<template>
  <div class="QMedico6">
    <div id="titleMed5">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico6">
      <div class="weight-variation-div">
        <p id="weight-variation">VARIAÇÃO DE PESO</p>
        <div class="weight-variation-inputs">
          <div class="weight-div">
            <label for="previous-weight-variation">Peso anterior:</label>
            <input
              type="text"
              @change="calcWeight(), addPontuacaoQMed1()"
              autofocus="autofocus"
              v-model="previousWeightVariation"
              id="previous-weight-variation"
            />
            <span>Kg</span>
          </div>
          <div class="weight-div">
            <label for="actual-weight-variation">Peso atual:</label>
            <input
              type="text"
              @change="calcWeight(), addPontuacaoQMed1()"
              v-model="actualWeightVariation"
              id="actual-weight-variation"
            />
            <span>Kg</span>
          </div>
          <div class="weight-div">
            <label for="loss-percentage-variation">Percentual de Perda:</label>
            <input
              type="text"
              v-model="lossPercentageVariation"
              disabled
              id="loss-percentage-variation"
            />
          </div>
          <div class="weight-div">
            <input
              type="checkbox"
              @change="addPontuacaoQMed1()"
              value="Sim"
              v-model="weightLoss"
              id="weight-loss-variation"
            />
            <label for="weight-loss-variation">Houve perda de Peso nas últimas 2 semanas?</label>
          </div>
        </div>
      </div>
    </form>
    <div class="pontuacao-container pontuacao1-espaco">
      <p>Pontuação total: {{ pontuacaoLocal + this.$store.state.pontuacaoTotal }}</p>
    </div>
    <div class="controlQMed6">
      <buttonRectangle info="VOLTAR" @click.native="qmedico5()" id="backMed6"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico7()" id="nextMed6"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";
import { baseApiUrl } from "../global";

export default {
  name: "QMedico6",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      previousWeightVariation: this.$store.state.versaoPaciente
        .versaoPaciente_q1PesoMes,
      actualWeightVariation: this.$store.state.versaoPaciente
        .versaoPaciente_q1PesoAtual,
      lossPercentageVariation: null,
      weightLoss: this.$store.state.versaoMedico.weightLoss,
      ready: false,
      pontuacaoLocal: 0
    };
  },
  methods: {
    qmedico7() {
      this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso = this.lossPercentageVariation;
      if (this.$store.state.flagPeso) {
        this.$store.state.versaoPaciente.versaoPaciente_q1PesoSemestre = this.previousWeightVariation;
      } else {
        this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes = this.previousWeightVariation;
      }

      this.$store.state.versaoPaciente.vpac_q1_pes_atu = this.actualWeightVariation;

      this.$store.state.pontuacaoTotal += this.pontuacaoLocal;

      if (this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso > -2) {
        this.$store.state.versaoMedico.versaoMedico_quadro1 = 0;
      } else if (
        this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso > -3 &&
        this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso <= -2
      ) {
        this.$store.state.versaoMedico.versaoMedico_quadro1 = 1;
      } else if (
        this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso > -5 &&
        this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso <= -3
      ) {
        this.$store.state.versaoMedico.versaoMedico_quadro1 = 2;
      } else if (
        this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso > -10 &&
        this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso <= -5
      ) {
        this.$store.state.versaoMedico.versaoMedico_quadro1 = 3;
      } else if (
        this.$store.state.versaoMedico.versaoMedico_q1PercentualPeso <= -10
      ) {
        this.$store.state.versaoMedico.versaoMedico_quadro1 = 4;
      }

      if (this.weightLoss == true) {
        this.$store.state.versaoMedico.versaoMedico_quadro1 += 1;
      }

      axios
        .put(
          `${baseApiUrl}/versao_paciente/${this.$store.state.versaoPaciente.versaoPaciente_id}`,
          this.$store.state.versaoPaciente
        )
        .catch(err => {
          alert(err);
        });

      this.$router.push("qmedico7");
    },
    qmedico5() {
      this.$router.push("qmedico5");
    },
    calcWeight() {
      let aux =
        ((this.actualWeightVariation - this.previousWeightVariation) * 100) /
        this.previousWeightVariation;
      if (!isNaN(aux)) {
        this.lossPercentageVariation = aux.toFixed(2);
      } else {
        this.lossPercentageVariation = "";
      }
    },
    calcPercentage() {
      this.lossPercentageVariation =
        ((this.actualWeightVariation - this.previousWeightVariation) * 100) /
        this.previousWeightVariation;

      this.lossPercentageVariation = parseFloat(
        this.lossPercentageVariation.toFixed(2)
      );
    },
    addPontuacaoQMed1() {
      if (this.actualWeightVariation && this.previousWeightVariation) {
        if (
          this.lossPercentageVariation > -3 &&
          this.lossPercentageVariation <= -2
        ) {
          this.pontuacaoLocal = 1;
        } else if (
          this.lossPercentageVariation > -5 &&
          this.lossPercentageVariation <= -3
        ) {
          this.pontuacaoLocal = 2;
        } else if (
          this.lossPercentageVariation > -10 &&
          this.lossPercentageVariation <= -5
        ) {
          this.pontuacaoLocal = 3;
        } else if (this.lossPercentageVariation <= -10) {
          this.pontuacaoLocal = 4;
        }
      }

      if (this.weightLoss == true) {
        this.pontuacaoLocal += 1;
      }
    },
    verificaPerda() {
      if (this.$store.state.versaoPaciente.versaPaciente_quadro1 == "1") {
        this.weightLoss = true;
      }
    },
    carregaPeso() {
      if (!this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes) {
        this.$store.state.flagPeso = true;
      }
      this.previousWeightVariation = this.pesoUsual;
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    versaoMedico() {
      return this.$store.state.versaoMedico;
    },
    pesoUsual() {
      if (!this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes) {
        return this.$store.state.versaoPaciente.versaoPaciente_q1PesoSemestre;
      }
      return this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes;
    }
  },
  mounted() {
    this.calcPercentage();
    this.verificaPerda();
    this.addPontuacaoQMed1();
    this.carregaPeso();
  }
};
</script>

<style>
.QMedico6 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 30px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed6 {
  position: relative;
  top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.weight-variation-div input[type="text"] {
  color: #777;
  width: 100px;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
}

.weight-variation-div {
  position: relative;
  top: 30px;
}

.pontuacao-container {
  position: relative;
  font-size: 35px;
  width: 80vw;
  margin-left: 50px;
}

.pontuacao-container p {
  width: 250px;
  background-color: #21a0a0;
  color: white;
  padding: 10px;
  box-shadow: 0 2px 10px #aaa;
}

.form-qmedico6 {
  position: relative;
  height: auto;
  width: 80vw;
  left: 50px;
}

.form-qmedico6 p {
  width: 80vw;
  height: 50px;
  font-size: 35px;
}

.weight-variation-div input[type="checkbox"] {
  display: none;
}

.weight-variation-div label {
  cursor: pointer;
}

.weight-variation-div input[type="checkbox"] + label:before {
  border: 1px solid rgb(79, 149, 253);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 4px;
  background-color: rgb(123, 181, 254);
  transform: translateY(8px);
}

.weight-variation-div input[type="checkbox"]:checked + label:before {
  color: #fff;
  content: "\2713";
  text-align: center;
}

.weight-variation-div input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.weight-variation-inputs div {
  font-size: 30px;
}

.weight-div {
  height: 55px;
  width: 80vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.weight-div span {
  margin-left: 15px;
}

#loss-percentage-variation {
  border: 2px solid rgb(99, 162, 226);
  border-radius: 5px;
  background-color: rgb(202, 202, 202);
  font-size: 25px;
  width: 150px;
}

#nextMed6 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed6 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed6 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 100vw;
  left: 50px;
}

@media screen and (max-width: 678px) {
  #titleMed6 {
    font-size: 35px;
    left: 20px;
    width: 80vw;
  }

  .form-qmedico6 {
    margin-top: 20px;
  }

  .form-qmedico6 {
    width: 80vw;
    left: 20px;
  }

  .form-qmedico6 p {
    font-size: 30px;
  }

  .form-qmedico6 label {
    font-size: 25px;
  }

  .weight-variation-div {
    height: auto;
  }

  .weight-variation-inputs {
    display: flex;
    flex-wrap: wrap;
    height: 40vh;
  }

  .controlQMed6 {
    margin-bottom: 20px;
  }

  #nextMed6 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed6 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>