<template>
  <div class="QMedico5">
    <div id="titleMed5">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico5">
      <p id="evaluation-register-med5">CADASTRO DE AVALIAÇÃO - Outros</p>
      <div class="IDN-div">
        <p id="IDN">IDN:</p>
        <div class="idn-radio-div">
          <div>
            <input
              type="radio"
              value="Desnutrição grave"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op1"
            />
            <label for="med-other-op1">Desnutrição grave</label>
            <br />
            <input
              type="radio"
              value="Desnutrição moderada"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op2"
            />
            <label for="med-other-op2">Desnutrição moderada</label>
            <br />
            <input
              type="radio"
              value="Desnutrição leve"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op3"
            />
            <label for="med-other-op3">Desnutrição leve</label>
            <br />
            <input
              type="radio"
              value="Magreza"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op4"
            />
            <label for="med-other-op4">Magreza</label>
            <br />
            <input
              type="radio"
              value="Eutrofia"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op5"
            />
            <label for="med-other-op5">Eutrofia</label>
            <br />
          </div>
          <div>
            <input
              type="radio"
              value="Sobrepeso"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op9"
            />
            <label for="med-other-op9">Sobrepeso</label>
            <br />
            <input
              type="radio"
              value="Obesidade I"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op10"
            />
            <label for="med-other-op10">Obesidade I</label>
            <br />
            <input
              type="radio"
              value="Obesidade II"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op11"
            />
            <label for="med-other-op11">Obesidade II</label>
            <br />
            <input
              type="radio"
              value="Obesidade III"
              v-model="idnRadio"
              name="idn-radio"
              id="med-other-op12"
            />
            <label for="med-other-op12">Obesidade III</label>
            <br />
          </div>
        </div>
      </div>
      <div class="nutritional-risk-div">
        <p id="nutritional-risk">Risco Nutricional</p>
        <div class="nutritional-risk-radio-div">
          <div class="no-nutritional-risk-div">
            <input
              type="radio"
              v-model="nutritionalRiskRadio"
              value="Não"
              name="nutritional-risk-radio"
              id="no-nutritional-risk"
            />
            <label for="no-nutritional-risk">Não</label>
          </div>
          <div class="yes-nutritional-risk-div">
            <input
              type="radio"
              v-model="nutritionalRiskRadio"
              value="Sim"
              name="nutritional-risk-radio"
              id="yes-nutritional-risk"
            />
            <label for="yes-nutritional-risk">Sim</label>
          </div>
        </div>
      </div>
      <div class="conduct-div">
        <p id="conduct">Conduta:</p>
        <textarea
          name="conduct"
          v-model="conductTextarea"
          id="conduct-textarea"
          cols="50"
          rows="10"
        ></textarea>
      </div>
    </form>
    <div class="controlQMed5">
      <!-- <buttonRectangle info="VOLTAR" @click.native="qmedico4()" id="backMed5"></buttonRectangle> -->
      <buttonRectangle info="AVANÇAR" @click.native="qmedico6()" id="nextMed5"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";
import { baseApiUrl } from "../global";

export default {
  name: "QMedico5",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      idnRadio: this.$store.state.avaliacao.ava_sit,
      nutritionalRiskRadio: this.$store.state.avaliacao.ava_ris_nut,
      conductTextarea: this.$store.state.avaliacao.ava_con,
      localVPac: [],
      ready: false
    };
  },
  methods: {
    qmedico6() {
      this.$store.state.avaliacao.ava_sit = this.idnRadio;

      if (this.nutritionalRiskRadio == "Sim") {
        this.$store.state.avaliacao.ava_ris_nut = 1;
      } else {
        this.$store.state.avaliacao.ava_ris_nut = 0;
      }

      this.$store.state.avaliacao.ava_con = this.conductTextarea;

      const dataPac = this.$store.state.avaliacao.avaliacao_dataPaciente.split(
        "T"
      );
      this.$store.state.avaliacao.avaliacao_dataPaciente = dataPac[0];

      const data = new Date();

      const stringData =
        data.getUTCFullYear() +
        "-" +
        (data.getUTCMonth() + 1) +
        "-" +
        data.getDate();

      this.$store.state.avaliacao.avaliacao_dataMedico = stringData;

      let userAux = localStorage.getItem("__user");

      userAux = JSON.parse(userAux);

      this.$store.state.avaliacao.nutricionista_id = userAux.nutricionista_id;

      axios
        .put(
          `${baseApiUrl}/avaliacao/${this.$store.state.avaliacao.avaliacao_id}`,
          this.$store.state.avaliacao
        )
        .then(res => res.data)
        .catch(err => {
          alert(err);
        });

      setTimeout(() => {
        this.$router.push("qmedico6");
      }, 1000);
    },
    qmedico4() {
      this.$router.push("qmedico4");
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  mounted() {
    //console.log(this.$store.state.paciente);
  },
  computed: {
    avaliacao() {
      return this.$store.state.avaliacao;
    },
    antropometria() {
      return this.$store.state.antropometria;
    }
  }
};
</script>

<style>
.QMedico5 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 30px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed5 {
  position: relative;
  top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.idn-radio-div {
  display: flex;
  width: 80vw;
  left: 50px;
  font-size: 27px;
}

.conduct-div {
  top: 100px;
  height: 200px;
}

.IDN-div {
  top: 30px;
}

.nutritional-risk-div {
  top: 60px;
  font-size: 27px;
}

.form-qmedico5 {
  height: 650px;
}

.yes-nutritional-risk-div {
  left: 60px;
}

.nutritional-risk-radio-div {
  left: 50px;
}

.nutritional-risk-div div {
  display: inline;
}

.idn-radio-div input[type="radio"],
.nutritional-risk-div input[type="radio"] {
  display: none;
}

.idn-radio-div label,
.nutritional-risk-div label {
  cursor: pointer;
}

.idn-radio-div input[type="radio"] + label:before,
.nutritional-risk-div input[type="radio"] + label:before {
  border: 1px solid #21a0a0;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(8px);
}

.idn-radio-div input[type="radio"]:checked + label:before,
.nutritional-risk-div input[type="radio"]:checked + label:before {
  color: #21a0a0;
  content: "\25CF";
  text-align: center;
}

.idn-radio-div input[type="radio"]:checked + label:after,
.nutritional-risk-div input[type="radio"]:checked + label:after {
  font-weight: bold;
}

.form-qmedico5 p,
.idn-radio-div,
.nutritional-risk-div,
.nutritional-risk-div div,
.yes-nutritional-risk-div,
.conduct-div,
#conduct-textarea,
.IDN-div {
  position: relative;
}

.form-qmedico5 p {
  width: 80vw;
  height: 50px;
  left: 50px;
  font-size: 30px;
}

#conduct-textarea {
  color: #777;
  left: 50px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 120px;
  width: 550px;
}

#evaluation-register-med5 {
  font-size: 35px;
}

#nextMed5 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed5 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed5 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 80vw;
  left: 50px;
}

@media screen and (max-width: 678px) {
  #titleMed5 {
    font-size: 35px;
    left: 10vw;
    width: 80vw;
  }

  .form-qmedico5 {
    height: auto;
    margin-top: 20px;
  }

  .form-qmedico5 p {
    width: 80vw;
    left: 10vw;
    font-size: 25px;
  }

  #evaluation-register-med5 {
    font-size: 30px;
  }

  .idn-radio-div {
    flex-wrap: wrap;
    left: 10vw;
    font-size: 25px;
  }

  .nutritional-risk-radio-div {
    left: 10vw;
  }

  #conduct-textarea {
    left: 10vw;
    font-size: 25px;
    width: 80vw;
  }

  .controlQMed5 {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  #nextMed5 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed5 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>