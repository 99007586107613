<template>
  <div class="QMedico7">
    <div id="titleMed7">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico7">
      <div class="diseases-div">
        <p id="diseases">DOENÇAS E NECESSIDADES NUTRICIONAIS</p>
        <div class="other-diagnoses-textearea-div">
          <p id="other-diagnoses">Outros diagnósticos relevantes:</p>
          <textarea
            name="other-diagnoses"
            autofocus="autofocus"
            v-model="otherDiagnosesTextarea"
            id="other-diagnoses-textarea"
            cols="50"
            rows="10"
          ></textarea>
        </div>
        <div class="staging-div">
          <label for="staging-select">Estadiamento da doença primária:</label>
          <select name="staging-select-lis" v-model="stagingSelect" id="staging-select">
            <option value="1">I</option>
            <option value="2">II</option>
            <option value="3">III</option>
            <option value="4">IV</option>
          </select>
        </div>
        <div class="other2-diagnoses-textearea-div">
          <p id="other2-diagnoses">Outro:</p>
          <textarea
            name="other2-diagnoses"
            v-model="other2DiagnosesTextarea"
            id="other2-diagnoses-textarea"
            cols="50"
            rows="10"
          ></textarea>
        </div>
        <div class="conditions-div">
          <p id="conditions">Marque todas as condições que se encaixam:</p>
          <div class="conditions-checkbox">
            <input
              type="checkbox"
              @change="addPontuacaoQMed2()"
              value="Câncer"
              v-model="conditionsArray"
              id="cancer"
            />
            <label for="cancer">Câncer</label>
            <input
              type="checkbox"
              @change="addPontuacaoQMed2()"
              value="AIDS"
              v-model="conditionsArray"
              id="aids"
            />
            <label for="aids">AIDS</label>
            <input
              type="checkbox"
              @change="addPontuacaoQMed2()"
              value="Caquexia cardíaca ou pulmonar"
              v-model="conditionsArray"
              id="cachexia"
            />
            <label for="cachexia">Caquexia cardíaca ou pulmonar</label>
            <input
              type="checkbox"
              @change="addPontuacaoQMed2()"
              value="Úlcera de decúbito, ferida aberta ou fístula"
              v-model="conditionsArray"
              id="ulcer"
            />
            <label for="ulcer">Úlcera de decúbito, ferida aberta ou fístula</label>
            <input
              type="checkbox"
              @change="addPontuacaoQMed2()"
              value="Presença de trauma"
              v-model="conditionsArray"
              id="trauma"
            />
            <label for="trauma">Presença de trauma</label>
            <input
              type="checkbox"
              @change="addPontuacaoQMed2()"
              value="Idade maior que 65 anos"
              v-model="conditionsArray"
              id="old-man"
            />
            <label for="old-man">Idade maior que 65 anos</label>
            <input
              type="checkbox"
              @change="addPontuacaoQMed2()"
              value="Insuficiência renal crônica"
              v-model="conditionsArray"
              id="renal-insufficiency"
            />
            <label for="renal-insufficiency">Insuficiência renal crônica</label>
          </div>
        </div>
      </div>
    </form>
    <div class="pontuacao-container pontuacao2-espaco">
      <p>Pontuação total: {{ pontuacaoLocal + this.$store.state.pontuacaoTotal }}</p>
    </div>
    <div class="controlQMed7">
      <buttonRectangle info="VOLTAR" @click.native="qmedico6()" id="backMed7"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico8()" id="nextMed7"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";

export default {
  name: "QMedico7",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      otherDiagnosesTextarea: this.$store.state.versaoMedico
        .versaoMedico_q1DiagnosticoRelevante,
      stagingSelect: this.$store.state.versaoMedico.versaoMedico_q2Estadiamento,
      other2DiagnosesTextarea: this.$store.state.versaoMedico
        .versaoMedico_q2Outro,
      conditionsArray: this.$store.state.condicoes,
      ready: false,
      pontuacaoLocal: 0
    };
  },
  methods: {
    qmedico8() {
      //this.$store.state.avaliacao.anthropometryHeight = this.anthropometryHeight
      this.$store.state.versaoMedico.versaoMedico_quadro2 = this.conditionsArray.length;
      this.$store.state.pontuacaoTotal += this.pontuacaoLocal;

      if (
        this.otherDiagnosesTextarea == "" ||
        this.otherDiagnosesTextarea == null
      ) {
        this.$store.state.versaoMedico.versaoMedico_q1DiagnosticoRelevante =
          "Não se aplica";
      } else {
        this.$store.state.versaoMedico.versaoMedico_q1DiagnosticoRelevante = this.otherDiagnosesTextarea;
      }
      this.$store.state.versaoMedico.versaoMedico_q2Estadiamento = this.stagingSelect;
      this.$store.state.versaoMedico.versaoMedico_q2Outro = this.other2DiagnosesTextarea;
      this.$store.state.condicoes = this.conditionsArray;

      this.$router.push("qmedico8");
    },
    qmedico6() {
      this.$router.push("qmedico6");
    },
    addPontuacaoQMed2() {
      this.pontuacaoLocal = this.conditionsArray.length;
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    versaoMedico() {
      return this.$store.state.versaoMedico;
    }
  }
};
</script>

<style>
.QMedico7 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 30px;
  width: 100vw;
  height: auto;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed7 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.form-qmedico7 {
  position: relative;
  height: auto;
  width: 80vw;
  top: 30px;
  left: 50px;
}

.conditions-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  top: 30px;
  height: auto;
  width: 40vw;
}

.conditions-checkbox label {
  color: rgb(79, 149, 253);
}

.conditions-checkbox input[type="checkbox"] {
  display: none;
}

.conditions-checkbox label {
  cursor: pointer;
}

.conditions-checkbox input[type="checkbox"] + label:before {
  border: 1px solid rgb(79, 149, 253);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 4px;
  background-color: rgb(123, 181, 254);
  transform: translateY(8px);
}

.conditions-checkbox input[type="checkbox"]:checked + label:before {
  color: #fff;
  content: "\2713";
  text-align: center;
}

.conditions-checkbox input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.weight-variation-inputs div {
  font-size: 30px;
}

.conditions-div {
  position: relative;
  height: auto;
  top: 150px;
}

.form-qmedico7 p,
.form-qmedico7 label {
  font-size: 30px;
}

.other-diagnoses-textearea-div {
  position: relative;
  top: 20px;
}

.other2-diagnoses-textearea-div {
  position: relative;
  top: 100px;
}

.staging-div {
  position: relative;
  top: 70px;
}

#staging-select {
  color: #777;
  width: 50px;
  border-color: #fff;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
}

#other-diagnoses-textarea {
  color: #777;
  right: 5px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 80px;
  width: 550px;
}

#other2-diagnoses-textarea {
  color: #777;
  right: 5px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 80px;
  width: 550px;
}

#diseases {
  font-size: 35px;
}

.pontuacao2-espaco {
  margin-top: 250px;
}

#nextMed7 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed7 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed7 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 80vw;
  left: 50px;
}

@media screen and (min-height: 1225px) {
}

@media screen and (min-width: 679px) and (max-width: 1024px) {
  .conditions-checkbox {
    width: 80vw;
  }
}

@media screen and (max-width: 678px) {
  #titleMed7 {
    font-size: 35px;
    left: 20px;
    width: 80vw;
  }

  .form-qmedico7 {
    margin-top: 20px;
  }

  .form-qmedico7 {
    height: auto;
    width: 80vw;
    left: 20px;
  }

  .form-qmedico7 p {
    font-size: 25px;
  }

  .form-qmedico7 label {
    font-size: 25px;
  }

  #other-diagnoses-textarea {
    width: 80vw;
  }

  #other2-diagnoses-textarea {
    width: 80vw;
  }

  #staging-select {
    font-size: 25px;
  }

  .conditions-checkbox {
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
  }

  .conditions-checkbox label {
    width: 80%;
  }

  .controlQMed7 {
    margin-top: 200px;
    margin-bottom: 20px;
  }

  #nextMed7 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed7 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>