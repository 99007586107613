<template>
    <div class="topnav">
        <div class="logo" @click="limparCache()">
          <img :src="nutrologic" alt="">
        </div>
        <button v-if="!user" @click="redirectToLogin()" class="login-menu-btn">Login</button>
        <div style="cursor: pointer" @click="toggleNav()" v-if="isMenuVisible && user"><i id="icon-navbar" class="fas fa-bars"></i></div>
    </div>
</template>

<script>
import nutrologic from '../assets/nutrologic.png'
export default {
  name: 'navBar',
  data: function(){
    return{
      isVisible: false,
      nutrologic: nutrologic
    }
  },
  components: {
  },
  methods: {
    toggleNav() {
      if(this.isVisible == false){
        document.getElementById("mySidenav").style.removeProperty("animation")
        document.getElementById("mySidenav").style.setProperty("animation", "menu-easein .5s")
        document.getElementById("mySidenav").style.setProperty("animation-fill-mode", "forwards")
        this.isVisible = true
        this.$store.state.menuToggle = true
      }
      else{
        document.getElementById("mySidenav").style.removeProperty("animation")
        document.getElementById("mySidenav").style.setProperty("animation", "menu-easeout .5s")
        document.getElementById("mySidenav").style.setProperty("animation-fill-mode", "forwards")
        this.isVisible = false
        this.$store.state.menuToggle = false
      }
    },
    limparCache(){
      this.$store.state.paciente = {}
      this.$store.state.listaPacientes = []
      this.$store.state.versaoPaciente = {}
      this.$store.state.exameFisico = []
      this.$store.state.avaliacaoGlobalAtual = []
      this.$store.state.idAvaliacao = ''
      this.$store.state.isMenuVisible = true
      this.$store.state.sintomas = []
      this.$store.state.condicoes = []
      this.$store.state.valueQuest1 = ''
      this.$store.state.valueQuest2 = ''
      this.$store.state.valueQuest3 = ''
      this.$store.state.valueQuest4 = ''
      this.$store.state.valueQuest5 = ''
      this.$store.state.otherFlag = false
      this.$store.state.acheFlag = false
      this.$store.state.feedFlag = false
      this.$store.state.avaliacao = {}
      this.$store.state.antropometria = {}
      this.$store.state.versaoMedico = {}

      this.$router.push('/')
    },
    redirectToLogin() {
      this.$router.push('/login')
    }
  },
  computed:{
    isMenuVisible(){
      return this.$store.state.isMenuVisible
    },
    user() {
      return this.$store.state.user
    }
  }
}
</script>

<style>
/* Add a black background color to the top navigation */
.topnav i#icon-navbar {
  float: right;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 50px;
}

.nomeApp {
    position: absolute;
}

.topnav {
  justify-content: space-between;
  display: flex;
  background-color: #6cd4ff;
  height: 80px;
  overflow: hidden;
  box-shadow: 3px 3px 5px 6px #ccc;
}

.topnav .logo {
  cursor: pointer;
  color: #f2f2f2;
  text-align: center;
  padding: 8px 10px;
  text-decoration: none;
  font-size: 50px;
}


.login-menu-btn {
  margin-right: 30px;
  height: 40px;
  padding: 0 30px;
  font-size: 20px;
  align-self: center;
  background-color: #41b7fc;
  box-shadow: 0 0 5px #fff;
  color: white;
  border: none;
  transition: .2s;
}

.login-menu-btn:hover {
  cursor: pointer;
  filter: brightness(105%);
}

</style>