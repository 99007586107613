<template>
  <div class="QMedico9">
    <div id="titleMed9">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico9">
      <div class="physical-exam-div">
        <p id="physical-exam">EXAME FÍSICO</p>
        <div>
          <label for="tempora">Temporas (músculos temporais)</label>
          <select name autofocus="autofocus" id="tempora" v-model="examsArray[0]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="clavicle">Clavículas (peitorais e deltoides)</label>
          <select name id="clavicle" v-model="examsArray[1]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="shoulder">Ombros (deltoides)</label>
          <select name id="shoulder" v-model="examsArray[2]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="musculature">Musculatura intraóssea (mãos)</label>
          <select name id="musculature" v-model="examsArray[3]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="shoulder-blade">Escápula (dorsal maior, trapézio, deltoides)</label>
          <select name id="shoulder-blade" v-model="examsArray[4]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="thigh">Coxa (quadríceps)</label>
          <select name id="thigh" v-model="examsArray[5]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="calf">Panturrilha (gastrocnêmius)</label>
          <select name id="calf" v-model="examsArray[6]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
      </div>
      <div class="fat-reserve-div">
        <div>
          <label for="fat-reserve">AVALIAÇÃO GERAL DO ESTADO MUSCULAR - RESERVAS DE GORDURA</label>
          <select name id="fat-reserve" v-model="examsArray[7]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="periorbital">Região periorbital</label>
          <select name id="periorbital" v-model="examsArray[8]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="fold">Prega cutânea do tríceps</label>
          <select name id="fold" v-model="examsArray[9]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
        <div>
          <label for="fat-ribs">Gordura sobre as costelas inferiores</label>
          <select name id="fat-ribs" v-model="examsArray[10]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem défict</option>
            <option value="1">Défict leve</option>
            <option value="2">Défict moderado</option>
            <option value="3">Défict grave</option>
          </select>
        </div>
      </div>
      <div class="hydration-status-div">
        <div>
          <label for="hydration-status">AVALIAÇÃO GERAL DO DÉFICIT DE GORDURA - ESTADO DE HIDRATAÇÃO</label>
          <select name id="hydration-status" v-model="examsArray[11]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem Edema</option>
            <option value="1">Edema leve</option>
            <option value="2">Edema moderado</option>
            <option value="3">Edema grave</option>
          </select>
        </div>
        <div>
          <label for="ankle-edema">Edema do tornozelo</label>
          <select name id="ankle-edema" v-model="examsArray[12]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem Edema</option>
            <option value="1">Edema leve</option>
            <option value="2">Edema moderado</option>
            <option value="3">Edema grave</option>
          </select>
        </div>
        <div>
          <label for="sacral-edema">Edema sacral</label>
          <select name id="sacral-edema" v-model="examsArray[13]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem Edema</option>
            <option value="1">Edema leve</option>
            <option value="2">Edema moderado</option>
            <option value="3">Edema grave</option>
          </select>
        </div>
        <div>
          <label for="ascite">Ascite</label>
          <select name id="ascite" v-model="examsArray[14]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem Edema</option>
            <option value="1">Edema leve</option>
            <option value="2">Edema moderado</option>
            <option value="3">Edema grave</option>
          </select>
        </div>
        <div>
          <label for="geral-hydration">Avaliação geral do estado de hidratação</label>
          <select name id="geral-hydration" v-model="examsArray[15]">
            <option selected disabled value="undefined">Escolha uma opção</option>
            <option value="0">Sem Edema</option>
            <option value="1">Edema leve</option>
            <option value="2">Edema moderado</option>
            <option value="3">Edema grave</option>
          </select>
        </div>
      </div>
      <div class="overall-score-div">
        <label for="overall-score">PONTUAÇÃO GERAL</label>
        <input
          type="number"
          @change="addPontuacaoQMed4()"
          id="overall-score"
          v-model="overallScore"
          min="0"
          max="3"
        />
      </div>
    </form>
    <div class="pontuacao-container pontuacao4-espaco">
      <p>Pontuação total: {{ pontuacaoLocal + this.$store.state.pontuacaoTotal }}</p>
    </div>
    <div class="controlQMed9">
      <buttonRectangle info="VOLTAR" @click.native="qmedico8()" id="backMed9"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico10()" id="nextMed9"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";

export default {
  name: "QMedico9",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      examsArray: this.$store.state.exameFisico,
      overallScore: this.$store.state.versaoMedico.versaoMedico_quadro4 || 0,
      ready: false,
      pontuacaoLocal: 0
    };
  },
  methods: {
    qmedico10() {
      //this.$store.state.avaliacao.anthropometryHeight = this.anthropometryHeight

      this.$store.state.versaoMedico.versaoMedico_quadro4 = this.overallScore;
      this.$store.state.exameFisico = this.examsArray;
      this.$store.state.pontuacaoTotal += this.pontuacaoLocal;

      this.$router.push("qmedico10");
    },
    qmedico8() {
      this.$router.push("qmedico8");
    },
    addPontuacaoQMed4() {
      this.pontuacaoLocal = Number(this.overallScore);
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    versaoMedico() {
      return this.$store.state.versaoMedico;
    }
  }
};
</script>

<style>
.QMedico9 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  /* position: relative; */
  margin-top: 30px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed9 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.form-qmedico9 {
  position: relative;
  margin-top: 20px;
  width: 80vw;
  left: 50px;
  margin-bottom: 30px;
}

.physical-exam-div div {
  display: flex;
  justify-content: space-between;
  width: 80vw;
}

.fat-reserve-div div {
  display: flex;
  justify-content: space-between;
  width: 80vw;
}

.hydration-status-div div {
  display: flex;
  justify-content: space-between;
  width: 80vw;
}

.overall-score-div {
  display: flex;
  justify-content: space-between;
  width: 80vw;
}

.form-qmedico9 p {
  width: 80vw;
  font-size: 35px;
}

.form-qmedico9 label {
  font-size: 30px;
}

.form-qmedico9 select {
  color: #777;
  width: 30vw;
  border-color: #fff;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
  text-align: center;
}

.form-qmedico9 input[type="number"] {
  color: #777;
  width: 10vw;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
  text-align: left;
}

.physical-exam-div {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}

.physical-exam-div p {
  position: relative;
  bottom: 20px;
}

.fat-reserve-div {
  width: 80vw;
  position: relative;
  margin-top: 60px;
}

.hydration-status-div {
  width: 80vw;
  position: relative;
  margin-top: 90px;
}

.overall-score-div {
  position: relative;
}

#nextMed9 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed9 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed9 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 80vw;
  left: 50px;
}

@media screen and (max-width: 1366px) {
  .physical-exam-div div {
    width: 90vw;
  }

  .fat-reserve-div div {
    width: 90vw;
  }

  .hydration-status-div div {
    width: 90vw;
  }

  .overall-score-div {
    width: 90vw;
  }
}

@media screen and (max-width: 778px) {
  #titleMed9 {
    font-size: 35px;
    left: 10vw;
    width: 80vw;
  }

  .form-qmedico9 {
    margin-top: 20px;
  }

  .form-qmedico9 {
    margin-bottom: 40px;
    height: auto;
    width: 80vw;
    left: 10vw;
  }

  .form-qmedico9 p {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .form-qmedico9 label {
    width: 80vw;
    font-size: 25px;
  }

  .form-qmedico9 select {
    color: #aaa;
    left: 0;
    width: 80vw;
    font-size: 25px;
  }

  .form-qmedico9 input[type="number"] {
    width: 25vw;
  }

  .physical-exam-div div {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
  }

  .fat-reserve-div div {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
  }

  .hydration-status-div div {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
  }

  .overall-score-div {
    width: 80vw;
  }

  .controlQMed9 {
    margin-top: 60px;
    margin-bottom: 20px;
    height: auto;
  }

  #nextMed9 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed9 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>