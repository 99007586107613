<template>
  <div class="home">
    <homePage msg="Bem Vindo(a)!"/>
  </div>
</template>

<script>
// @ is an alias to /src
import homePage from '@/components/homePage.vue'

export default {
  name: 'home',
  components: {
    homePage
  } 
}
</script>

