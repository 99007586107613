<template>
  <div class="QMedico11">
    <div id="titleMed11">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico11">
      <div class="suplementacao-necessaria-div">
        <p id="suplementacao-demand">SUPLEMENTAÇÃO NECESSÁRIA</p>
        <div class="suplementacao-div">
          <p id="suplementacao">Suplementação:</p>
          <div class="radio-div">
            <input
              type="radio"
              value="Sim"
              v-model="suplementacaoRadio"
              name="suplementacao-radio"
              id="suplementacao-radio1"
            />
            <label for="suplementacao-radio1">Sim</label>
            <br />
            <input
              type="radio"
              value="Não"
              v-model="suplementacaoRadio"
              name="suplementacao-radio"
              id="suplementacao-radio2"
            />
            <label for="suplementacao-radio2">Não</label>
            <br />
          </div>
        </div>
        <div class="suplementacao-tipo-div">
          <p id="suplementacao-tipo">Tipo:</p>
          <div class="radio-div">
            <input
              type="radio"
              value="Pó"
              v-model="suplementoTipoRadio"
              name="suplementacao-tipo-radio"
              id="suplementacao-tipo-radio1"
            />
            <label for="suplementacao-tipo-radio1">Pó</label>
            <br />
            <input
              type="radio"
              value="Preparado"
              v-model="suplementoTipoRadio"
              name="suplementacao-tipo-radio"
              id="suplementacao-tipo-radio2"
            />
            <label for="suplementacao-tipo-radio2">Preparado</label>
            <br />
            <input
              type="radio"
              value="Pronto"
              v-model="suplementoTipoRadio"
              name="suplementacao-tipo-radio"
              id="suplementacao-tipo-radio3"
            />
            <label for="suplementacao-tipo-radio3">Pronto</label>
            <br />
          </div>
        </div>
        <div class="suplementacao-proteina-div">
          <p class="suplementacao-proteina">Proteína:</p>
          <div class="radio-div">
            <input
              type="radio"
              value="Hiperproteico"
              v-model="suplementacaoProteinaRadio"
              name="suplementacao-proteina-radio"
              id="suplementacao-proteina-radio1"
            />
            <label for="suplementacao-proteina-radio1">Hiperproteico</label>
            <br />
            <input
              type="radio"
              value="Normoproteico"
              v-model="suplementacaoProteinaRadio"
              name="suplementacao-proteina-radio"
              id="suplementacao-proteina-radio2"
            />
            <label for="suplementacao-proteina-radio2">Normoproteico</label>
            <br />
          </div>
        </div>
        <div class="suplementacao-caloria-div">
          <p class="suplementacao-caloria">Calorias:</p>
          <div class="radio-div">
            <input
              type="radio"
              value="Hipercalórico"
              v-model="suplementacaoCaloriaRadio"
              name="suplementacao-caloria-radio"
              id="suplementacao-caloria-radio1"
            />
            <label for="suplementacao-caloria-radio1">Hipercalórico</label>
            <br />
            <input
              type="radio"
              value="Normocalórico"
              v-model="suplementacaoCaloriaRadio"
              name="suplementacao-caloria-radio"
              id="suplementacao-caloria-radio2"
            />
            <label for="suplementacao-caloria-radio2">Normocalórico</label>
            <br />
          </div>
        </div>
        <div class="suplementacao-omega-div">
          <p class="suplementacao-omega">Rico em Ômega 3:</p>
          <div class="radio-div">
            <input
              type="radio"
              value="1"
              v-model="suplementacaoOmegaRadio"
              name="suplementacao-omega-radio"
              id="suplementacao-omega-radio1"
            />
            <label for="suplementacao-omega-radio1">Sim</label>
            <br />
            <input
              type="radio"
              value="0"
              v-model="suplementacaoOmegaRadio"
              name="suplementacao-omega-radio"
              id="suplementacao-omega-radio2"
            />
            <label for="suplementacao-omega-radio2">Não</label>
            <br />
          </div>
        </div>
      </div>
    </form>
    <div class="pontuacao-container pontuacao3-espaco">
      <p>Pontuação total: {{ pontuacaoLocal + this.$store.state.pontuacaoTotal }}</p>
    </div>
    <div class="controlQMed11">
      <buttonRectangle info="VOLTAR" @click.native="qmedico10()" id="backMed11"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="relatorio()" id="nextMed11"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";
import axios from "axios";
import { baseApiUrl } from "../global";

export default {
  name: "QMedico11",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      suplementacaoRadio: this.$store.state.versaoMedico
        .versaoMedico_suplementacao,
      suplementoTipoRadio: this.$store.state.versaoMedico.suplementacao_tipo,
      suplementacaoProteinaRadio: this.$store.state.versaoMedico
        .suplementacao_proteina,
      suplementacaoCaloriaRadio: this.$store.state.versaoMedico
        .suplementacao_caloria,
      suplementacaoOmegaRadio: this.$store.state.versaoMedico
        .suplementacao_omega,
      ready: false,
      pontuacaoLocal: 0
    };
  },
  methods: {
    relatorio() {
      //this.$store.state.avaliacao.anthropometryHeight = this.anthropometryHeight
      this.$store.state.versaoMedico.versaoMedico_suplementacao = this.suplementacaoRadio;
      this.$store.state.versaoMedico.suplementacao_tipo = this.suplementoTipoRadio;
      this.$store.state.versaoMedico.suplementacao_proteina = this.suplementacaoProteinaRadio;
      this.$store.state.versaoMedico.suplementacao_caloria = this.suplementacaoCaloriaRadio;
      this.$store.state.versaoMedico.suplementacao_omega = this.suplementacaoOmegaRadio;

      //console.log(this.$store.state.versaoMedico);
      axios
        .put(
          `${baseApiUrl}/versao_medico/${this.$store.state.versaoMedico.versaoMedico_id}`,
          this.$store.state.versaoMedico
        )
        .then(() => {
          this.$router.push("relatorio");
        })
        .catch(err => {
          alert(err);
        });
    },
    qmedico10() {
      this.$router.push("qmedico10");
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    versaoMedico() {
      return this.$store.state.versaoMedico;
    }
  }
};
</script>

<style>
.QMedico11 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100vw;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed11 {
  position: relative;
  top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.form-qmedico11 {
  position: relative;
  height: auto;
  width: 100vw;
  left: 50px;
  margin-bottom: 20px;
}

.form-qmedico11 p {
  font-size: 30px;
}

.form-qmedico11 label {
  font-size: 25px;
}

.suplementacao-div label,
.suplementacao-tipo-div label,
.suplementacao-proteina-div label,
.suplementacao-caloria-div label,
.suplementacao-omega-div label {
  position: relative;
  left: 20px;
}

.suplementacao-div,
.suplementacao-tipo-div,
.suplementacao-proteina-div,
.suplementacao-caloria-div,
.suplementacao-omega-div {
  position: relative;
}

.suplementacao-div {
  margin-top: 30px;
}

.suplementacao-tipo-div {
  margin-top: 30px;
}

.suplementacao-proteina-div {
  margin-top: 30px;
}

.suplementacao-caloria-div {
  margin-top: 30px;
}

.suplementacao-omega-div {
  margin-top: 30px;
}

.suplementacao-div input[type="radio"],
.suplementacao-tipo-div input[type="radio"],
.suplementacao-proteina-div input[type="radio"],
.suplementacao-caloria-div input[type="radio"],
.suplementacao-omega-div input[type="radio"] {
  display: none;
}

.suplementacao-div label,
.suplementacao-tipo-div label,
.suplementacao-proteina-div label,
.suplementacao-caloria-div label,
.suplementacao-omega-div label {
  cursor: pointer;
}

.suplementacao-div input[type="radio"] + label:before,
.suplementacao-tipo-div input[type="radio"] + label:before,
.suplementacao-proteina-div input[type="radio"] + label:before,
.suplementacao-caloria-div input[type="radio"] + label:before,
.suplementacao-omega-div input[type="radio"] + label:before {
  border: 1px solid #21a0a0;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(8px);
}

.suplementacao-div input[type="radio"]:checked + label:before,
.suplementacao-tipo-div input[type="radio"]:checked + label:before,
.suplementacao-proteina-div input[type="radio"]:checked + label:before,
.suplementacao-caloria-div input[type="radio"]:checked + label:before,
.suplementacao-omega-div input[type="radio"]:checked + label:before {
  color: #21a0a0;
  content: "\25CF";
  text-align: center;
}

.suplementacao-div input[type="radio"]:checked + label:after,
.suplementacao-tipo-div input[type="radio"]:checked + label:after,
.suplementacao-proteina-div
  input[type="radio"]:checked
  + label:after
  .suplementacao-caloria-div
  input[type="radio"]:checked
  + label:after,
.suplementacao-omega-div input[type="radio"]:checked + label:after {
  font-weight: bold;
}

#nextMed11 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed11 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed11 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 100vw;
  height: auto;
  left: 50px;
}

@media screen and (max-width: 678px) {
  #titleMed11 {
    font-size: 35px;
    left: 20px;
    width: 80vw;
  }

  .form-qmedico11 {
    margin-top: 20px;
  }

  .form-qmedico11 {
    margin-bottom: 40px;
    height: auto;
    width: 80vw;
    left: 20px;
  }

  .form-qmedico11 p {
    font-size: 25px;
  }

  .form-qmedico11 label {
    font-size: 25px;
  }

  .controlQMed11 {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  #nextMed11 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed11 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>