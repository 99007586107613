<template>
  <div class="login">
    <div class="login-container">
      <div class="login-title">
        <h1>Login</h1>
      </div>
      <form action>
        <div>
          <input type="text" v-model="usuario.adm_email" placeholder="Login" />
        </div>
        <div>
          <input type="password" v-model="usuario.adm_senha" placeholder="Senha" />
        </div>
      </form>
      <div class="login-button-container">
        <buttonRectangle
          info="CONFIRMAR"
          styl="font-size: 20px;"
          @click.native="loginAdmin()"
          id="confirm-login"
        ></buttonRectangle>
        <buttonRectangle
          info="CANCELAR"
          styl="font-size: 20px;"
          @click.native="cancela()"
          id="cancel-login"
        ></buttonRectangle>
      </div>
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import { baseApiUrl, userKey } from "@/global";

export default {
  name: "login",
  components: {
    buttonRectangle
  },
  data() {
    return {
      usuario: {},
      passFlag: true
    };
  },
  methods: {
    loginAdmin() {
      axios
        .post(`${baseApiUrl}/login`, this.usuario)
        .then(res => {
          this.$store.commit("setUser", res.data);
          localStorage.setItem(userKey, JSON.stringify(res.data));
          this.$router.push("/");
        });
    }
  }
};
</script>

<style>
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #f5f5f5;
  width: 100%;
  height: 5vh;
  margin-top: 20px;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
  min-height: 50vh;
  width: 40vw;
  background-color: #6cc0c0;
  box-shadow: 2px 4px 10px #777;
  border-radius: 5px;
}

.login form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 15vh;
}

.login input[type="password"],
.login input[type="text"] {
  padding-left: 7px;
  border: none;
  border-bottom: 1px solid #444;
  outline: none;
  width: 350px;
  font-size: 25px;
  color: #777;
}

.login i {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  margin: 17px 0 0 -25px;
  float: right;
}

.login select {
  border-color: #fff;
  border-bottom: 1px solid #444;
  outline: none;
  width: 350px;
  font-size: 25px;
  color: #777;
}

#confirm-login {
  margin-right: 10px;
  width: 165px;
  height: 60px;
  display: flex;
  background-color: #56e049;
  box-shadow: 2px 4px 10px #777;
}

#cancel-login {
  margin-left: 10px;
  width: 165px;
  height: 60px;
  display: flex;
  background-color: #fc7753;
  box-shadow: 2px 4px 10px #777;
}

.login-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  .login-container {
    width: 70vw;
  }

  .login-title {
    font-size: 30px;
  }

  .login input[type="password"],
  .login input[type="text"] {
    font-size: 45px;
    width: 100%;
  }

  .login-input-div {
    width: 80%;
  }

  .login select {
    width: 100%;
    font-size: 40px;
  }

  .login i {
    font-size: 30px;
    margin-left: -45px;
  }

  .login-button-container {
    align-items: flex-start;
    margin-top: -50px;
  }

  #confirm-login {
    width: 40%;
  }

  #cancel-login {
    width: 40%;
  }
}

@media screen and (min-height: 851px) and (max-height: 1024px) {
  .login-container {
    height: 70vh;
  }

  .login input[type="password"],
  .login input[type="text"] {
    margin-top: 10px;
  }

  .login select {
    background-color: #fff;
    margin-top: 10px;
  }

  .login-button-container {
    height: 100px;
  }

  #confirm-login {
    height: 70px;
  }

  #cancel-login {
    height: 70px;
  }
}

@media screen and (min-height: 721px) and (max-height: 850px) {
  .login input[type="password"],
  .login input[type="text"] {
    font-size: 25px;
    margin-top: 10px;
  }

  .login select {
    background-color: #fff;
    font-size: 25px;
    margin-top: 10px;
  }

  .login i {
    font-size: 20px;
    margin-top: 25px;
  }

  #confirm-login {
    height: 50px;
  }

  #cancel-login {
    height: 50px;
  }
}

@media screen and (max-height: 720px) {
  .login-title {
    font-size: 20px;
  }

  .login-button-container {
    margin-top: 10px;
  }
}

@keyframes clicked {
  from {
    box-shadow: 2px 4px 10px #777;
  }
  to {
    box-shadow: none;
  }
}
</style>