<template>
    <div class="btn">
        <i :class="icon" :style="styl"></i>
   </div>
</template>

<script>
export default {
    name: 'Button',
    props: {
        icon: String,
        styl: String
    }
}
</script>

<style>

.btn{
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #21a0a0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 3px 4px 5px 3px #ccc;
}

/*.btn:active{
    animation-name: clicar;
    animation-duration: 1s;
}

@keyframes clicar {
    from{
        box-shadow: 3px 4px 5px 3px #ccc;
    }

    to{
        box-shadow: 3px 4px 5px 3px #fff;
    }
}*/
.btn:active{
    animation-name: clicked;
    animation-duration: .2s;
}

@keyframes clicked {
    from{
        box-shadow: 3px 4px 5px 3px #ccc;
    }
    to{
        box-shadow: none;
    }
}

@keyframes slide-in-add {
    from {
        bottom: 190px;
        visibility: hidden;
        transform: rotate(45deg);
        box-shadow: 3px 4px 5px 3px #fff;
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        bottom: 250px;
        visibility: visible;
        transform: rotate(0deg);
        box-shadow: 3px 4px 5px 3px #ccc;
        background-color: #6cd4ff;
    }
}

@keyframes slide-out-add {
    from {
        bottom: 250px;
        visibility: visible;
        transform: rotate(0deg);
        box-shadow: 3px 4px 5px 3px #ccc;
        background-color: #6cd4ff;
    }
    to {
        bottom: 190px;
        visibility: hidden;
        transform: rotate(45deg);
        box-shadow: 3px 4px 5px 3px #fff;
        background-color: rgba(0, 0, 0, 0);
    }
}

@keyframes slide-in-file {
    from {
        bottom: 115px;
        visibility: hidden;
        transform: rotate(45deg);
        box-shadow: 3px 4px 5px 3px #fff;
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        bottom: 175px;
        visibility: visible;
        transform: rotate(0deg);
        box-shadow: 3px 4px 5px 3px #ccc;
        background-color: #fabc3c;
    }
}

@keyframes slide-out-file {
    from {
        bottom: 175px;
        visibility: visible;
        transform: rotate(0deg);
        box-shadow: 3px 4px 5px 3px #ccc;
        background-color: #fabc3c;
    }
    to {
        bottom: 115px;
        visibility: hidden;
        transform: rotate(45deg);
        box-shadow: 3px 4px 5px 3px #fff;
        background-color: rgba(0, 0, 0, 0);
    }
}

</style>