<template>
  <div class="pesquisa-paciente">
    <div class="search-div">
      <input type="text" v-model="nomePesquisa" placeholder="NOME DO PACIENTE" />
      <Button
        id="btn-search"
        @click.native="pesquisaPaciente()"
        icon="fas fa-search"
        styl="color: white;font-size:25px;"
      ></Button>
    </div>
    <div class="table-div">
      <div class="pacientes-div">
        <table>
          <thead>
            <td width="1%">Código</td>
            <td width="30%">Nome do paciente</td>
            <td width="16%">CPF</td>
            <td width="13%">Última consulta</td>
            <td width="25%">Email</td>
            <td width="20%">Telefone</td>
            <td>Histórico</td>
            
          </thead>
          <tbody v-if="ready">
            <tr class="paciente-list" v-for="value in listaPacientes" :key="value.paciente_id">
              <td>#{{ value.paciente_id }}</td>
              <td>{{ value.paciente_nome }}</td>
              <td>{{ value.paciente_cpf }}</td>
              <td>{{ formatDate(value.avaliacao_dataPaciente) }}</td>
              <td>{{ value.paciente_email }}</td>
              <td>{{ value.paciente_telefone }}</td>
              <td class="history">
                <i @click="openPaciente(value)" class="fas fa-folder-open"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <buttonRectangle info="VOLTAR" @click.native="home()" id="backSearch"></buttonRectangle>
    <div v-if="!ready" id="overlay">
      <img id="overlay-gif" src="../resources/giphy.gif" alt />
    </div>
  </div>
</template>

<script>
import Button from "../components/Button";
import axios from "axios";
import buttonRectangle from "../components/buttonRectangle";
import { baseApiUrl } from "../global";

export default {
  name: "PesquisaPaciente",
  components: {
    Button,
    buttonRectangle
  },
  data() {
    return {
      pacientesAuxiliar: [],
      pacientePassagem: [],
      avaliacaoPassagem: [],
      listaPacientes: [],
      ready: false,
      nomePesquisa: ""
    };
  },
  methods: {
    async organizeData() {
      this.getEvaluationsData();
    },
    async getEvaluationsData() {
      const evaluations = await axios.get(
        `${baseApiUrl}/dados_avaliacao`
      );

      this.listaPacientes = evaluations.data;
      this.pacientesAuxiliar = this.listaPacientes;
      this.ready = true;
      //console.log(this.listaPacientes)
    },
    home() {
      this.$router.push("/");
    },
    async openPaciente(value) {
      try {
        const paciente = await axios.get(
          `${baseApiUrl}/paciente/${value.paciente_id}`
        );

        this.$store.state.paciente = paciente.data;
        this.pacientePassagem = [];
        this.avaliacaoPassagem = [];
        this.$router.push("tela_paciente");
      } catch (err) {
        alert(err);
      }
    },
    pesquisaPaciente() {
      this.listaPacientes = this.pacientesAuxiliar;
      const listaFilter = this.listaPacientes.filter(value => {
        return value.paciente_nome
          .toLowerCase()
          .includes(this.nomePesquisa.toLowerCase());
      });

      this.listaPacientes = listaFilter;
    },
    formatDate(date) {
      date = date.split("T")[0]
      date = date.split("-")
      date = `${date[2]}/${date[1]}/${date[0]}`
      return date
    }
  },
  mounted() {
    this.getEvaluationsData();
  }
};
</script>

<style>
#overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  z-index: 3;
}

#overlay-gif {
  width: 50px;
  height: 50px;
}

.pesquisa-paciente {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pesquisa-paciente i {
  cursor: pointer;
}

.search-div {
  width: 100vw;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-div input[type="text"] {
  color: #999;
  outline: none;
  border: none;
  border-bottom: 1px solid #777;
  width: 250px;
  font-size: 25px;
}

::placeholder {
  color: #ccc;
}

#btn-search {
  width: 45px;
  height: 45px;
  background-color: #fabc3c;
}

.table-div {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.pacientes-div {
  overflow: hidden;
  overflow-y: scroll;
  background-color: #ccc7;
  width: 800px;
  height: 55vh;
  border: 1px solid #fabc3c;
}

.pacientes-div table {
  width: 100%;
}

.pacientes-div thead td {
  background-color: #fff;
  font-size: 20px;
  padding-left: 8px;
}

.paciente-list {
  height: 40px;
  background: linear-gradient(to bottom, #fff, #ccc);
  font-size: 20px;
}

.paciente-list td {
  padding-left: 8px;
  color: #777;
}

.history {
  text-align: center;
}

#backSearch {
  margin-top: 50px;
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

@media screen and (max-width: 800px) {
  .pacientes-div {
    width: 100vw;
  }
}
</style>