<template>
  <div class="Quest2">
    <div id="title2">
      {{ $store.state.paciente.paciente_nome }} - Auto Avaliação
    </div>
    <form action="" id="formQuest2">
      <p id="feed">INGESTÃO ALIMENTAR</p>
      <p id="habitualFeed">
        Comparada com a minha alimentação habitual no último mês, eu tenho
        comido:
      </p>
      <div class="barLevel2">
        <input
          type="radio"
          value="0"
          v-model="monthFood"
          checked="checked"
          name="bar"
          id="leftOp"
        />
        <label for="leftOp">A mesma coisa</label>
        <input
          type="radio"
          value="2"
          v-model="monthFood"
          name="bar"
          id="centerOp"
        />
        <label for="centerOp">Mais que o habitual</label>
        <input
          type="radio"
          value="1"
          v-model="monthFood"
          name="bar"
          id="rightOp"
        />
        <label for="rightOp">Menos que o habitual</label>
      </div>
      <div :class="[actualFeedContainer, actualFeedDynamic]">
        <p id="actualFeed">Atualmente, eu estou comendo:</p>
        <div class="foodInputs">
          <input
            type="radio"
            name="food"
            value="1"
            v-model="actualFood"
            id="solidFoodLess"
          />
          <label for="solidFoodLess">
            A mesma comida(sólida) em menor quantidade que o habitual</label
          ><br />
          <input
            type="radio"
            name="food"
            value="2"
            v-model="actualFood"
            id="solidFoddLittle"
          />
          <label for="solidFoddLittle">
            A mesma comida(sólida) em pouca quantidade</label
          ><br />
          <input
            type="radio"
            name="food"
            value="3"
            v-model="actualFood"
            id="onlyLiquid"
          />
          <label for="onlyLiquid"> Apenas alimentos líquidos</label><br />
          <input
            type="radio"
            name="food"
            value="4"
            v-model="actualFood"
            id="supplement"
          />
          <label for="supplement"> Apenas suplementos nutricionais</label><br />
          <input
            type="radio"
            name="food"
            value="5"
            v-model="actualFood"
            id="littleAny"
          />
          <label for="littleAny">
            Muito pouca quantidade de qualquer alimento</label
          ><br />
          <input
            type="radio"
            name="food"
            value="0"
            v-model="actualFood"
            id="vein"
          />
          <label for="vein"> Apenas alimentos por sonda ou pela veia</label
          ><br />
        </div>
      </div>
    </form>
    <div class="controlQuest2">
      <buttonRectangle
        info="VOLTAR"
        @click.native="quest1()"
        id="back2"
      ></buttonRectangle>
      <buttonRectangle
        info="AVANÇAR"
        @click.native="setFood()"
        id="next2"
      ></buttonRectangle>
    </div>
    <div v-if="!stepComplete" class="guidance-overlay">
      <guidance-modal :step="step"/>
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import GuidanceModal from '../components/GuidanceModal.vue';

export default {
  name: "Quest2",
  components: {
    buttonRectangle,
    GuidanceModal
  },
  data() {
    return {
      actualFeedContainer: "actualFeed-container",
      actualFeedDynamic: "",
      monthFood: this.$store.state.versaoPaciente.monthFood,
      actualFood: this.$store.state.versaoPaciente.actualFood,
      step: {
        title: "Você está na etapa 2",
        message: 'Aqui você deve informar se está comendo mais, menos ou o mesmo que o habitual. Caso seja menos escolha a opção que mais se encaixa para justificar a redução na quantidade de alimento ingerido.',
        close: () => {
          this.stepComplete = true;
        }
      },
      stepComplete: false
    };
  },
  methods: {
    quest1() {
      this.$router.push("quest1");
    },
    quest3() {
      this.$router.push("quest3");
    },
    setFood() {
      let todayfeed = this.actualFood;
      let monthfeed = this.monthFood;
      if (this.actualFood == "4") {
        todayfeed = "3";
      }
      if (this.actualFood == "5") {
        todayfeed = "4";
      }
      if (this.monthFood == "2") {
        monthfeed = "0";
      }
      this.$store.state.versaoPaciente.actualFood = this.actualFood;
      this.$store.state.versaoPaciente.monthFood = this.monthFood;
      this.$store.state.valueQuest2 =
        Number(todayfeed) + Number(monthfeed);

      this.quest3();
    },
    visibleChecked() {
      if (this.monthFood == 1) {
        this.actualFeedDynamic = "";
      } else {
        this.actualFeedDynamic = "actualFood-hidden";
      }
    },
    disableMenu() {
      this.$store.state.isMenuVisible = false;
    },
  },
  computed: {
    versaoPaciente() {
      return this.$store.state.versaoPaciente;
    },
    isMenuVisible() {
      return this.$store.state.isMenuVisible;
    },
  },
  mounted() {
    this.disableMenu();
  },
  watch: {
    monthFood: function () {
      if (this.monthFood) {
        this.visibleChecked();
      }
    },
  },
};
</script>

<style>
.actualFeed-container {
  position: relative;
  left: 50px;
}

.actualFood-hidden {
  visibility: hidden;
}

.controlQuest2 {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.Quest2 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  height: 100vh;
  padding-left: 50px;
  padding-top: 30px;
}

#formQuest2 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  height: 50vh;
}

.Quest2 form p {
  font-size: 35px;
  position: relative;
  width: 80vw;
}

.barLevel2 {
  border-radius: 12px;
  height: 35px;
}

.barLevel2 input[type="radio"] {
  display: none;
}

.barLevel2 input[type="radio"] + label {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  background-color: #21a0a0;
  width: 150px;
  height: 34px;
  line-height: 35px;
  border-radius: 12px;
  margin: 5px 10px;
}

.barLevel2 input[type="radio"]:checked + label {
  outline: 0;
  background-image: none;
  background-color: rgb(46, 129, 127);
  border: 1px solid rgb(46, 129, 127);
}

.foodInputs input[type="radio"] {
  display: none;
}

.foodInputs label {
  cursor: pointer;
}

.foodInputs input[type="radio"] + label:before {
  border: 1px solid #777;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(8px);
}

.foodInputs input[type="radio"]:checked + label:before {
  color: #777;
  content: "\25CF";
  text-align: center;
}

.foodInputs input[type="radio"]:checked + label:after {
  font-weight: bold;
}

.foodInputs {
  color: rgb(131, 127, 127);
  left: 60px;
  top: 490px;
  font-size: 27px;
}

#next2 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#back2 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#title2 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 100vw;
}

@media screen and (min-width: 576px) and (max-width: 774px) {
  .controlQuest2 {
    justify-content: center;
  }

  #formQuest2 {
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #next2 {
    margin-left: 10px;
    width: 200px;
    height: 50px;
  }

  #back2 {
    margin-right: 10px;
    width: 200px;
    height: 50px;
  }

  #habitualFeed {
    height: 100px;
  }
}

@media screen and (max-width: 575px) {
  .Quest2 {
    height: auto;
    width: 90vw;
    left: 20px;
    justify-content: center;
  }

  #formQuest2 {
    height: auto;
    margin-top: 30px;
  }

  #formQuest2 p {
    font-size: 30px;
  }

  #habitualFeed {
    height: auto;
  }

  .barLevel2 {
    margin-top: 20px;
    width: 80vw;
    height: 100px;
  }

  .barLevel2 input[type="radio"] + label {
    display: block;
    font-size: 25px;
    width: 100%;
  }

  #actualFeed {
    margin-top: 20px;
  }

  #next2 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #back2 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  .controlQuest2 {
    margin-bottom: 20px;
  }
  .actualFeed-container {
    left: 0;
  }
}

@media screen and (max-width: 400px) {
  #actualFeed {
    height: 80px;
  }
}
</style>