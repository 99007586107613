<template>
  <div class="modal">
    <form action>
      <h1>{{ msg }}</h1>
      <input
        type="email"
        v-model="paciente_nome"
        maxlength="30"
        id="nome"
        placeholder="Nome Completo"
      />
      <br />
      <the-mask
        type="text"
        v-model="paciente_telefone"
        mask="(##) # ####-####"
        maxlength="30"
        id="telefone"
        placeholder="Telefone"
      ></the-mask>
      <br />
      <input
        type="text"
        v-model="paciente_email"
        maxlength="30"
        id="email"
        placeholder="Email"
      />
      <br />
      <div class="dataNasc-div">
        <label for="dataNascimento">Data de nascimento:</label>
        <the-mask
          type="text"
          mask="##/##/####"
          placeholder="dd/mm/aaaa"
          v-model="paciente_dataNascimento"
          id="dataNascimento"
        ></the-mask>
      </div>
      <div class="cpf-div">
        <the-mask
          type="text"
          mask="###.###.###-##"
          v-model="paciente_cpf"
          id="cpf"
          placeholder="CPF"
        ></the-mask>
      </div>
      <div class="btn-container">
        <Button
          icon="fas fa-plus"
          @click.native="setPaciente()"
          styl="color: white; font-size: 40px;"
          id="btn-add"
        ></Button>
        <Button
          icon="fas fa-times"
          @click.native="closeModal()"
          styl="color: white; font-size: 40px;"
          id="btn-close"
        ></Button>
      </div>
    </form>
  </div>
</template>

<script>
import Button from "./Button";
import axios from "axios";
import { TheMask } from "vue-the-mask";
import { baseApiUrl } from "../global";

export default {
  name: "Modal",
  props: {
    msg: String
  },
  components: {
    Button,
    TheMask
  },
  data() {
    return {
      paciente_nome: "",
      paciente_telefone: "",
      paciente_email: "",
      paciente_dataNascimento: "",
      paciente_cpf: "",
      paciente_dataNascimento_formatada: ""
    };
  },
  methods: {
    verificaEmail() {
      if(this.paciente_email != "") {
        const regExEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regExEmail.test(this.paciente_email) ? true : false
      }
      else {
        return true
      }
    },

    verificaData() {
      if (this.paciente_dataNascimento.length === 8) {
        const dia = this.paciente_dataNascimento.slice(0, 2);
        const mes = this.paciente_dataNascimento.slice(2, 4);
        const ano = this.paciente_dataNascimento.slice(4, 8);
        if (mes > 12) return false;
        if (ano > new Date().getFullYear()) return false;
        if (!this.checaNumeroDias(mes, dia)) return false;
        this.paciente_dataNascimento_formatada = ano + "-" + mes + "-" + dia;
        return true;
      }
      return false;
    },
    checaNumeroDias(mes, dia) {
      const catalogoMeses = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (dia > catalogoMeses[mes - 1]) return false;
      return true;
    },
    closeModal() {
      if (this.$store.state.visibleModal) {
        document.getElementById("modal").style.removeProperty("animation");
        document
          .getElementById("modal")
          .style.setProperty("animation", "modal-out .5s");
        document
          .getElementById("modal")
          .style.setProperty("animation-fill-mode", "forwards");
        this.$store.state.visibleModal = false;
      }
    },
    async setPaciente() {
      if (this.paciente_nome == ""){
        alert("Insira um nome!")
      }
      else if(!this.verificaData()) {
        alert("Insira uma data válida")
      }
      else if(!this.verificaCpf() && this.paciente_cpf !== '') {
        alert("Insira um CPF válido")
      }
      else if(!this.verificaEmail()) {
        alert("Insira um email válido!")
      }
      else {
        let aux = this.paciente_cpf.split("");
        this.paciente_cpf = "";

        for (let i = 0; i < aux.length; i++) {
          if ((i + 1) % 3 == 0 && i + 1 != 9) {
            this.paciente_cpf += aux[i];
            this.paciente_cpf += ".";
          } else if (i + 1 == 9) {
            this.paciente_cpf += aux[i];
            this.paciente_cpf += "-";
          } else {
            this.paciente_cpf += aux[i];
          }
        }

        this.$store.state.paciente.paciente_nome = this.paciente_nome;
        this.$store.state.paciente.paciente_telefone = this.paciente_telefone;
        this.$store.state.paciente.paciente_email = this.paciente_email;        
        this.$store.state.paciente.paciente_dataNascimento = this.paciente_dataNascimento_formatada;
        this.$store.state.paciente.paciente_cpf = this.paciente_cpf;

        try {
          const paciente = await axios.post(
            `${baseApiUrl}/paciente`,
            this.$store.state.paciente
          );

          this.$store.state.paciente.paciente_id = paciente.data[0];
          this.$store.state.visibleModal = false;
          this.$router.push("tela_paciente");
        } catch (err) {
          //console.log(err);
        }
      }
    },
    verificaCpf() {
      let cpf = this.paciente_cpf.split("");
      const verificador2 = cpf.pop();
      const verificador1 = cpf.pop();

      const resultado1 = cpf.reduce((soma, digito, index) => {
        return Number(soma) + Number(digito) * (10 - index);
      }, 0);
      const candidato1 = resultado1 % 11 < 2 ? 0 : 11 - (resultado1 % 11);
      cpf.push(candidato1);
      const resultado2 = cpf.reduce((soma, digito, index) => {
        return Number(soma) + Number(digito) * (11 - index);
      }, 0);
      const candidato2 = resultado2 % 11 < 2 ? 0 : 11 - (resultado2 % 11);
      if (candidato1 == verificador1 && candidato2 == verificador2) {
        return true;
      }
      return false;
    }
  },
  computed: {
    visibleModal() {
      return this.$store.state.visibleModal;
    },
    paciente() {
      return this.$store.state.paciente;
    }
  }
};
</script>

<style>
.btn-container {
  height: 100px;
  align-items: flex-end;
  justify-content: space-around;
  display: flex;
}

#dataNascimento {
  margin-top: 0;
  color: #777;
}

.dataNasc-div {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
}

.dataNasc-div label {
  margin-top: 10px;
  color: #777;
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.modal {
  visibility: hidden;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background-color: #fefefe;
  box-shadow: 3px 3px 5px 6px #ccc;
  width: 40vw;
  height: 540px;
  position: absolute;
  left: 30vw;
  bottom: 30vh;
  top: 50px;
  padding: 20px;
  padding-bottom: 30px;
  z-index: 4;
}

.modal input {
  color: #777;
  font-size: 25px;
  margin-top: 40px;
  border: none;
  border-bottom: 1px solid #292b2e;
  width: 300px;
  outline: none;
}

#btn-add,
#btn-close {
  width: 60px;
  height: 60px;
}

#btn-close {
  background-color: #fc7753;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .modal {
    left: 20vw;
    width: 60vw;
  }

  .modal input {
    background-color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .modal {
    left: 10vw;
    bottom: 0;
    width: 80vw;
  }

  .modal input {
    width: 100%;
    background-color: #fff;
  }
}

@keyframes modal-in {
  from {
    bottom: 30vh;
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    bottom: 35vh;
    opacity: 1;
  }
}

@keyframes modal-out {
  from {
    visibility: visible;
    bottom: 35vh;
    opacity: 1;
  }
  to {
    bottom: 30vh;
    visibility: hidden;
    opacity: 0;
  }
}
</style>