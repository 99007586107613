<template>
  <div class="cadastro">
    <div class="register-container">
      <div class="register-title">
        <h1>Cadastro</h1>
      </div>
      <form action>
        <div class="register-input-div">
          <input type="text" v-model="cadastro.adm_login" placeholder="Login" />
        </div>
        <div class="register-input-div">
          <input type="password" v-model="cadastro.adm_senha" placeholder="Senha" />
        </div>
        <div class="register-input-div">
          <select
            id="select-nutricionista"
            name="select-nutricionista"
            :required="true"
            v-model="cadastro.adm_fk_nut"
          >
            <option disabled value>Selecione um nutricionista(opcional)</option>
            <option
              v-for="value in nutricionistas"
              :key="value.nutricionista_id"
              :value="value.nutricionista_id"
            >{{ value.nutricionista_nome }}</option>
          </select>
        </div>
      </form>
      <div class="register-button-container">
        <buttonRectangle
          info="CONFIRMAR"
          styl="font-size: 20px;"
          @click.native="registerAdmin()"
          id="confirm-register"
        ></buttonRectangle>
        <buttonRectangle
          info="CANCELAR"
          styl="font-size: 20px;"
          @click.native="cancela()"
          id="cancel-register"
        ></buttonRectangle>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import buttonRectangle from "../components/buttonRectangle";
import { baseApiUrl } from "../global";

export default {
  name: "cadastro",
  components: {
    buttonRectangle
  },
  data() {
    return {
      nutricionistas: [],
      cadastro: {
        adm_fk_nut: ""
      },
      passFlag: true
    };
  },
  methods: {
    carregaNutricionista() {
      axios
        .get(`${baseApiUrl}/nutricionista`)
        .then(res => {
          this.nutricionistas = res.data;
        })
        .catch(err => {
          alert(err);
        });
    },
    registerAdmin() {
      axios
        .post(`${baseApiUrl}/administrador`, this.cadastro)
        .then(() => {
          alert("Cadastro realizado com sucesso!");
          return this.$router.push("/");
        })
        .catch(err => {
          alert(err);
        });
    },
    cancela() {
      this.$router.push("/");
    }
  },
  mounted() {
    this.carregaNutricionista();
  }
};
</script>

<style>
.cadastro {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.register-title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #f5f5f5;
  width: 100%;
  height: 5vh;
  margin-top: 20px;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
  min-height: 50vh;
  width: 30vw;
  background-color: #6cc0c0;
  box-shadow: 2px 4px 10px #777;
  border-radius: 5px;
}

.cadastro form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.cadastro input[type="password"],
.cadastro input[type="text"] {
  padding-left: 7px;
  border: none;
  border-bottom: 1px solid #444;
  outline: none;
  margin-top: 10px;
  width: 350px;
  font-size: 25px;
  color: #777;
}

.cadastro i {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  margin: 17px 0 0 -25px;
  float: right;
}

.cadastro select {
  border-color: #fff;
  border-bottom: 1px solid #444;
  outline: none;
  width: 350px;
  margin-top: 10px;
  font-size: 25px;
  color: #777;
}

#confirm-register {
  margin-right: 10px;
  width: 165px;
  height: 60px;
  display: flex;
  background-color: #56e049;
  box-shadow: 2px 4px 10px #777;
}

#cancel-register {
  margin-left: 10px;
  width: 165px;
  height: 60px;
  display: flex;
  background-color: #fc7753;
  box-shadow: 2px 4px 10px #777;
}

.register-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

@media screen and (max-width: 1250px) {
  .register-container {
    width: 70vw;
  }

  .register-title {
    font-size: 30px;
  }

  .cadastro input[type="password"],
  .cadastro input[type="text"] {
    font-size: 45px;
    width: 100%;
  }

  .register-input-div {
    width: 80%;
  }

  .cadastro select {
    width: 100%;
    font-size: 40px;
  }

  .cadastro i {
    font-size: 30px;
    margin-left: -45px;
  }

  .register-button-container {
    align-items: flex-start;
  }

  #confirm-register {
    width: 40%;
  }

  #cancel-register {
    width: 40%;
  }
}

@media screen and (min-height: 851px) and (max-height: 1024px) {
  .register-container {
    height: 70vh;
  }

  .cadastro input[type="password"],
  .cadastro input[type="text"] {
    margin-top: 10px;
  }

  .cadastro select {
    background-color: #fff;
    margin-top: 10px;
  }

  .register-button-container {
    height: 100px;
  }

  #confirm-register {
    height: 70px;
  }

  #cancel-register {
    height: 70px;
  }
}

@media screen and (max-width: 850px) {
  .cadastro input[type="password"],
  .cadastro input[type="text"] {
    font-size: 25px;
    margin-top: 10px;
  }

  .cadastro select {
    background-color: #fff;
    font-size: 25px;
    margin-top: 10px;
  }

  .register-container {
  width: 80vw;
}

  .cadastro i {
    font-size: 20px;
    margin-top: 25px;
  }

  #confirm-register {
    height: 50px;
  }

  #cancel-register {
    height: 50px;
  }
}

@media screen and (max-height: 720px) {
  .register-title {
    font-size: 20px;
  }

  .register-button-container {
    margin-top: 10px;
  }
}

@keyframes clicked {
  from {
    box-shadow: 2px 4px 10px #777;
  }
  to {
    box-shadow: none;
  }
}
</style>