<template>
  <div id="app">
    <navBar></navBar>
    <Menu></Menu>
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src
import navBar from './components/navBar'
import Menu from './components/Menu'

export default {
  components: {
    navBar,
    Menu
  } 
}
</script>

<style>
    @font-face {
        font-family: "AgencyFB";
        src: url("./fonts/agency-fb_[allfont.net].ttf");
    }

* {
  font-family: "AgencyFB";
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

body{
  overflow-x: hidden;
  min-height: 100vh;
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
  
}
</style>
