<template>
  <div class="QMedico8">
    <div id="titleMed8">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico8">
      <div class="metabolic-demand-div">
        <p id="metabolic-demand">DEMANDA METABÓLICA</p>
        <div class="fever-div">
          <p id="fever">Febre:</p>
          <div class="radio-div">
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="0"
              v-model="feverRadio"
              name="fever-radio"
              id="fever-radio1"
            />
            <label for="fever-radio1">Sem febre</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="1"
              v-model="feverRadio"
              name="fever-radio"
              id="fever-radio2"
            />
            <label for="fever-radio2">Maior que 37,2 e menor que 38,3ºC</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="2"
              v-model="feverRadio"
              name="fever-radio"
              id="fever-radio3"
            />
            <label for="fever-radio3">Maior ou igual a 38,3 e menor que 38,9ºC</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="3"
              v-model="feverRadio"
              name="fever-radio"
              id="fever-radio4"
            />
            <label for="fever-radio4">Acima de 38,9ºC</label>
            <br />
          </div>
        </div>
        <div class="fever-time-div">
          <p id="fever-time">Tempo de Febre:</p>
          <div class="radio-div">
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="0"
              v-model="feverTimeRadio"
              name="fever-time-radio"
              id="fever-time-radio1"
            />
            <label for="fever-time-radio1">Sem febre</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="1"
              v-model="feverTimeRadio"
              name="fever-time-radio"
              id="fever-time-radio2"
            />
            <label for="fever-time-radio2">Menos que 72 horas</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="2"
              v-model="feverTimeRadio"
              name="fever-time-radio"
              id="fever-time-radio3"
            />
            <label for="fever-time-radio3">Igual a 72 horas</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="3"
              v-model="feverTimeRadio"
              name="fever-time-radio"
              id="fever-time-radio4"
            />
            <label for="fever-time-radio4">Acima de 72 horas</label>
            <br />
          </div>
        </div>
        <div class="corticosteroids-div">
          <p class="corticosteroids">Corticosteroides:</p>
          <div class="radio-div">
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="0"
              v-model="corticosteroidsRadio"
              name="corticosteroids-radio"
              id="corticosteroids-radio1"
            />
            <label for="corticosteroids-radio1">Sem corticosteroides</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="1"
              v-model="corticosteroidsRadio"
              name="corticosteroids-radio"
              id="corticosteroids-radio2"
            />
            <label
              for="corticosteroids-radio2"
            >Dose baixa (menor que 10mb equival. prednisolona/dia)</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="2"
              v-model="corticosteroidsRadio"
              name="corticosteroids-radio"
              id="corticosteroids-radio3"
            />
            <label
              for="corticosteroids-radio3"
            >Dose moderada (≥ 10mb a &lt; 30mg equival. prednisolona/dia)</label>
            <br />
            <input
              type="radio"
              @change="addPontuacaoQMed3()"
              value="3"
              v-model="corticosteroidsRadio"
              name="corticosteroids-radio"
              id="corticosteroids-radio4"
            />
            <label for="corticosteroids-radio4">Dose elevada (≥ 30mg equival. prednisolona/dia)</label>
            <br />
          </div>
        </div>
      </div>
    </form>
    <div class="pontuacao-container pontuacao3-espaco">
      <p>Pontuação total: {{ pontuacaoLocal + this.$store.state.pontuacaoTotal }}</p>
    </div>
    <div class="controlQMed8">
      <buttonRectangle info="VOLTAR" @click.native="qmedico7()" id="backMed8"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico9()" id="nextMed8"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";

export default {
  name: "QMedico8",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      feverRadio: this.$store.state.versaoMedico.versaoMedico_q3Febre,
      feverTimeRadio: this.$store.state.versaoMedico
        .versaoMedico_q3DuracaoFebre,
      corticosteroidsRadio: this.$store.state.versaoMedico
        .versaoMedico_q3Corticosteroides,
      ready: false,
      pontuacaoLocal: 0
    };
  },
  methods: {
    qmedico9() {
      //this.$store.state.avaliacao.anthropometryHeight = this.anthropometryHeight
      if (this.feverRadio > this.feverTimeRadio) {
        this.$store.state.versaoMedico.versaoMedico_quadro3 =
          Number(this.feverRadio) + Number(this.corticosteroidsRadio);
      } else {
        this.$store.state.versaoMedico.versaoMedico_quadro3 =
          Number(this.feverTimeRadio) + Number(this.corticosteroidsRadio);
      }

      this.$store.state.versaoMedico.versaoMedico_q3Febre = this.feverRadio;
      this.$store.state.versaoMedico.versaoMedico_q3DuracaoFebre = this.feverTimeRadio;
      this.$store.state.versaoMedico.versaoMedico_q3Corticosteroides = this.corticosteroidsRadio;
      this.$store.state.pontuacaoTotal += this.pontuacaoLocal;

      this.$router.push("qmedico9");
    },
    qmedico7() {
      this.$router.push("qmedico7");
    },
    addPontuacaoQMed3() {
      if (this.feverRadio && this.feverTimeRadio && this.corticosteroidsRadio) {
        if (this.feverRadio > this.feverTimeRadio) {
          this.pontuacaoLocal =
            Number(this.feverRadio) + Number(this.corticosteroidsRadio);
        } else {
          this.pontuacaoLocal =
            Number(this.feverTimeRadio) + Number(this.corticosteroidsRadio);
        }
      }
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    versaoMedico() {
      return this.$store.state.versaoMedico;
    }
  }
};
</script>

<style>
.QMedico8 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 30px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed8 {
  position: relative;
  top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.metabolic-demand-div {
  height: 620px;
}

.form-qmedico8 {
  position: relative;
  height: auto;
  width: 100vw;
  padding-left: 50px;
}

.form-qmedico8 p {
  font-size: 30px;
}

.form-qmedico8 label {
  font-size: 25px;
}

.fever-div label,
.fever-time-div label,
.corticosteroids-div label {
  position: relative;
  left: 20px;
}

.fever-div,
.fever-time-div,
.corticosteroids-div {
  position: relative;
}

.fever-div {
  top: 30px;
}

.fever-time-div {
  top: 60px;
}

.corticosteroids-div {
  top: 90px;
}

.fever-div input[type="radio"],
.fever-time-div input[type="radio"],
.corticosteroids-div input[type="radio"] {
  display: none;
}

.fever-div label,
.fever-time-div label,
.corticosteroids-div label {
  cursor: pointer;
}

.fever-div input[type="radio"] + label:before,
.fever-time-div input[type="radio"] + label:before,
.corticosteroids-div input[type="radio"] + label:before {
  border: 1px solid #21a0a0;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(8px);
}

.fever-div input[type="radio"]:checked + label:before,
.fever-time-div input[type="radio"]:checked + label:before,
.corticosteroids-div input[type="radio"]:checked + label:before {
  color: #21a0a0;
  content: "\25CF";
  text-align: center;
}

.fever-div input[type="radio"]:checked + label:after,
.fever-time-div input[type="radio"]:checked + label:after,
.corticosteroids-div input[type="radio"]:checked + label:after {
  font-weight: bold;
}

#nextMed8 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed8 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed8 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 100vw;
  height: auto;
  padding-left: 50px;
}

@media screen and (max-width: 678px) {
  #titleMed8 {
    font-size: 35px;
    left: 0;
    padding-left: 20px;
    width: 80vw;
  }

  .form-qmedico8 {
    margin-top: 20px;
    padding-left: 20px;
    left: 0;
  }

  .form-qmedico8 {
    margin-bottom: 40px;
    height: auto;
    width: 80vw;
    left: 20px;
  }

  .form-qmedico8 p {
    font-size: 25px;
  }

  .form-qmedico8 label {
    font-size: 25px;
  }

  .controlQMed8 {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  #nextMed8 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed8 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>