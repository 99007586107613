<template>
  <div class="relatorio" v-if="reportReady">
    <div class="relatorio-for-print" id="patient-report">
      <div id="title-relatorio">
        <p>
          RELATÓRIO - {{ paciente.paciente_nome }} -
          {{ avaliacao.avaliacao_dataMedico }}
        </p>
      </div>
      <div class="two-div">
        <div class="two-post-it">
          <div id="evolution-post-it">
            <span class="post-it-title">EVOLUÇÃO DE PESO:</span>
            <div class="topics">
              <div class="topic-group">
                <span class="outer-circle" id="actual-circle">
                  <span class="inner-circle"></span>
                </span>
                <p>
                  Peso atual:
                  <b>{{ versaoPaciente.versaoPaciente_q1PesoAtual }} Kg</b>
                </p>
              </div>
              <div class="topic-group">
                <span class="outer-circle" id="month-circle">
                  <span class="inner-circle"></span>
                </span>
                <p>
                  Peso há 1 mês:
                  <b>{{ versaoPaciente.versaoPaciente_q1PesoMes }} Kg</b>
                </p>
              </div>
              <div class="topic-group">
                <span class="outer-circle" id="smst-circle">
                  <span class="inner-circle"></span>
                </span>
                <p>
                  Peso há 6 meses:
                  <b>{{ versaoPaciente.versaoPaciente_q1PesoSemestre }} Kg</b>
                </p>
              </div>
            </div>
            <div class="weight-statistics">
              <div>
                <p>Percentual de ganho no último mês:</p>
                <h2 :class="themePercentual">
                  {{ versaoMedico.versaoMedico_q1PercentualPeso }}
                </h2>
              </div>
              <div>
                <p>Percentual de ganho no último semestre:</p>
                <h2 :class="themePercentualSemestre">
                  {{ percentualPesoSemestre }}
                </h2>
              </div>
              <div>
                <p>Pontuação da última avaliação:</p>
                <h2 :class="themeQuad1">
                  {{ versaoMedico.versaoMedico_quadro1 }}
                </h2>
              </div>
            </div>
          </div>
          <div id="disease-post-it">
            <span class="post-it-title"
              >DOENÇAS E NECESSIDADES NUTRICIONAIS:</span
            >
            <div class="topics">
              <div class="topic-group">
                <span class="topic-square" id="estad-square"></span>
                <p>Estadiamento da doença principal:</p>
                <h3 :class="themeEstad">
                  {{ versaoMedico.versaoMedico_q2Estadiamento }}
                </h3>
              </div>
              <div class="topic-group">
                <span class="topic-square" id="diag-square"></span>
                <p>Outros diagnósticos relevantes:</p>
                <h3>{{ versaoMedico.versaoMedico_q1DiagnosticoRelevante }}</h3>
              </div>
              <div class="topic-group">
                <span class="topic-square" id="cond-square"></span>
                <p>Demais condições levantadas:</p>
                <ul>
                  <li v-for="(values, index) in condicaoClinica" :key="index">
                    -{{ values.condicaoClinica_nome }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="nutrition-field">
              <p v-if="versaoMedico.versaoMedico_suplementacao == 'Sim'">
                Existe necessidade de suplementação
              </p>
              <p v-else>Não existe necessidade de suplementação</p>
              <p v-if="versaoMedico.versaoMedico_suplementacao == 'Sim'">
                Tipo de suplementação: {{ versaoMedico.suplementacao_tipo }}
              </p>
              <p v-if="versaoMedico.versaoMedico_suplementacao == 'Sim'">
                Proteína: {{ versaoMedico.suplementacao_proteina }}
              </p>
              <p v-if="versaoMedico.versaoMedico_suplementacao == 'Sim'">
                Calorias: {{ versaoMedico.suplementacao_caloria }}
              </p>
              <p
                v-if="
                  versaoMedico.versaoMedico_suplementacao == 'Sim' &&
                  versaoMedico.suplementacao_omega == 1
                "
              >
                Deve ser rico em Ômega 3
              </p>
            </div>
            <div class="disease-statistics">
              <div>
                <p>Pontuação da última avaliação:</p>
                <h2 :class="themeQuad2">
                  {{ versaoMedico.versaoMedico_quadro2 }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="two-post-it">
          <div id="metabolic-post-it">
            <span class="post-it-title">DEMANDA METABÓLICA:</span>
            <div class="topics">
              <div class="topic-group">
                <span class="topic-square" id="estad-square"></span>
                <p>Febre:</p>
                <h3 :class="themeFerver">{{ ferver }}</h3>
              </div>
              <div class="topic-group">
                <span class="topic-square" id="diag-square"></span>
                <p>Duração da Febre:</p>
                <h3 :class="themeFerverDuration">{{ ferverDuration }}</h3>
              </div>
              <div class="topic-group">
                <span class="topic-square" id="cond-square"></span>
                <p>Corticosteroides:</p>
                <h3 :class="themeCorticosteroids">{{ corticosteroids }}</h3>
              </div>
            </div>
            <div class="metabolic-statistics">
              <div>
                <p>Pontuação da última avaliação:</p>
                <h2 :class="themeQuad3">
                  {{ versaoMedico.versaoMedico_quadro3 }}
                </h2>
              </div>
            </div>
          </div>
          <div id="asg-post-it">
            <span class="post-it-title">Avaliação Global:</span>
            <div id="asg-organization">
              <div>
                <p>
                  Peso:
                  <span :class="themeAvaPes">{{
                    avaliacaoGlobalAtual.avaliacaoGlobal_peso
                  }}</span>
                </p>
                <p id="ingest-p">
                  Ingestão de nutrientes:
                  <span :class="themeAvaNut">{{
                    avaliacaoGlobalAtual.avaliacaoGlobal_nutrientes
                  }}</span>
                </p>
                <p id="symptoms-p">
                  Sintomas com Impacto Nutricional:
                  <span :class="themeAvaImpNut">{{
                    avaliacaoGlobalAtual.avaliacaoGlobal_impactoNutricional
                  }}</span>
                </p>
              </div>
              <div>
                <p>
                  Funcionalidade:
                  <span :class="themeAvaFun">{{
                    avaliacaoGlobalAtual.avaliacaoGlobal_funcionalidade
                  }}</span>
                </p>
                <p id="exams-p">
                  Exame físico:
                  <span :class="themeAvaExaFis">{{
                    avaliacaoGlobalAtual.avaliacaoGlobal_exameFisico
                  }}</span>
                </p>
              </div>
            </div>
            <div class="asg-statistics">
              <p>
                Avaliação Global:
                <span :class="themeQuad5">{{ avaliacaoGlobal }}</span>
              </p>
              <p>
                ASG PPP:
                <span :class="themeASG">{{
                  versaoMedico.versaoMedico_pontuacaoTotal
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="solo-post-it">
        <div id="exam-post-it">
          <span class="post-it-title">EXAME FÍSICO:</span>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Temporas (músculos temporais)</p>
            <p :class="exameFisicoClasses[0]">
              {{ exameFisico[0].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Clavículas (peitorais e deltoides)</p>
            <p :class="exameFisicoClasses[1]">
              {{ exameFisico[1].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Ombros (deltoides)</p>
            <p :class="exameFisicoClasses[2]">
              {{ exameFisico[2].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Musculatura intraóssea (mãos)</p>
            <p :class="exameFisicoClasses[3]">
              {{ exameFisico[3].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">
              Escápula (dorsal maior, trapézio, deltoides)
            </p>
            <p :class="exameFisicoClasses[4]">
              {{ exameFisico[4].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Coxa (quadríceps)</p>
            <p :class="exameFisicoClasses[5]">
              {{ exameFisico[5].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Panturrilha (gastrocnêmius)</p>
            <p :class="exameFisicoClasses[6]">
              {{ exameFisico[6].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="middle-title">
              AVALIAÇÃO GERAL DO ESTADO MUSCULAR - RESERVAS DE GORDURA
            </p>
            <p :class="exameFisicoClasses[7]">
              {{ exameFisico[7].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Região periorbital</p>
            <p :class="exameFisicoClasses[8]">
              {{ exameFisico[8].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Prega cutânea do tríceps</p>
            <p :class="exameFisicoClasses[9]">
              {{ exameFisico[9].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">
              Gordura sobre as costelas inferiores
            </p>
            <p :class="exameFisicoClasses[10]">
              {{ exameFisico[10].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="middle-title">
              AVALIAÇÃO GERAL DO DÉFICIT DE GORDURA - ESTADO DE HIDRATAÇÃO
            </p>
            <p :class="exameFisicoClasses[11]">
              {{ exameFisico[11].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Edema do tornozelo</p>
            <p :class="exameFisicoClasses[12]">
              {{ exameFisico[12].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Edema sacral</p>
            <p :class="exameFisicoClasses[13]">
              {{ exameFisico[13].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">Ascite</p>
            <p :class="exameFisicoClasses[14]">
              {{ exameFisico[14].exameFisicoVersaoMedico_valor }}
            </p>
          </div>
          <div class="exam-div-class">
            <p class="solo-post-it-label">
              Avaliação geral do estado de hidratação
            </p>
            <p :class="exameFisicoClasses[15]">
              {{ exameFisico[15].exameFisicoVersaoMedico_valor }}
            </p>
          </div>

          <p id="exam-score">
            PONTUAÇÃO GERAL
            <span :class="themeQuad4">{{
              versaoMedico.versaoMedico_quadro4
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="report-btn-container">
      <buttonRectangle
        info="VOLTAR"
        @click.native="telaPaciente()"
        id="backRelatorio"
      ></buttonRectangle>
      <buttonRectangle
        info="IMPRIMIR"
        @click.native="print()"
        styl="background-color: #fabc3c"
        stylBtn="background-color: #fabc3c"
        id="backRelatorio"
      ></buttonRectangle>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import buttonRectangle from "../components/buttonRectangle";
import { baseApiUrl } from "../global";

export default {
  name: "Relatorio",
  components: {
    buttonRectangle,
  },
  data() {
    return {
      resVPaciente: [],
      themePercentual: "",
      themePercentualSemestre: "",
      themeEstad: "",
      themeQuad1: "",
      themeQuad2: "",
      themeQuad3: "",
      themeQuad4: "",
      themeQuad5: "",
      themeFerver: "",
      themeFerverDuration: "",
      themeCorticosteroids: "",
      themeASG: "",
      themeAvaPes: "",
      themeAvaNut: "",
      themeAvaImpNut: "",
      themeAvaFun: "",
      themeAvaExaFis: "",
      ferver: "",
      ferverDuration: "",
      corticosteroids: "",
      avaliacaoGlobal: "",
      avaliacaoGlobalAtual: {},
      percentualPesoSemestre: "",
      exameFisicoClasses: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      reportReady: false
    };
  },
  methods: {
    print() {
      this.$htmlToPaper("patient-report");
    },
    carregaDados() {
      axios
        .get(`${baseApiUrl}/avaliacao_global`)
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            if (
              res.data[i].versaoMedico_id == this.versaoMedico.versaoMedico_id
            ) {
              this.avaliacaoGlobalAtual = res.data[i];
            }
          }
          this.checkValue();
          this.convertAvaliacaoGlobalValues();
        })
        .catch((err) => {
          alert(err);
        });

      axios
        .get(`${baseApiUrl}/exame_fisico`)
        .then((res) => {
          this.exameFisico = res.data;

          axios
            .get(
              `${baseApiUrl}/exame_fisico_versao_medico/${this.versaoMedico.versaoMedico_id}`
            )
            .then((res) => {
              for (let i = 0; i < res.data.length; i++) {
                for (let j = 0; j < this.exameFisico.length; j++) {
                  if (
                    res.data[i].efisvmed_fk_efis == this.exameFisico[j].efis_id
                  ) {
                    this.exameFisico[j].value = res.data[i].efisvmed_val;
                  }
                }
              }
              this.convertExameFisico();
            })
            .catch((err) => {
              alert(err);
            });
        })
        .catch((err) => {
          alert(err);
        });
    },
    setPercentualPesoSemestre() {
      this.percentualPesoSemestre =
        ((this.versaoPaciente.versaoPaciente_q1PesoAtual -
          this.versaoPaciente.versaoPaciente_q1PesoSemestre) *
          100) /
        this.versaoPaciente.versaoPaciente_q1PesoSemestre;
      this.percentualPesoSemestre = this.percentualPesoSemestre.toFixed(2);
    },
    checkValue() {
      if (this.versaoMedico.versaoMedico_q1PercentualPeso <= -10) {
        this.themePercentual = "resultado-negativo";
      } else if (
        this.versaoMedico.versaoMedico_q1PercentualPeso > -10 &&
        this.versaoMedico.versaoMedico_q1PercentualPeso <= -3
      ) {
        this.themePercentual = "resultado-neutro";
      } else {
        this.themePercentual = "resultado-positivo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_quadro1 < 2) {
        this.themeQuad1 = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_quadro1 == 2) {
        this.themeQuad1 = "resultado-neutro";
      } else {
        this.themeQuad1 = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_q2Estadiamento < 2) {
        this.themeEstad = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_q2Estadiamento == 2) {
        this.themeEstad = "resultado-neutro";
      } else {
        this.themeEstad = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_quadro2 < 4) {
        this.themeQuad2 = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_quadro2 == 4) {
        this.themeQuad2 = "resultado-neutro";
      } else {
        this.themeQuad2 = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_q3Febre < 1) {
        this.themeFerver = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_q3Febre == 1) {
        this.themeFerver = "resultado-neutro";
      } else {
        this.themeFerver = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_q3DuracaoFebre < 1) {
        this.themeFerverDuration = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_q3DuracaoFebre == 1) {
        this.themeFerverDuration = "resultado-neutro";
      } else {
        this.themeFerverDuration = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_q3Corticosteroides < 1) {
        this.themeCorticosteroids = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_q3Corticosteroides == 1) {
        this.themeCorticosteroids = "resultado-neutro";
      } else {
        this.themeCorticosteroids = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_quadro3 < 3) {
        this.themeQuad3 = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_quadro3 == 3) {
        this.themeQuad3 = "resultado-neutro";
      } else {
        this.themeQuad3 = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_avaliacaoGlobal < 2) {
        this.themeQuad5 = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_avaliacaoGlobal == 2) {
        this.themeQuad5 = "resultado-neutro";
      } else {
        this.themeQuad5 = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_pontuacaoTotal < 10) {
        this.themeASG = "resultado-positivo";
      } else if (this.versaoMedico.versaoMedico_pontuacaoTotal == 10) {
        this.themeASG = "resultado-neutro";
      } else {
        this.themeASG = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.avaliacaoGlobalAtual.avaliacaoGlobal_peso < 2) {
        this.themeAvaPes = "resultado-positivo";
      } else if (this.avaliacaoGlobalAtual.avaliacaoGlobal_peso == 2) {
        this.themeAvaPes = "resultado-neutro";
      } else {
        this.themeAvaPes = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.avaliacaoGlobalAtual.avaliacaoGlobal_nutrientes < 2) {
        this.themeAvaNut = "resultado-positivo";
      } else if (this.avaliacaoGlobalAtual.avaliacaoGlobal_nutrientes == 2) {
        this.themeAvaNut = "resultado-neutro";
      } else {
        this.themeAvaNut = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.avaliacaoGlobalAtual.avaliacaoGlobal_impactoNutricional < 2) {
        this.themeAvaImpNut = "resultado-positivo";
      } else if (
        this.avaliacaoGlobalAtual.avaliacaoGlobal_impactoNutricional == 2
      ) {
        this.themeAvaImpNut = "resultado-neutro";
      } else {
        this.themeAvaImpNut = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.avaliacaoGlobalAtual.avaliacaoGlobal_funcionalidade < 2) {
        this.themeAvaFun = "resultado-positivo";
      } else if (
        this.avaliacaoGlobalAtual.avaliacaoGlobal_funcionalidade == 2
      ) {
        this.themeAvaFun = "resultado-neutro";
      } else {
        this.themeAvaFun = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.avaliacaoGlobalAtual.avaliacaoGlobal_exameFisico < 2) {
        this.themeAvaExaFis = "resultado-positivo";
      } else if (this.avaliacaoGlobalAtual.avaliacaoGlobal_exameFisico == 2) {
        this.themeAvaExaFis = "resultado-neutro";
      } else {
        this.themeAvaExaFis = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.versaoMedico.versaoMedico_quadro4 < 3) {
        this.themeQuad4 = "resultado-positivo";
      } else if (
        this.versaoMedico.versaoMedico_quadro4 >= 3 &&
        this.versaoMedico.versaoMedico_quadro4 < 6
      ) {
        this.themeQuad4 = "resultado-neutro";
      } else {
        this.themeQuad4 = "resultado-negativo";
      }

      /*--------------------------------------*/

      if (this.pesoSemestre > -6) {
        this.themePercentualSemestre = "resultado-positivo";
      } else if (this.pesoSemestre > -20 && this.pesoSemestre <= -6) {
        this.themePercentualSemestre = "resultado-neutro";
      } else {
        this.themePercentualSemestre = "resultado-negativo";
      }
    },
    convertFerver() {
      switch (this.versaoMedico.versaoMedico_q3Febre) {
        case 0:
          this.ferver = "Sem febre";
          break;
        case 1:
          this.ferver = "Maior que 37,2 e menor que 38,3ºC";
          break;
        case 2:
          this.ferver = "Maior ou igual a 38,3 e menor que 38,9ºC";
          break;
        case 3:
          this.ferver = "Acima de 38,9ºC";
          break;
      }
    },
    convertFerverDuration() {
      switch (this.versaoMedico.versaoMedico_q3DuracaoFebre) {
        case 0:
          this.ferverDuration = "Sem febre";
          break;
        case 1:
          this.ferverDuration = "Menos que 72 horas";
          break;
        case 2:
          this.ferverDuration = "Igual a 72 horas";
          break;
        case 3:
          this.ferverDuration = "Acima de 72 horas";
          break;
      }
    },
    convertCorticosteroids() {
      switch (this.versaoMedico.versaoMedico_q3Corticosteroides) {
        case 0:
          this.corticosteroids = "Sem corticosteroides";
          break;
        case 1:
          this.corticosteroids =
            "Dose baixa (menor que 10mb equival. prednisolona/dia)";
          break;
        case 2:
          this.corticosteroids =
            "Dose moderada (≥ 10mb a < 30mg equival. prednisolona/dia)";
          break;
        case 3:
          this.corticosteroids =
            "Dose elevada (≥ 30mg equival. prednisolona/dia)";
          break;
      }
    },
    convertAvaliacaoGlobalValues() {
      for (let value in this.avaliacaoGlobalAtual) {
        switch (this.avaliacaoGlobalAtual[value]) {
          case 1:
            this.avaliacaoGlobalAtual[value] = "A";
            break;
          case 2:
            this.avaliacaoGlobalAtual[value] = "B";
            break;
          case 3:
            this.avaliacaoGlobalAtual[value] = "C";
            break;
        }
      }
    },
    convertAvaliacaoGlobal() {
      switch (this.versaoMedico.versaoMedico_avaliacaoGlobal) {
        case "1":
          this.avaliacaoGlobal = "A";
          break;
        case "2":
          this.avaliacaoGlobal = "B";
          break;
        case "3":
          this.avaliacaoGlobal = "C";
          break;
      }
    },
    convertExameFisico() {
      for (let i = 0; i < this.exameFisico.length; i++) {
        switch (this.exameFisico[i].exameFisicoVersaoMedico_valor) {
          case "0":
            this.$store.state.exameFisico[i].exameFisicoVersaoMedico_valor =
              "Sem défict / edema";
            this.exameFisicoClasses[i] = "resultado-positivo";
            break;
          case "1":
            this.$store.state.exameFisico[i].exameFisicoVersaoMedico_valor =
              "Leve";
            this.exameFisicoClasses[i] = "resultado-positivo";
            break;
          case "2":
            this.$store.state.exameFisico[i].exameFisicoVersaoMedico_valor =
              "Moderado";
            this.exameFisicoClasses[i] = "resultado-neutro";
            break;
          case "3":
            this.$store.state.exameFisico[i].exameFisicoVersaoMedico_valor =
              "Grave";
            this.exameFisicoClasses[i] = "resultado-negativo";
            break;
        }
      }
      this.$forceUpdate();
    },
    telaPaciente() {
      this.$router.push("/historico");
    },
    formatDate() {
      let date = this.$store.state.avaliacao.avaliacao_dataMedico.split("T");
      date = date[0].split("-");
      date = date[2] + "/" + date[1] + "/" + date[0];
      this.$store.state.avaliacao.avaliacao_dataMedico = date;
      setTimeout(() => {
        this.reportReady = true;
      }, 2000)
    },
  },
  computed: {
    paciente() {
      return this.$store.state.paciente;
    },
    avaliacao() {
      return this.$store.state.avaliacao;
    },
    versaoMedico() {
      return this.$store.state.versaoMedico;
    },
    versaoPaciente() {
      return this.$store.state.versaoPaciente;
    },
    condicaoClinica() {
      return this.$store.state.condicoes;
    },
    exameFisico() {
      return this.$store.state.exameFisico;
    },
  },
  mounted() {
    this.carregaDados();
    this.convertFerver();
    this.convertFerverDuration();
    this.convertCorticosteroids();
    this.convertAvaliacaoGlobal();
    this.setPercentualPesoSemestre();
    this.convertExameFisico();
    this.formatDate();
  },
};
</script>

<style>
.relatorio {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  min-height: 110vh;
  background-color: #ccc7;
  padding-bottom: 50px;
}

.relatorio-for-print {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

#title-relatorio {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  color: #777;
  width: 80vw;
  top: 50px;
  left: 50px;
}

.two-div {
  height: auto;
}

.two-post-it {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  left: 50px;
  top: 90px;
  width: 60vw;
  height: auto;
}

.post-it-title {
  text-decoration: underline;
  font-size: 30px;
  position: relative;
  color: #777;
  height: 50px;
  top: 10px;
  left: 20px;
}

.two-post-it p {
  font-size: 20px;
  position: relative;
  color: #777;
  width: auto;
  margin-right: 5px;
  margin-left: 10px;
}

.middle-title {
  position: relative;
}

.topics {
  position: relative;
  margin-top: 30px;
  height: auto;
  margin-bottom: 25px;
  align-self: flex-start;
}

.topic-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  position: relative;
  top: 15px;
  margin-left: 15px;
  width: auto;
  height: auto;
}

.topic-group ul {
  display: block;
  width: 100%;
  color: #777;
  margin: 10px 0 0 15px;
}

#evolution-post-it {
  display: block;
  box-shadow: 3px 4px 5px 3px #aaa;
  width: 23vw;
  height: 40vh;
  background-color: #fff;
  overflow-y: auto;
}

.outer-circle {
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-self: flex-start;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.topic-square {
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-self: flex-start;
  height: 10px;
  width: 10px;
}

#actual-circle,
#estad-square {
  background-color: #21a0a0;
}

#month-circle,
#diag-square {
  background-color: #6cd4ff;
}

#smst-circle,
#cond-square {
  background-color: #fabc3c;
}

.inner-circle {
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-self: flex-start;
  margin: 5px 0 0 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.weight-statistics {
  position: relative;
  display: flex;
}

.weight-statistics p,
.disease-statistics p,
.metabolic-statistics p {
  font-size: 15px;
}

.weight-statistics div,
.disease-statistics div,
.metabolic-statistics div {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  width: 120px;
  height: 100px;
}

.weight-statistics h2,
.disease-statistics h2,
.metabolic-statistics h2 {
  text-align: center;
  border-bottom: 1px solid #000;
  width: 90px;
  height: 30px;
}

.nutrition-field p {
  color: #fabc3c;
  font-size: 16px;
  margin-left: 30px;
}

.resultado-positivo {
  margin-left: 5px;
  color: #56e049;
}

.resultado-negativo {
  margin-left: 5px;
  color: #c91a1a;
}

.resultado-neutro {
  margin-left: 5px;
  color: #fabc3c;
}

#disease-post-it {
  box-shadow: 3px 4px 5px 3px #aaa;
  width: 35vw;
  height: 40vh;
  background-color: #fff;
  overflow-y: auto;
}

.disease-statistics {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: auto;
  float: right;
  height: 100px;
  bottom: 10px;
}

.metabolic-statistics {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 10px;
}

#metabolic-post-it {
  position: relative;
  top: 15px;
  box-shadow: 3px 4px 5px 3px #aaa;
  width: 29vw;
  height: 40vh;
  background-color: #fff;
  overflow-y: auto;
}

#asg-post-it {
  position: relative;
  top: 15px;
  box-shadow: 3px 4px 5px 3px #aaa;
  width: 30vw;
  height: 40vh;
  background-color: #fff;
  overflow-y: auto;
}

#asg-organization {
  display: flex;
  position: relative;
  top: 40px;
  margin-left: 20px;
}

#ingest-p,
#exams-p,
#symptoms-p {
  margin-top: 15px;
}

.asg-statistics {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  top: 10vh;
  margin-left: 20px;
  margin-right: 30px;
  width: auto;
  height: auto;
}

.asg-statistics p {
  font-size: 25px;
}

.solo-post-it {
  position: relative;
  top: 90px;
  left: 100px;
  width: 30vw;
  height: auto;
  padding-bottom: 10px;
}

.solo-post-it p {
  font-size: 20px;
  position: relative;
  width: 20vw;
  height: auto;
  left: 35px;
}

.solo-post-it-label {
  color: #777;
}

#exam-post-it {
  box-shadow: 3px 4px 5px 3px #aaa;
  width: 30vw;
  min-height: 80vh;
  background-color: #fff;
  overflow-y: auto;
}

#exam-score {
  display: flex;
  justify-content: space-between;
  color: #777;
  position: relative;
  font-size: 30px;
  top: 80px;
}

.exam-div-class {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 30px;
  width: 25vw;
  height: auto;
}

.exam-div-class p {
  width: auto;
}

#backRelatorio {
  display: flex;
  background-color: #fc7753;
}

.report-btn-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  bottom: 0;
  width: 100vw;
}

.report-btn-container button {
  margin: 0 10px;
}

@media screen and (min-width: 951px) and (max-width: 1200px) {
  .two-post-it {
    width: 50vw;
  }

  .solo-post-it {
    width: 40vw;
  }

  #evolution-post-it {
    margin-top: 10px;
    width: 100%;
    height: 32vh;
  }

  #disease-post-it {
    margin-top: 10px;
    width: 100%;
    height: 32vh;
  }

  #metabolic-post-it {
    margin-top: 10px;
    width: 100%;
    height: 32vh;
  }

  #asg-post-it {
    margin-top: 10px;
    width: 100%;
    height: 32vh;
  }

  #exam-post-it {
    width: 100%;
  }

  .exam-div-class {
    width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .two-div {
    width: 80vw;
  }

  .two-post-it {
    left: 10vw;
    width: 100%;
  }

  .solo-post-it {
    left: 10vw;
    margin-top: 50px;
    width: 80vw;
  }

  #evolution-post-it {
    margin-top: 10px;
    width: 100%;
    height: auto;
  }

  #disease-post-it {
    margin-top: 10px;
    width: 100%;
    height: auto;
  }

  #metabolic-post-it {
    margin-top: 10px;
    width: 100%;
    height: auto;
  }

  #asg-post-it {
    margin-top: 10px;
    width: 100%;
    height: auto;
  }

  #asg-organization {
    flex-wrap: wrap;
    top: 0;
  }

  #asg-organization div {
    margin: 20px 0 10px 0;
  }

  .asg-statistics {
    margin-bottom: 10px;
    top: 0;
  }

  #exam-post-it {
    width: 80vw;
    height: auto;
  }

  .exam-div-class {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .post-it-title {
    font-size: 20px;
  }

  .two-div {
    width: 90vw;
  }

  .two-post-it {
    left: 5vw;
    width: 100%;
  }

  .solo-post-it {
    left: 5vw;
    margin-top: 50px;
    width: 90vw;
  }

  .solo-post-it p {
    width: 80vw;
  }

  #asg-organization {
    flex-wrap: wrap;
    top: 0;
  }

  #asg-organization div {
    margin: 20px 0 10px 0;
  }

  .asg-statistics {
    margin-bottom: 10px;
    top: 0;
  }

  #exam-post-it {
    width: 90vw;
  }

  #exam-score {
    top: 0;
    justify-content: space-around;
    margin-top: 80px;
    padding-bottom: 20px;
  }
}
</style>