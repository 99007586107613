<template>
  <div class="Quest4">
    <div class="quest4-header-container">
      <div id="title4">
        {{ $store.state.paciente.paciente_nome }} - Auto Avaliação
      </div>
      <p id="activity">ATIVADE E FUNÇÃO</p>
    </div>
    <p id="activityMonth">
      No último mês, de um modo geral eu consideraria a minha atividade (função)
      como:
    </p>
    <form action id="formQuest4">
      <div class="activityInputs">
        <div>
          <input
            type="radio"
            value="0"
            v-model="myActivity"
            name="activitys"
            id="activitys1"
          />
          <label for="activitys1">Normal, sem nenhuma limitação</label>
        </div>
        <div>
          <input
            type="radio"
            value="1"
            v-model="myActivity"
            name="activitys"
            id="activitys2"
          />
          <label for="activitys2"
            >Não totalmente normal, mas capaz de manter quase todas as
            atividades normais</label
          >
        </div>
        <div>
          <input
            type="radio"
            value="2"
            v-model="myActivity"
            name="activitys"
            id="activitys3"
          />
          <label for="activitys3"
            >Sem disposição para a maioria das coisas mas ficando na cama ou na
            cadeira menos da metade do dia</label
          >
        </div>
        <div>
          <input
            type="radio"
            value="3"
            v-model="myActivity"
            name="activitys"
            id="activitys4"
          />
          <label for="activitys4"
            >Capaz de fazer pouca atividade e passando maior parte do dia na
            cadeira ou na cama</label
          >
        </div>
        <div>
          <input
            type="radio"
            value="4"
            v-model="myActivity"
            name="activitys"
            id="activitys5"
          />
          <label for="activitys5"
            >Praticamente acamado, raramente fora da cama</label
          >
        </div>
      </div>
    </form>
    <div class="controlQuest4">
      <buttonRectangle
        info="VOLTAR"
        @click.native="quest3()"
        id="back4"
      ></buttonRectangle>
      <buttonRectangle
        info="FINALIZAR"
        @click.native="setActivity()"
        id="next4"
      ></buttonRectangle>
    </div>
    <div v-if="!stepComplete" class="guidance-overlay">
      <guidance-modal :step="step" />
    </div>
    <div class="complete-overlay" v-if="formComplete">
      <guidance-modal :step="complete"/>
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import { baseApiUrl } from "../global";
import GuidanceModal from "../components/GuidanceModal.vue";

export default {
  name: "Quest4",
  components: {
    buttonRectangle,
    GuidanceModal,
  },
  data() {
    return {
      myActivity: this.$store.state.versaoPaciente.myActivity,
      form: {},
      listaSintomas: [],
      vPac: "",
      allowRedirect: false,
      completion: false,
      step: {
        title: "Você está na etapa 4",
        message:
          "Aqui você deve selecionar quais impactos os problemas informados previamente têm no seu dia-a-dia",
        close: () => {
          this.stepComplete = true;
        },
      },
      complete: {
        title: "Muito Bem!",
        message:
          "Você terminou sua auto avaliação e ela foi enviada para a nossa Central. Em breve, nosso nutricionista entrará em contato através dos canais de comunicação disponibilizados. Até mais!",
        close: () => {
          this.$router.push("/");
        },
      },
      stepComplete: false,
      formComplete: false
    };
  },
  methods: {
    quest3() {
      this.$router.push("quest3");
    },
    home() {
      this.formComplete = true
    },
    async setActivity() {
      let height =
        this.$store.state.versaoPaciente.meter +
        this.$store.state.versaoPaciente.cm;

      this.$store.state.versaoPaciente.myActivity = this.myActivity;
      this.$store.state.versaoPaciente.sintomas = this.$store.state.sintomas;
      this.$store.state.versaoPaciente.finalSum =
        Number(this.$store.state.valueQuest1) +
        Number(this.$store.state.valueQuest2) +
        Number(this.$store.state.valueQuest3) +
        Number(this.$store.state.valueQuest4);
      this.$store.state.valueQuest4 = this.myActivity;

      this.form.avaliacao_id = this.$store.state.idAvaliacao;
      this.form.versaoPaciente_q1PesoAtual = this.$store.state.versaoPaciente.actualWeight;
      this.form.versaoPaciente_q1Tamanho = height;
      this.form.versaoPaciente_q1PesoMes = this.$store.state.versaoPaciente.oneMonthWeight;
      this.form.versaoPaciente_q1PesoSemestre = this.$store.state.versaoPaciente.sixMonthWeight;
      this.form.versaoPaciente_q2Quantidade = this.$store.state.versaoPaciente.monthFood;
      this.form.versaoPaciente_q2Tipo = this.$store.state.versaoPaciente.actualFood;
      this.form.versaoPaciente_q2Dor = this.$store.state.versaoPaciente.acheType;
      this.form.versaoPaciente_q2Outro = this.$store.state.versaoPaciente.otherDescription;
      this.form.versaoPaciente_quadro1 = this.$store.state.valueQuest1;
      this.form.versaoPaciente_quadro2 = this.$store.state.valueQuest2;
      this.form.versaoPaciente_quadro3 = this.$store.state.valueQuest3;
      this.form.versaoPaciente_quadro4 = this.$store.state.valueQuest4;

      await axios
        .get(`${baseApiUrl}/sintomas`)
        .then((res) => {
          res.data.forEach((element) => {
            for (let i = 0; i < this.$store.state.sintomas.length; i++) {
              const sintomasSplit = this.$store.state.sintomas[i].split(", ");
              if (element.sintomas_nome == sintomasSplit[0]) {
                this.listaSintomas.push(element.sintomas_id);
              }
            }
          });
        })
        .then(() => {
          axios
            .post(`${baseApiUrl}/versao_paciente`, this.form)
            .then((res) => {
              this.vPac = res.data;
              //console.log(this.vPac);
              for (let i = 0; i < this.listaSintomas.length; i++) {
                const sinVPac = {
                  versaoPaciente_id: this.vPac,
                  sintomas_id: this.listaSintomas[i],
                };
                axios
                  .post(`${baseApiUrl}/sintoma_versao_paciente`, sinVPac)
                  .then(() => {
                    this.$store.state.versaoPaciente = {};
                    this.$store.state.paciente = {};
                    this.home();
                  })
                  .catch((err) => {
                    alert(err);
                  });
              }
            })
            .catch((err) => {
              alert(err);
            });
        })
        .catch((err) => {
          alert(err);
        });
    },
    disableMenu() {
      this.$store.state.isMenuVisible = false;
    },
  },
  computed: {
    versaoPaciente() {
      return this.$store.state.versaoPaciente;
    },
    valueQuest4() {
      return this.$store.state.valueQuest4;
    },
    sintomas() {
      return this.$store.state.sintomas;
    },
    isMenuVisible() {
      return this.$store.state.isMenuVisible;
    },
  },
  mounted() {
    this.disableMenu();
  },
};
</script>

<style>
.controlQuest4 {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.complete-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #7777;
  position: absolute;
  top: 0;
  left: 0;
}

.Quest4 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  padding-top: 30px;
}

.Quest4 p {
  font-size: 35px;
  padding-left: 50px;
}

#formQuest4 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  width: 80vw;
}

.Quest4 form p {
  font-size: 35px;
  position: absolute;
  left: 50px;
}

#activity {
  margin: 20px 0;
}

.activityInputs {
  position: relative;
  left: 60px;
  font-size: 27px;
}

.activityInputs input[type="radio"] {
  display: none;
}

.activityInputs label {
  cursor: pointer;
}

.activityInputs input[type="radio"] + label:before {
  border: 1px solid rgb(238, 207, 80);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
  background-color: rgb(248, 236, 181);
  transform: translateY(8px);
}

.activityInputs input[type="radio"]:checked + label:before {
  color: rgb(238, 207, 80);
  content: "\25CF";
  text-align: center;
}

.activityInputs input[type="radio"]:checked + label:after {
  font-weight: bold;
}

#activityMonth {
  width: 90vw;
}

#next4 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#back4 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#title4 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  height: 50px;
  width: 80vw;
  left: 50px;
}

@media screen and (max-width: 700px) {
  .Quest4 {
    height: auto;
  }

  #title4 {
    left: 20px;
    margin-bottom: 20px;
  }

  .Quest4 p {
    left: 20px;
    font-size: 25px;
  }

  #formQuest4 {
    bottom: 0;
    left: 20px;
    height: auto;
  }

  #activity {
    margin: 20px 0;
  }

  #activityMonth {
    height: auto;
  }

  .activityInputs {
    left: 0;
  }

  .activityInputs label {
    font-size: 25px;
  }

  #next4 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #back4 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  .controlQuest4 {
    margin-bottom: 20px;
  }
}
</style>