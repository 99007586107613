<template>
  <div class="QMedico10">
    <div id="titleMed10">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico10">
      <div class="physical-exam2-div">
        <p id="physical-exam2">EXAME FÍSICO</p>
        <div class="weight-select-div">
          <label for="weight-select">Peso</label>
          <select
            name="weight-select-lis"
            autofocus="autofocus"
            v-model="avaliacaoGlobal.avaliacaoGlobal_peso"
            id="weight-select"
          >
            <option value="1">A - Sem perda de peso OU ganho de peso recente (não hídrico)</option>
            <option
              value="2"
            >B - &le;5% perda de peso em 1 mês(ou &le;10% em 6 meses) OU perda de peso progressiva</option>
            <option
              value="3"
            >C >5% perda de peso em 1 mês (ou 10% em 6 meses) OU perda de peso progressiva</option>
          </select>
        </div>
        <div class="ingestion-select-div">
          <label for="ingestion-select">Ingestão de Nutrientes</label>
          <select
            name="ingestion-select-lis"
            v-model="avaliacaoGlobal.avaliacaoGlobal_nutrientes"
            id="ingestion-select"
          >
            <option value="1">A - Sem déficit OU melhora significativa recente</option>
            <option value="2">B - Diminuição evidente da ingestão</option>
            <option value="3">C - Grave déficit na ingestão</option>
          </select>
        </div>
        <div class="nutritional-div">
          <label for="nutritional-select">Sintomas com Impacto Nutricional</label>
          <select
            name="nutritional-select-lis"
            v-model="avaliacaoGlobal.avaliacaoGlobal_impactoNutricional"
            id="nutritional-select"
          >
            <option
              value="1"
            >A - Nenhum OU melhora significativa recente permitindo ingestão adequada</option>
            <option value="2">B - Presença de sintomas de impacto nutricional (caixa 3)</option>
            <option value="3">C - Presença de sintomas de impacto nutricional (caixa 3)</option>
          </select>
        </div>
        <div class="functionality-select-div">
          <label for="functionality-select">Funcionalidade</label>
          <select
            name="functionality-select-lis"
            v-model="avaliacaoGlobal.avaliacaoGlobal_funcionalidade"
            id="functionality-select"
          >
            <option value="1">A - Sem déficit OU melhora significativa recente</option>
            <option value="2">B - Déficit funcional moderado OU piora recente</option>
            <option value="3">C - Grave déficit funcional OU piora recente</option>
          </select>
        </div>
        <div class="physical-exam-select-div">
          <label for="physical-exam-select">Exame Físico</label>
          <select
            name="physical-exam-select-lis"
            v-model="avaliacaoGlobal.avaliacaoGlobal_exameFisico"
            id="physical-exam-select"
          >
            <option
              value="1"
            >A - Sem déficit OU Deficiência crônica, mas com melhora clínica recente</option>
            <option
              value="2"
            >B - Evidência de perda leve a moderada de massa muscular e/ou tônus muscular à palpação e/ou perda de gordura subcutânea</option>
            <option
              value="3"
            >C - Sinais óbvios de desnutrição (ex. perda intensa de massa muscular, gordura e possível edema</option>
          </select>
        </div>
        <div class="global-assessment-div">
          <label for="global-assessment-select">Avaliação Global</label>
          <select
            name=" global-assessment-select-lis"
            @change="addPontuacaoQMed5()"
            v-model="globalAssessmentSelect"
            id="global-assessment-select"
          >
            <option value="1">A</option>
            <option value="2">B</option>
            <option value="3">C</option>
          </select>
        </div>
      </div>
    </form>
    <div class="pontuacao-container pontuacao5-espaco">
      <p>Pontuação total: {{ pontuacaoLocal + this.$store.state.pontuacaoTotal }}</p>
    </div>
    <div class="controlQMed10">
      <buttonRectangle info="VOLTAR" @click.native="qmedico9()" id="backMed10"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="result()" id="nextMed10"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import { baseApiUrl } from "../global";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";

export default {
  name: "QMedico10",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      avaliacaoGlobal: {},
      globalAssessmentSelect: this.$store.state.versaoMedico
        .versaoMedico_quadro5,
      ready: false,
      flagFinish: false,
      pontuacaoLocal: 0,
      contadorSintomas: {
        grauUm: 0,
        grauElevado: 0
      }
    };
  },
  methods: {
    result() {
      this.gravarAvaliacao();
    },
    qmedico9() {
      this.$router.push("qmedico9");
    },
    addPontuacaoQMed5() {
      this.pontuacaoLocal = Number(this.globalAssessmentSelect);
    },
    setPeso() {
      if (this.$store.state.flagPeso) {
        let pesoAux =
          ((this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes -
            this.$store.state.versaoPaciente.versaoPaciente_q1PesoAtual) /
            this.$store.state.versaoPaciente.versaoPaciente_q1PesoAtual) *
          100;
        if (pesoAux <= 0) {
          this.avaliacaoGlobal.avaliacaoGlobal_peso = "1";
        } else if (pesoAux <= 10) {
          this.avaliacaoGlobal.avaliacaoGlobal_peso = "2";
        } else {
          this.avaliacaoGlobal.avaliacaoGlobal_peso = "3";
        }
      } else {
        let pesoAux =
          ((this.$store.state.versaoPaciente.versaoPaciente_q1PesoSemestre -
            this.$store.state.versaoPaciente.versaoPaciente_q1PesoAtual) /
            this.$store.state.versaoPaciente.versaoPaciente_q1PesoAtual) *
          100;
        if (pesoAux <= 0) {
          this.avaliacaoGlobal.avaliacaoGlobal_peso = "1";
        } else if (pesoAux <= 5) {
          this.avaliacaoGlobal.avaliacaoGlobal_peso = "2";
        } else {
          this.avaliacaoGlobal.avaliacaoGlobal_peso = "3";
        }
      }
      this.$forceUpdate();
    },
    setNutrientes() {
      if (this.$store.state.versaoPaciente.versaoPaciente_q2Quantidade == "0") {
        this.avaliacaoGlobal.avaliacaoGlobal_nutrientes = "1";
      } else {
        if (
          this.$store.state.versaoPaciente.versaoPaciente_q2Tipo == "1" ||
          this.$store.state.versaoPaciente.versaoPaciente_q2Tipo == "2"
        ) {
          this.avaliacaoGlobal.avaliacaoGlobal_nutrientes = "2";
        } else {
          this.avaliacaoGlobal.avaliacaoGlobal_nutrientes = "3";
        }
      }
      this.$forceUpdate();
    },
    setImpactoNutri() {
      axios
        .get(
          `${baseApiUrl}/sintoma_versao_paciente/${this.$store.state.versaoPaciente.versaoPaciente_id}`
        )
        .then(res => {
          //console.log(res.data[0].sintomas_valor);
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].sintomas_valor == "1") {
              this.contadorSintomas.grauUm += 1;
            } else if (
              res.data[i].sintomas_valor == "2" ||
              res.data[i].sintomas_valor == "3"
            ) {
              this.contadorSintomas.grauElevado += 1;
            }
          }

          if (this.contadorSintomas.grauElevado > 0) {
            this.avaliacaoGlobal.avaliacaoGlobal_impactoNutricional = "3";
          } else if (this.contadorSintomas.grauUm > 0) {
            this.avaliacaoGlobal.avaliacaoGlobal_impactoNutricional = "2";
          } else {
            this.avaliacaoGlobal.avaliacaoGlobal_impactoNutricional = "1";
          }

          this.$forceUpdate();
        })
        .catch(err => {
          alert(err);
        });
    },
    setFuncionalidade() {
      if (
        this.$store.state.versaoPaciente.versaoPaciente_quadro4 == "0" ||
        this.$store.state.versaoPaciente.versaoPaciente_quadro4 == "1"
      ) {
        this.avaliacaoGlobal.avaliacaoGlobal_funcionalidade = "1";
      } else if (
        this.$store.state.versaoPaciente.versaoPaciente_quadro4 == "2" ||
        this.$store.state.versaoPaciente.versaoPaciente_quadro4 == "3"
      ) {
        this.avaliacaoGlobal.avaliacaoGlobal_funcionalidade = "2";
      } else {
        this.avaliacaoGlobal.avaliacaoGlobal_funcionalidade = "3";
      }
      this.$forceUpdate();
    },
    setExame() {
      if (
        this.$store.state.exameFisico[7] == "0" &&
        this.$store.state.exameFisico[11] == "0"
      ) {
        this.avaliacaoGlobal.avaliacaoGlobal_exameFisico = "1";
      } else if (
        this.$store.state.exameFisico[7] == "3" ||
        this.$store.state.exameFisico[11] == "3"
      ) {
        this.avaliacaoGlobal.avaliacaoGlobal_exameFisico = "3";
      } else if (
        this.$store.state.exameFisico[7] == "1" ||
        this.$store.state.exameFisico[7] == "2" ||
        this.$store.state.exameFisico[11] == "1" ||
        this.$store.state.exameFisico[11] == "2"
      ) {
        this.avaliacaoGlobal.avaliacaoGlobal_exameFisico = "2";
      }
      this.$forceUpdate();
    },
    openModal() {
      this.$store.state.flagModal = true;
    },
    setDoctorsEvaluationData() {
      this.$store.state.versaoMedico.versaoMedico_quadro5 = this.globalAssessmentSelect;
      this.$store.state.versaoMedico.versaoMedico_pontuacaoTotal =
        Number(this.$store.state.versaoMedico.versaoMedico_quadro1) +
        Number(this.$store.state.versaoMedico.versaoMedico_quadro2) +
        Number(this.$store.state.versaoMedico.versaoMedico_quadro3) +
        Number(this.$store.state.versaoMedico.versaoMedico_quadro4);
      this.$store.state.versaoMedico.versaoMedico_avaliacaoGlobal = this.globalAssessmentSelect;
      this.$store.state.versaoMedico.avaliacao_id = this.$store.state.avaliacao.avaliacao_id;
    },
    async gravarAvaliacao() {
      try {
        await this.setDoctorsEvaluationData();
        const doctorsEval = await this.postDoctorsEvaluation();
        await this.getDoctorsEvaluationId(doctorsEval[0]);
        const clinicalConditions = await this.getClinicalConditions();
        await this.setClinicalConditions(clinicalConditions);
        await this.setPhysicalEvaluation();
        await this.setGlobalEvaluation();
        this.Redirect();

      }
      catch(err) {
        alert(err)
      }
    },
    async postDoctorsEvaluation() {
      return await axios
        .post(
          `${baseApiUrl}/versao_medico`,
          this.$store.state.versaoMedico
        )
        .then(res => res.data)
        .catch(err => {
          alert(err);
        });
    },
    getDoctorsEvaluationId(idDoctorsEvaluation) {
      this.$store.state.versaoMedico.versaoMedico_id = idDoctorsEvaluation;
    },
    async getClinicalConditions() {
      return await axios
        .get(`${baseApiUrl}/condicao_clinica`)
        .then(res => res.data)
        .catch(err => {
          alert(err);
        });
    },
    setClinicalConditions(clinicalConditions) {
      const activeClinicalConditions = clinicalConditions.filter(condition => {
        return this.$store.state.condicoes.includes(
          condition.condicaoClinica_nome
        );
      });

      const registers = activeClinicalConditions.map(condition => {
        const reg = {};
        reg.condicaoClinica_id = condition.condicaoClinica_id;
        reg.versaoMedico_id = this.$store.state.versaoMedico.versaoMedico_id;
        return reg;
      });

      axios
        .post(`${baseApiUrl}/condicao_clinica_versao_medico`, registers)
        .catch(err => {
          alert(err);
        });
    },
    setPhysicalEvaluation() {
      const registros = [];
      for (let i = 0; i < 16; i++) {
        let registro = {};
        registro.versaoMedico_id = this.$store.state.versaoMedico.versaoMedico_id;
        registro.exameFisico_id = i + 1;
        registro.exameFisicoVersaoMedico_valor = this.$store.state.exameFisico[
          i
        ];
        registros.push(registro);
      }
      axios
        .post(`${baseApiUrl}/exame_fisico_versao_medico`, registros)
        .catch(err => {
          alert(err);
        });
    },
    setGlobalEvaluation() {
      this.avaliacaoGlobal.versaoMedico_id = this.$store.state.versaoMedico.versaoMedico_id;
      axios
        .post(`${baseApiUrl}/avaliacao_global`, this.avaliacaoGlobal)
        .catch(err => {
          alert(err);
        });
    },
    Redirect() {
      if (this.$store.state.versaoMedico.versaoMedico_pontuacaoTotal < 2) {
        this.$router.push("/relatorio");
      } else {
        this.$router.push("/qmedico11");
      }
    }
  },
  computed: {
    versaoMedico() {
      return this.$store.state.versaoMedico;
    }
  },
  mounted() {
    this.setPeso();
    this.setNutrientes();
    this.setImpactoNutri();
    this.setFuncionalidade();
    this.setExame();
  }
};
</script>

<style>
.QMedico10 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 30px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed10 {
  position: relative;
  top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.physical-exam2-div {
  width: 50vw;
}

.physical-exam2-div div {
  display: flex;
  justify-content: space-between;
}

.physical-exam2-div div {
  margin: 3em 0 0 0;
}

.form-qmedico10 {
  display: flex;
  position: relative;
  top: 30px;
  height: auto;
  width: 80vw;
  left: 50px;
}

.form-qmedico10 p {
  font-size: 35px;
}

.form-qmedico10 label {
  width: 20vw;
  font-size: 30px;
}

.form-qmedico10 select {
  color: #777;
  width: 30vw;
  border-color: #fff;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
  text-align: center;
}

.pontuacao5-espaco {
  margin-top: 80px;
}

#nextMed10 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed10 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed10 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 90vw;
  left: 50px;
}

@media screen and (max-width: 768px) {
  .form-qmedico10 {
    height: auto;
    width: 80vw;
  }

  .form-qmedico10 label {
    width: 40vw;
  }

  .form-qmedico10 select {
    width: 40vw;
  }

  .physical-exam2-div {
    width: 80vw;
  }
}

@media screen and (max-width: 678px) {
  #titleMed10 {
    font-size: 35px;
    left: 20px;
    width: 80vw;
  }

  .form-qmedico10 {
    margin-top: 20px;
  }

  .form-qmedico10 {
    height: auto;
    width: 80vw;
    left: 20px;
  }

  .form-qmedico10 p {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .form-qmedico10 label {
    font-size: 25px;
  }

  .form-qmedico10 select {
    width: 50vw;
  }

  .physical-exam2-div {
    width: 80vw;
  }

  .controlQMed10 {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  #nextMed10 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed10 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>