import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import telaPaciente from "../views/telaPaciente";
import Quest1 from "../views/Quest1";
import Quest2 from "../views/Quest2";
import Quest3 from "../views/Quest3";
import Quest4 from "../views/Quest4";
import Relatorio from "../views/Relatorio";
import QMedico1 from "../views/QMedico1";
import QMedico2 from "../views/QMedico2";
import QMedico3 from "../views/QMedico3";
import QMedico4 from "../views/QMedico4";
import QMedico5 from "../views/QMedico5";
import QMedico6 from "../views/QMedico6";
import QMedico7 from "../views/QMedico7";
import QMedico8 from "../views/QMedico8";
import QMedico9 from "../views/QMedico9";
import QMedico10 from "../views/QMedico10";
import QMedico11 from "../views/QMedico11";
import PesquisaPaciente from "../views/PesquisaPaciente";
import Historico from "../views/Historico";
import Cadastro from "../views/Cadastro";
import Login from "../views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Home,
  },
  {
    path: "/tela_paciente",
    name: "telaPaciente",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: telaPaciente,
  },
  {
    path: "/Quest1",
    name: "quest1",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Quest1,
  },
  {
    path: "/Quest2",
    name: "quest2",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Quest2,
  },
  {
    path: "/Quest3",
    name: "quest3",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Quest3,
  },
  {
    path: "/Quest4",
    name: "quest4",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Quest4,
  },
  {
    path: "/Relatorio",
    name: "relatorio",
    /*  beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Relatorio,
  },
  {
    path: "/QMedico1",
    name: "qmedico1",
    /*  beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico1,
  },
  {
    path: "/QMedico2",
    name: "qmedico2",
    /*  beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico2,
  },
  {
    path: "/QMedico3",
    name: "qmedico3",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico3,
  },
  {
    path: "/QMedico4",
    name: "qmedico4",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico4,
  },
  {
    path: "/QMedico5",
    name: "qmedico5",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico5,
  },
  {
    path: "/QMedico6",
    name: "qmedico6",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico6,
  },
  {
    path: "/QMedico7",
    name: "qmedico7",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico7,
  },
  {
    path: "/QMedico8",
    name: "qmedico8",
    /*  beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico8,
  },
  {
    path: "/QMedico9",
    name: "qmedico9",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico9,
  },
  {
    path: "/QMedico10",
    name: "qmedico10",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico10,
  },
  {
    path: "/QMedico11",
    name: "qmedico11",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: QMedico11,
  },
  {
    path: "/pesquisa_paciente",
    name: "pesquisa_paciente",
     beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    },
    component: PesquisaPaciente,
  },
  {
    path: "/historico",
    name: "historico",
    /*  beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Historico,
  },
  {
    path: "/cadastroadmin",
    name: "cadastroadmin",
    /* beforeEnter(to, from, next) {
      // check vuex store //
      if (localStorage.getItem('__user')) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }, */
    component: Cadastro,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
