<template>
    <button class="btn-rec" :style="stylBtn">
        <span id="info" :style="styl">{{ info }}</span>
    </button>
</template>

<script>
export default {
    name: 'buttonRectangle',
    props: {
        info: String,
        styl: String,
        stylBtn: String
    }

}
</script>

<style>
#info{
    color: #fff;
    font-size: 40px;
}

.btn-rec{
    justify-content: center;
    align-items: center;
    background-color: #21a0a0;
    width: 300px;
    height: 80px;
    cursor: pointer;
    box-shadow: 3px 4px 5px 3px #ccc;
    border: none;
    margin-bottom: 30px;
}

.btn-rec:active{
    animation-name: clicked;
    animation-duration: .2s;
}

@media screen and (max-width: 700px){
    #info{
        font-size: 25px;
    }
}

@keyframes clicked{
    from{
        box-shadow: 3px 4px 5px 3px #ccc;
    }
    to{
        box-shadow: none;
    }
}

</style>