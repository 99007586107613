<template>
  <div :class="[sidenav, sidenavDynamic]" id="mySidenav" v-if="isMenuVisible">
    <div class="nav-item">
      <router-link to="/">
        <i class="fas fa-door-open"></i>Inicio
      </router-link>
    </div>
    <hr />

    <div class="nav-item">
      <router-link to="/pesquisa_paciente">
        <i id="icons" class="fas fa-search"></i>Pesquisar Paciente
      </router-link>
    </div>
    <hr />

    <div class="nav-item" >
      <router-link to="/cadastroadmin">
        <i class="fas fa-id-badge"></i>Cadastro
      </router-link>
    </div>
    <hr />

    <div class="nav-item" v-if="!this.$store.state.user">
      <router-link to="/login">
        <i class="fas fa-sign-in-alt"></i>Login
      </router-link>
    </div>
    <hr v-if="!this.$store.state.user" />

    <div class="nav-item" @click="logout" v-if="this.$store.state.user">
      <router-link to="/">
        <i class="fas fa-sign-out-alt"></i>Sair
      </router-link>
    </div>
    <hr v-if="this.$store.state.user"/>

    <div class="nav-item">
      <router-link to="/about">
        <i class="fas fa-info-circle"></i>Sobre
      </router-link>
    </div>
    <hr />   

  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      sidenav: "sidenav",
      sidenavDynamic: "sidenav-hidden"
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('__user')
      this.$router.go(this.$router.currentRoute)
    }
  },
  computed: {
    isMenuVisible() {
      return this.$store.state.isMenuVisible;
    },
    menuToggle() {
      return this.$store.state.menuToggle;
    }
  },
  watch: {
    menuToggle: function() {
      if (this.menuToggle) {
        this.sidenavDynamic = "sidenav-visible";
      } else {
        setTimeout(() => {
          this.sidenavDynamic = "sidenav-hidden";
        }, 500);
      }
    }
  }
};
</script>

<style>
.nav-item a i {
  margin-right: 15px;
  padding: 0px 0px;
  color: #000;
  float: left;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.sidenav-visible {
  display: block;
  height: 100vh;
  width: 350px;
  position: absolute;
  top: 80px;
  bottom: 0;
  right: -350px;
  background-color: #ffffff;
  overflow-x: hidden;
  box-shadow: -3px 3px 10px #aaa;
  z-index: 5;
}

.sidenav-hidden {
  display: none;
  height: 100vh;
  width: 350px;
  position: absolute;
  top: 80px;
  bottom: 0;
  right: -350px;
  background-color: #ffffff;
  overflow-x: hidden;
  box-shadow: -3px 3px 10px #aaa;
  z-index: 5;
}

.sidenav a {
  font-size: 25px;
  color: #444;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@keyframes menu-easein {
  from {
    right: -350px;
    display: none;
  }
  to {
    right: 0px;
    display: block;
  }
}

@keyframes menu-easeout {
  from {
    right: 0px;
    display: block;
  }
  to {
    right: -350px;
    display: none;
  }
}
</style>