<template>
  <div class="QMedico2">
    <div id="titleMed2">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico2">
      <div class="evaluation-register-med2-div">
        <div class="exams-div">
          <p id="evaluation-register-med2">CADASTRO DE AVALIAÇÃO</p>
          <p id="exams">Exames Laboratorias:</p>
          <div class="textarea-div">
            <textarea
              name="exams"
              v-model="examsTextarea"
              autofocus="autofocus"
              id="exams-textarea"
              cols="50"
              rows="10"
            ></textarea>
          </div>
        </div>
        <div class="medicines-div">
          <p id="medicines">Medicamentos em uso:</p>
          <div class="textarea-div">
            <textarea
              name="medicines"
              v-model="medicinesTextarea"
              id="medicines-textarea"
              cols="50"
              rows="10"
            ></textarea>
          </div>
        </div>
        <div class="allergy-div">
          <p id="allergy">Alergia ou intolerância alimentar:</p>
          <div class="allergy-inputs">
            <div class="allergy-radios">
              <div class="no-allergy-div">
                <input
                  type="radio"
                  v-model="allergyRadio"
                  value="Sem alergia"
                  name="allergy-radio"
                  id="no-allergy"
                />
                <label for="no-allergy">Não</label>
              </div>
              <div class="yes-allergy-div">
                <input
                  type="radio"
                  v-model="allergyRadio"
                  value="Com alergia"
                  name="allergy-radio"
                  id="yes-allergy"
                />
                <label for="yes-allergy">Sim</label>
              </div>
            </div>
            <div class="allergy-textearea-div">
              <p id="allergy-description">Alimentos relacionados:</p>
              <div class="textarea-div">
                <textarea
                  name="allergy-description"
                  v-model="allergyDescriptionTextarea"
                  id="allergy-description-textarea"
                  cols="50"
                  rows="10"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="physical-activy-div">
          <div class="physical-activy">
            <p id="physical-activy">Atividades Físicas:</p>
            <div class="physical-activy-radios">
              <div class="no-physical-div">
                <input
                  type="radio"
                  v-model="physicalActivyRadio"
                  value="Atividade física não"
                  name="physical-activy-radio"
                  id="no-physical-activy"
                />
                <label for="no-physical-activy">Não</label>
              </div>
              <div class="yes-physical-div">
                <input
                  type="radio"
                  v-model="physicalActivyRadio"
                  value="sim"
                  name="physical-activy-radio"
                  id="yes-physical-activy"
                />
                <label for="yes-physical-activy">Sim</label>
              </div>
            </div>
          </div>
          <div class="frequency-div">
            <p id="frequency">Frequência por semana:</p>
            <input type="number" v-model="frequencyInput" id="frequency-input" />
          </div>
        </div>
      </div>
    </form>
    <div class="controlQMed2">
      <buttonRectangle info="VOLTAR" @click.native="qmedico1()" id="backMed2"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico3()" id="nextMed2"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";

export default {
  name: "QMedico2",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      examsTextarea: this.$store.state.avaliacao.avaliacao_exame,
      medicinesTextarea: this.$store.state.avaliacao.avaliacao_medicamento,
      allergyRadio: this.$store.state.avaliacao.allergyRadio,
      allergyDescriptionTextarea: this.$store.state.avaliacao.avaliacao_alergia,
      physicalActivyRadio: this.$store.state.avaliacao.physicalActivyRadio,
      frequencyInput: this.$store.state.avaliacao.avaliacao_atividadeFisica,
      ready: false
    };
  },
  methods: {
    qmedico3() {
      this.$store.state.avaliacao.avaliacao_exame = this.examsTextarea;
      this.$store.state.avaliacao.avaliacao_medicamento = this.medicinesTextarea;
      if (this.allergyRadio == "Sem alergia") {
        this.$store.state.avaliacao.avaliacao_alergia = "Sem alergia";
      } else {
        this.$store.state.avaliacao.avaliacao_alergia = this.allergyDescriptionTextarea;
      }

      if (this.physicalActivyRadio == "não") {
        this.$store.state.avaliacao.avaliacao_atividadeFisica = 0;
      } else {
        this.$store.state.avaliacao.avaliacao_atividadeFisica = this.frequencyInput;
      }

      this.$router.push("qmedico3");
    },
    qmedico1() {
      this.$router.push("qmedico1");
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    avaliacao() {
      return this.$store.state.avaliacao;
    }
  }
};
</script>

<style>
.QMedico2 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 20px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.physical-activy-titles p {
  display: inline;
}

.textarea-div textarea {
  resize: none;
}

.allergy-textearea-div {
  top: 20px;
  height: 150px;
}

.allergy-textearea-div label {
  bottom: 30px;
  left: 50px;
  font-size: 30px;
}

.exams-div {
  height: 250px;
}

.medicines-div {
  height: 200px;
}

.allergy-div {
  height: 290px;
}

.allergy-inputs {
  height: 240px;
}

.allergy-inputs div,
.physical-activy-inputs div,
.physical-activy-inputs #frequency-input,
.physical-activy-div,
.medicines-div,
.allergy-div,
.allergy-textearea-div label,
.yes-allergy-div,
.form-qmedico2 p,
#allergy-description-textarea,
#exams-textarea,
#medicines-textarea,
.form-qmedico2 {
  position: relative;
}

.allergy-radios div,
.physical-activy-radios div {
  display: inline;
}

.allergy-radios .yes-allergy-div {
  margin-left: 230px;
}

.yes-physical-div {
  margin-left: 60px;
}

.allergy-radios,
.physical-activy-radios {
  left: 50px;
  font-size: 30px;
}

.allergy-radios input[type="radio"],
.physical-activy-radios input[type="radio"] {
  display: none;
}

.allergy-radios label,
.physical-activy-radios label {
  cursor: pointer;
}

.allergy-radios input[type="radio"] + label:before,
.physical-activy-radios input[type="radio"] + label:before {
  border: 1px solid #21a0a0;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
  transform: translateY(8px);
}

.allergy-radios input[type="radio"]:checked + label:before,
.physical-activy-radios input[type="radio"]:checked + label:before {
  color: #21a0a0;
  content: "\25CF";
  text-align: center;
}

.allergy-radios input[type="radio"]:checked + label:after,
.physical-activy-radios input[type="radio"]:checked + label:after {
  font-weight: bold;
}

.form-qmedico2 {
  display: flex;
  top: 30px;
  flex-wrap: wrap;
  width: 100vw;
  height: auto;
}

.form-qmedico2 p {
  width: 80vw;
  height: 50px;
  left: 50px;
}

.physical-activy-div {
  width: 70vw;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.physical-activy {
  width: 30vw;
}

.frequency-div {
  width: 30vw;
}

#evaluation-register-med2 {
  font-size: 35px;
}

#frequency-input {
  color: #777;
  left: 330px;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  width: 100px;
}

#physical-activy {
  width: auto;
  left: 0;
}

#frequency {
  width: auto;
  left: 0;
}

#exams,
#medicines,
#allergy,
#allergy-description,
#physical-activy,
#frequency {
  font-size: 30px;
}

#allergy-description-textarea {
  color: #777;
  left: 50px;
  right: 5px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 80px;
  width: 550px;
}

#exams-textarea {
  color: #777;
  left: 50px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 120px;
  width: 550px;
}

#medicines-textarea {
  color: #777;
  left: 50px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 120px;
  width: 550px;
}

.controlQMed2 {
  position: relative;
  top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

#nextMed2 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed2 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed2 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  height: 50px;
  width: 80vw;
  left: 50px;
}

@media screen and (max-width: 768px) {
  .physical-activy {
    height: 120px;
    width: 40vw;
  }

  #physical-activy {
    height: 60px;
  }

  .yes-physical-div {
    margin-left: 20px;
  }

  .frequency-div {
    width: 40vw;
  }

  #frequency {
    height: 50px;
  }
}

@media screen and (max-width: 678px) {
  #titleMed2 {
    font-size: 35px;
    left: 10vw;
  }

  .form-qmedico2 p {
    left: 10vw;
    font-size: 25px;
  }

  #evaluation-register-med2 {
    font-size: 30px;
  }

  .form-qmedico2 {
    height: auto;
  }

  #exams-textarea {
    width: 80vw;
    left: 10vw;
  }

  #medicines-textarea {
    width: 80vw;
    left: 10vw;
  }

  #allergy {
    height: 80px;
  }

  .allergy-radios,
  .physical-activy-radios {
    left: 10vw;
    font-size: 25px;
  }

  .allergy-radios .yes-allergy-div {
    margin-left: 100px;
  }

  #allergy-description-textarea {
    width: 80vw;
    left: 10vw;
  }

  .physical-activy-div {
    width: 80vw;
    left: 10vw;
  }

  .physical-activy {
    height: 150px;
    width: 40vw;
  }

  #physical-activy {
    height: 80px;
  }

  .yes-physical-div {
    margin-left: 20px;
  }

  .frequency-div {
    width: 40vw;
  }

  #frequency {
    height: 80px;
  }

  .controlQMed2 {
    margin-bottom: 20px;
  }

  #nextMed2 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed2 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>