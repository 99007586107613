<template>
  <div class="QMedico1" v-if="ready">
    <div id="titleMed1">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action id="form-qmedico1">
      <div class="register-div">
        <p id="register-record">CADASTRO DE PRONTUÁRIO</p>
        <div>
          <label for="input-number-record">Nº DO PRONTUÁRIO</label>
          <input v-model="numberRecord" autofocus="autofocus" id="input-number-record" type="text" />
        </div>
      </div>
      <div class="register-div">
        <p id="avaliation-register">CADASTRO DE AVALIÇÃO</p>
        <div>
          <label for="input-companion">Acompanhante:</label>
          <input v-model="companion" id="input-companion" type="text" />
        </div>
        <div>
          <label for="input-diagnosis">Diagnóstico:</label>
          <input v-model="diagnosis" id="input-diagnosis" type="text" />
        </div>
      </div>
      <div class="staging-container">
        <p id="staging">Estadiamento:</p>
        <div class="barLevelMed1">
          <input type="radio" value="1" v-model="numbersRoman" checked="checked" name="bar" id="I" />
          <label for="I">I</label>
          <input type="radio" value="2" v-model="numbersRoman" name="bar" id="II" />
          <label for="II">II</label>
          <input type="radio" value="3" v-model="numbersRoman" name="bar" id="III" />
          <label for="III">III</label>
          <input type="radio" value="4" v-model="numbersRoman" name="bar" id="IV" />
          <label for="IV">IV</label>
        </div>
        <div class="TMNG">
          <label for="T">T</label>
          <input maxlength="1" v-model="t" id="T" type="text" />
          <label for="M">M</label>
          <input maxlength="1" v-model="m" id="M" type="text" />
          <label for="N">N</label>
          <input maxlength="1" v-model="n" id="N" type="text" />
          <label for="G">G</label>
          <input maxlength="1" v-model="g" id="G" type="text" />
        </div>
      </div>
      <div class="class-treatment">
        <p id="treatment">Tratamento:</p>
        <textarea
          name="treatment"
          v-model="treatmentTextarea"
          id="treatment-textarea"
          cols="50"
          rows="10"
        ></textarea>
      </div>
    </form>
    <div class="controlQMed1">
      <buttonRectangle info="VOLTAR" @click.native="telaPaciente()" id="backMed1"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico2()" id="nextMed1"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";
import { baseApiUrl } from "../global";

export default {
  name: "QMedico1",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      numberRecord: this.$store.state.paciente.paciente_prontuario,
      companion: this.$store.state.avaliacao.avaliacao_acompanhante,
      diagnosis: this.$store.state.avaliacao.avaliacao_diagnostico,
      numbersRoman: this.$store.state.avaliacao.avaliacao_estadiamento,
      treatmentTextarea: this.$store.state.avaliacao.avaliacao_tratamento,
      t: this.$store.state.avaliacao.avaliacao_t,
      m: this.$store.state.avaliacao.avaliacao_m,
      n: this.$store.state.avaliacao.avaliacao_n,
      g: this.$store.state.avaliacao.avaliacao_g,
      ready: false
    };
  },
  methods: {
    qmedico2() {
      this.$store.state.paciente.paciente_prontuario = this.numberRecord;
      this.$store.state.avaliacao.avaliacao_acompanhante = this.companion;
      this.$store.state.avaliacao.avaliacao_diagnostico = this.diagnosis;
      this.$store.state.avaliacao.avaliacao_estadiamento = this.numbersRoman;
      this.$store.state.avaliacao.avaliacao_tratamento = this.treatmentTextarea;
      this.$store.state.avaliacao.avaliacao_t = this.t;
      this.$store.state.avaliacao.avaliacao_m = this.m;
      this.$store.state.avaliacao.avaliacao_n = this.n;
      this.$store.state.avaliacao.avaliacao_g = this.g;
      this.$store.state.paciente.paciente_dataNascimento = this.$store.state.paciente.paciente_dataNascimento.split(
        "T"
      )[0];

      delete this.$store.state.paciente.pac_dt_nasc;
      delete this.$store.state.paciente.pac_pro;

      axios
        .put(
          `${baseApiUrl}/paciente/${this.$store.state.paciente.paciente_id}`,
          this.$store.state.paciente
        )
        .catch(err => {
          alert(err);
        });

      this.$router.push("qmedico2");
    },
    telaPaciente() {
      this.$router.push("tela_paciente");
    },
    setReady() {
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
    getPaciente() {
      axios
        .get(`${baseApiUrl}/versao_paciente/${this.$store.state.paciente.paciente_id}`)
        .then(res => {
          this.$store.state.versaoPaciente = res.data
          return res
        })
        .then(res => res.data)
        .catch(err => {
          alert(err);
        });
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    avaliacao() {
      return this.$store.state.avaliacao;
    }
  },
  mounted() {
    this.setReady();
    this.getPaciente();
  }
};
</script>

<style>
.controlQMed1 {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.class-treatment {
  position: relative;
  top: 20px;
}

.staging-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.TMNG {
  width: 50vw;
  left: 220px;
  position: relative;
}

.barLevelMed1 {
  background-color: #21a0a0;
  border-radius: 12px;
  position: relative;
  height: 35px;
  left: 50px;
  width: 600px;
}

.barLevelMed1 input[type="radio"] {
  display: none;
}

.barLevelMed1 input[type="radio"] + label {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  background-color: #21a0a0;
  width: 150px;
  height: 34px;
  line-height: 35px;
  border-radius: 12px;
}

.barLevelMed1 input[type="radio"]:checked + label {
  outline: 0;
  background-image: none;
  background-color: rgb(46, 129, 127);
  border: 1px solid rgb(46, 129, 127);
}

.QMedico1 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 30px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

#form-qmedico1 {
  position: relative;
  top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 80vh;
}

.register-div label {
  font-size: 30px;
}

.register-div input {
  margin-left: 10px;
}

.register-div div {
  position: relative;
  left: 50px;
}

#form-qmedico1 p {
  position: relative;
  width: 80vw;
  height: 50px;
  left: 50px;
}

#treatment-textarea {
  color: #777;
  position: relative;
  left: 50px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 120px;
  width: 550px;
}

#number-record,
#companion,
#diagnosis,
#staging,
#treatment {
  font-size: 30px;
}

#register-record,
#avaliation-register {
  font-size: 35px;
}

input#input-number-record {
  width: 150px;
}

input#input-companion,
input#input-diagnosis {
  width: 300px;
}

.TMNG input {
  width: 40px;
  text-align: center;
}

.TMNG label {
  font-size: 30px;
}

#form-qmedico1 input {
  color: #777;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
}

#nextMed1 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed1 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed1 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  height: 50px;
  width: 80vw;
  left: 50px;
}

#btn-open-modal {
  position: absolute;
  right: 50px;
}

@media screen and (max-width: 678px) {
  #titleMed1 {
    font-size: 35px;
    left: 10vw;
  }

  #form-qmedico1 {
    height: auto;
  }

  #form-qmedico1 p {
    margin-top: 20px;
    font-size: 25px;
    left: 10vw;
  }

  #form-qmedico1 label {
    font-size: 25px;
  }

  #form-qmedico1 input {
    padding-left: 10px;
    font-size: 25px;
  }

  input#input-companion,
  input#input-diagnosis {
    width: 80vw;
  }

  .register-div input {
    margin-left: 0;
  }

  .register-div div {
    position: relative;
    left: 10vw;
    margin-top: 10px;
    width: 80vw;
  }

  .barLevelMed1 {
    margin-bottom: 40px;
    background-color: #21a0a0;
    width: 80vw;
    height: 120px;
    left: 10vw;
  }

  .barLevelMed1 input[type="radio"] + label {
    display: block;
    font-size: 25px;
    width: 100%;
  }

  .TMNG {
    width: 80vw;
    left: 20vw;
  }

  .class-treatment {
    width: 90vw;
  }

  #treatment-textarea {
    width: 80vw;
    left: 10vw;
  }

  .controlQMed1 {
    margin-bottom: 20px;
  }

  #nextMed1 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed1 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #btn-open-modal {
    top: 40px;
    right: 20px;
  }
}
</style>