<template>
  <div class="Quest3">
    <div class="quest3-header-container">
      <div id="title3">
        {{ $store.state.paciente.paciente_nome }} - Auto Avaliação
      </div>
      <p id="symptom">SINTOMAS</p>
    </div>
    <p id="descriSymptom">
      Durante as últimas duas semanas eu tenho tido os seguintes problemas que
      me impedem de comer o suficiente(marque todos os que tiver sentindo):
    </p>
    <form v-if="form3Ready" action="" id="formQuest3">
      <div class="checkSymptons">
        <div>
          <div>
            <input
              type="checkbox"
              value="Sem problemas para me alimentar, 0"
              v-model="symptomsArray"
              name="quest3Op1"
              id="quest3Op1"
            />
            <label for="quest3Op1"> Sem problemas para me alimentar</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Vômitos, 3"
              v-model="symptomsArray"
              name="quest3Op2"
              id="quest3Op2"
            />
            <label for="quest3Op2"> Vômitos</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Sem apetite, apenas sem vontade de comer, 3"
              v-model="symptomsArray"
              name="quest3Op3"
              id="quest3Op3"
            />
            <label for="quest3Op3">
              Sem apetite, apenas sem vontade de comer</label
            >
          </div>
          <div>
            <input
              type="checkbox"
              value="Diarréia, 3"
              v-model="symptomsArray"
              name="quest3Op4"
              id="quest3Op4"
            />
            <label for="quest3Op4"> Diarréia</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Náuseas, 1"
              v-model="symptomsArray"
              name="quest3Op5"
              id="quest3Op5"
            />
            <label for="quest3Op5"> Náuseas</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Boca seca, 1"
              v-model="symptomsArray"
              name="quest3Op6"
              id="quest3Op6"
            />
            <label for="quest3Op6"> Boca seca</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Obstipação, 1"
              v-model="symptomsArray"
              name="quest3Op7"
              id="quest3Op7"
            />
            <label for="quest3Op7"> Obstipação (Intestino preso)</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Outro, 1"
              @change="toggleOther()"
              v-model="symptomsArray"
              name="quest3Op15"
              id="quest3Op15"
            />
            <label for="quest3Op15">
              Outro*<input
                type="text"
                v-model="otherDescription"
                v-if="otherFlag"
                maxlength="50"
            /></label>
            <small>*Ex.: Depressão, problemas financeiros, dentes, etc.</small>
          </div>
        </div>
        <div>
          <div>
            <input
              type="checkbox"
              value="Os cheiros me incomodam, 1"
              v-model="symptomsArray"
              name="quest3Op8"
              id="quest3Op8"
            />
            <label for="quest3Op8"> Os cheiros me incomodam</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Feridas na boca, 2"
              v-model="symptomsArray"
              name="quest3Op9"
              id="quest3Op9"
            />
            <label for="quest3Op9"> Feridas na boca</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Me sinto rapidamente satisfeito, 1"
              v-model="symptomsArray"
              name="quest3Op10"
              id="quest3Op10"
            />
            <label for="quest3Op10"> Me sinto rapidamente satisfeito</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Coisas tem gosto estranho ou não tem gosto, 1"
              v-model="symptomsArray"
              name="quest3Op11"
              id="quest3Op11"
            />
            <label for="quest3Op11">
              Coisas tem gosto estranho ou não tem gosto</label
            >
          </div>
          <div>
            <input
              type="checkbox"
              value="Cansaço fadiga, 1"
              v-model="symptomsArray"
              name="quest3Op12"
              id="quest3Op12"
            />
            <label for="quest3Op12"> Cansaço fadiga</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Problemas para engolir, 1"
              v-model="symptomsArray"
              name="quest3Op13"
              id="quest3Op13"
            />
            <label for="quest3Op13"> Problemas para engolir</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="Dor, 3"
              @change="toggleAche()"
              v-model="symptomsArray"
              name="quest3Op14"
              id="quest3Op14"
            />
            <label for="quest3Op14">
              Dor, onde?<input
                type="text"
                v-model="acheType"
                v-if="acheFlag"
                maxlength="50"
            /></label>
          </div>
        </div>
      </div>
    </form>
    <div class="warning-container">
      <div class="warning" v-if="errorFlagAche || errorFlagOther">
        {{ warningMsg }}
      </div>
    </div>
    <div class="controlQuest3">
      <buttonRectangle
        info="VOLTAR"
        @click.native="quest2()"
        id="back3"
      ></buttonRectangle>
      <buttonRectangle
        info="AVANÇAR"
        @click.native="quest4()"
        id="next3"
      ></buttonRectangle>
    </div>
    <div v-if="!stepComplete" class="guidance-overlay">
      <guidance-modal :step="step" />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import GuidanceModal from "../components/GuidanceModal.vue";

export default {
  name: "Quest3",
  components: {
    buttonRectangle,
    GuidanceModal,
  },
  data() {
    return {
      symptomsArray: this.$store.state.sintomas,
      sum: this.$store.state.versaoPaciente.sum,
      otherFlag: this.$store.state.otherFlag,
      acheFlag: this.$store.state.acheFlag,
      acheType: this.$store.state.versaoPaciente.acheType,
      otherDescription: this.$store.state.versaoPaciente.otherDescription,
      errorFlagAche: false,
      errorFlagOther: false,
      warningMsg: "",
      form3Ready: false,
      step: {
        title: "Você está na etapa 3",
        message:
          "Aqui você deve clicar em todos os sintomas que você tenha apresentado nas últimas duas semanas, em caso de dor especifique o local. Caso alguma situação não esteja na lista clique em 'outro' e especifique",
        close: () => {
          this.stepComplete = true;
        },
      },
      stepComplete: false,
    };
  },
  methods: {
    quest2() {
      this.$router.push("quest2");
    },
    quest4() {
      this.sum = 0;
      this.symptomsArray.forEach((element) => {
        let number;
        if (element == "ache, 3") {
          if (this.acheType == undefined || this.acheType == "") {
            this.warningMsg = "Por favor, informe sua dor!";
            this.errorFlagAche = true;
          } else {
            this.errorFlagAche = false;
          }
        }
        if (element == "other, 1") {
          if (
            this.otherDescription == undefined ||
            this.otherDescription == ""
          ) {
            this.warningMsg = "Por favor, informe o outro sintoma!";
            this.errorFlagOther = true;
          } else {
            this.errorFlagOther = false;
          }
        }
        number = element.charAt(element.length - 1);
        this.sum += Number(number);
      });
      this.sum = Number(this.sum);
      this.$store.state.sintomas = this.symptomsArray;
      this.$store.state.versaoPaciente.sum = this.sum;
      this.$store.state.valueQuest3 = this.sum;
      this.$store.state.acheFlag = this.acheFlag;
      this.$store.state.otherFlag = this.otherFlag;
      this.$store.state.versaoPaciente.acheType = this.acheType;
      this.$store.state.versaoPaciente.otherDescription = this.otherDescription;
      if (!this.errorFlagAche && !this.errorFlagOther) {
        this.$router.push("quest4");
      }
    },
    disableMenu() {
      this.$store.state.isMenuVisible = false;
    },
    toggleAche() {
      this.acheFlag = !this.acheFlag;
    },
    toggleOther() {
      this.otherFlag = !this.otherFlag;
    },
    loadForm() {
      setTimeout(() => {
        this.form3Ready = true;
      }, 100);
    },
  },
  computed: {
    versaoPaciente() {
      return this.$store.state.versaoPaciente;
    },
    valueQuest3() {
      return this.$store.state.valueQuest3;
    },
    sintomas() {
      return this.$store.state.sintomas;
    },
    soma() {
      return this.$store.state.paciente.sum;
    },
    isMenuVisible() {
      return this.$store.state.isMenuVisible;
    },
  },
  mounted() {
    this.disableMenu();
    this.loadForm();
  },
};
</script>

<style>
.warning-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 50px;
}

.quest3-header-container{
  display: flex;
  flex-direction: column;
  padding: 0 50px;
}

.warning {
  text-align: center;
  padding-top: 5px;
  border-radius: 5px;
  box-shadow: 2px 3px 5px #777;
  justify-self: center;
  width: 50vw;
  height: 30px;
  background-color: #fa5f5f;
  font-size: 20px;
  color: #fff;
}

.guidance-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  background-color: #aaa5;
}

.controlQuest3 {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.Quest3 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  min-height: 80vh;
  padding-top: 30px;
}

.Quest3 p {
  font-size: 35px;
}

small {
  color: #aaa;
}

#formQuest3 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  top: 40px;
  width: 100vw;
  height: auto;
}

.checkSymptons {
  justify-content: center;
  display: flex;
  position: relative;
  width: 80vw;
  left: 60px;
  font-size: 27px;
}

.checkSymptons div {
  margin-right: 20px;
}

.checkSymptons label input {
  margin-left: 5px;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 27px;
  width: 200px;
  outline: none;
}

.checkSymptons input[type="checkbox"] {
  display: none;
}

.checkSymptons label {
  cursor: pointer;
}

.checkSymptons input[type="checkbox"] + label:before {
  border: 1px solid rgb(79, 149, 253);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 4px;
  background-color: rgb(123, 181, 254);
  transform: translateY(8px);
}

.checkSymptons input[type="checkbox"]:checked + label:before {
  color: #fff;
  content: "\2713";
  text-align: center;
}

.checkSymptons input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

#descriSymptom {
  width: 90vw;
  margin-left: 50px;
}

#next3 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#back3 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#title3 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 80vw;
  margin-bottom: 20px;
}

@media screen and (min-height: 1200px) {
  .Quest3 {
    height: 80vh;
  }

  .controlQuest3 {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1024px) {
  .checkSymptons {
    width: 90vw;
  }

  #symptom {
    margin: 20px 0;
    width: 80vw;
  }
}

@media screen and (max-width: 700px) {
  #title3 {
    left: 30px;
    margin-bottom: 20px;
  }

  .Quest3 p {
    left: 30px;
    font-size: 25px;
  }

  #symptom {
    margin-top: 20px;
    width: 80vw;
  }

  #descriSymptom {
    height: auto;
    width: 80vw;
  }

  .checkSymptons {
    left: 0;
    width: 80vw;
    flex-wrap: wrap;
  }

  .checkSymptons div {
    width: 100%;
    margin-right: 0;
  }

  .checkSymptons label {
    font-size: 25px;
  }

  #formQuest3 {
    height: auto;
  }

  #next3 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #back3 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  .controlQuest3 {
    margin-bottom: 20px;
  }
}
</style>