<template>
  <div class="Quest1">
    <div id="title1">{{$store.state.paciente.paciente_nome}} - Auto Avaliação</div>
    <form action id="formQuest1">
      <p id="weight">PESO</p>
      <p id="resumeWeight">Resumindo meu peso atual e recente:</p>
      <div class="weigth-class">
        <p id="actualWeight">
          Eu atualmente peso aproximadamente
          <input maxlength="5" type="number" v-model="actualWeight" /> kg
        </p>
        <p id="height">
          Eu tenho aproximadamente
          <input type="number" maxlength="1" v-model="meter" />metro e
          <input type="number" maxlength="2" v-model="cm" /> cm
        </p>
      </div>
      <div class="weigth-class">
        <p id="oneMonthWeight">
          Há um mês eu costumava pesar
          <input type="number" maxlength="5" v-model="oneMonthWeight" /> kg
        </p>
        <p id="sixMonthWeight">
          Há seis meses atrás eu costumava pesar
          <input
            type="number"
            maxlength="5"
            v-model="sixMonthWeight"
          /> kg
        </p>
      </div>
      <p id="lastWeeks">Durante as duas últimas semanas o meu peso:</p>
      <div class="barLevel1">
        <input type="radio" checked="checked" name="bar" id="leftOp" value="1" v-model="weight" />
        <label for="leftOp">Diminuiu</label>
        <input type="radio" name="bar" id="centerOp" value="2" v-model="weight" />
        <label for="centerOp">Ficou Igual</label>
        <input type="radio" name="bar" id="rightOp" value="0" v-model="weight" />
        <label for="rightOp">Aumentou</label>
      </div>
    </form>
    <div class="controlQuest1">
      <buttonRectangle info="AVANÇAR" @click.native="setPeso()" id="next1"></buttonRectangle>
    </div>
    <div v-if="!stepComplete" class="guidance-overlay">
      <guidance-modal :step="step"/>
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import GuidanceModal from '../components/GuidanceModal.vue';

export default {
  name: "Quest1",
  components: {
    buttonRectangle,
    GuidanceModal
  },
  data() {
    return {
      meter: this.$store.state.versaoPaciente.meter,
      weight: this.$store.state.versaoPaciente.weight,
      actualWeight: this.$store.state.versaoPaciente.actualWeight,
      cm: this.$store.state.versaoPaciente.cm,
      oneMonthWeight: this.$store.state.versaoPaciente.oneMonthWeight,
      sixMonthWeight: this.$store.state.versaoPaciente.sixMonthWeight,
      step: {
        title: "Você está na etapa 1",
        message: 'Aqui você deve preencher seus dados de peso e altura, assim como seu peso há 1 mês e 6 meses atrás!',
        close: () => {
          this.stepComplete = true;
        }
      },
      stepComplete: false
    };
  },
  methods: {
    telaPaciente() {
      this.$router.push("telaPaciente");
    },
    quest2() {
      this.$router.push("quest2");
    },
    setPeso() {
      if (this.weight == 2) {
        this.weight = 0;
      }
      this.$store.state.versaoPaciente.meter = this.meter;
      this.$store.state.versaoPaciente.weight = this.weight;
      this.$store.state.valueQuest1 = this.weight;
      this.$store.state.versaoPaciente.actualWeight = this.actualWeight;
      this.$store.state.versaoPaciente.cm = this.cm;
      this.$store.state.versaoPaciente.oneMonthWeight = this.oneMonthWeight;
      this.$store.state.versaoPaciente.sixMonthWeight = this.sixMonthWeight;

      if (!this.oneMonthWeight && !this.sixMonthWeight) {
        alert("Informe um dos pesos anteriores!");
      } else if (
        this.oneMonthWeight.length == 0 &&
        this.sixMonthWeight.length == 0
      ) {
        alert("Informe um dos pesos anteriores!");
      } else {
        this.quest2();
      }
    },
    disableMenu() {
      this.$store.state.isMenuVisible = false;
    }
  },
  computed: {
    versaoPaciente() {
      return this.$store.state.versaoPaciente;
    },
    valueQuest1() {
      return this.$store.state.valueQuest1;
    },
    isMenuVisible() {
      return this.$store.state.isMenuVisible;
    }
  },
  mounted() {
    this.disableMenu();
  }
};
</script>

<style>
.controlQuest1 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.Quest1 {
  color: #777;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  min-height: 100vh;
}

.guidance-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: static;
  top: 0;
  left: 0;

  background-color: #aaa5;
}

#formQuest1 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 80vw;
  min-height: 60vh;
  margin-bottom: 30px;
}

#next1 {
  display: flex;
  background-color: #56e049;
}

#formQuest1 p input {
  color: #777;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 25px;
  width: 50px;
  outline: none;
}

.Quest1 form p,
.Quest1 form div p {
  font-size: 35px;
  position: relative;
  height: 50px;
  width: 80vw;
  left: 50px;
}

.barLevel1 {
  border-radius: 12px;
  position: relative;
  height: 35px;
  left: 50px;
}

.barLevel1 input[type="radio"] {
  display: none;
}

.barLevel1 input[type="radio"] + label {
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  background-color: #fabc3c;
  display: inline-block;
  width: 150px;
  height: 34px;
  line-height: 35px;
  border-radius: 12px;
  margin: 5px 10px;
}

.barLevel1 input[type="radio"]:checked + label {
  outline: 0;
  background-image: none;
  background-color: #e6aa30;
  border: 1px solid #d9a02b;
}

#title1 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  width: 80vw;
  padding-left: 20px;
}

@media screen and (max-width: 678px) {
  #formQuest1 {
    width: 100vw;
    height: 100vh;
    margin: 30px 0 50px 0;
    justify-content: center;
  }

  #formQuest1 p {
    font-size: 30px;
  }

  #lastWeeks {
    margin-top: 20px;
  }

  .barLevel1 {
    margin-top: 20px;
    width: 80vw;
    height: 100px;
  }

  .barLevel1 input[type="radio"] + label {
    display: block;
    font-size: 25px;
    width: 100%;
  }

  .weigth-class p {
    margin-top: 30px;
    height: 100px;
  }

  #next1 {
    width: 250px;
    height: 50px;
    margin-bottom: 30px;
  }

  .Quest1 form p,
  .Quest1 form div p {
    left: 0;
  }

  .barLevel1 {
    left: 0;
  }
}
</style>