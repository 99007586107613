import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    visibleModal: false,
    paciente: {},
    listaPacientes: [],
    versaoPaciente: {},
    exameFisico: [],
    avaliacaoGlobalAtual: [],
    idAvaliacao: "",
    menuToggle: false,
    isMenuVisible: true,
    sintomas: [],
    condicoes: [],
    valueQuest1: "",
    valueQuest2: "",
    valueQuest3: "",
    valueQuest4: "",
    valueQuest5: "",
    otherFlag: false,
    acheFlag: false,
    feedFlag: false,
    avaliacao: {},
    antropometria: {},
    versaoMedico: {},
    pontuacaoTotal: 0,
    flagPeso: false,
    flagModal: false,
    user: localStorage.getItem("__user")
      ? JSON.parse(localStorage.getItem("__user"))
      : null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      if (user) {
        axios.defaults.headers.common["Authorization"] = `bearer ${user.token}`;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    },
  },
  actions: {},
  modules: {},
});
