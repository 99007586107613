<template>
  <div class="QMedico4">
    <div id="titleMed4">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico4">
      <p id="evaluation-register-med4">CADASTRO DE AVALIAÇÃO - Antropometria</p>
      <div class="anthropometry-inputs">
        <div class="first-column">
          <div class="anthropometry-div">
            <label for="anthropometry-height">Altura:</label>
            <input
              type="text"
              autofocus="autofocus"
              v-model="anthropometryHeight"
              id="anthropometry-height"
            />
            <span>m</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-actual-weight">Peso atual:</label>
            <input type="text" v-model="anthropometryActualWeight" id="anthropometry-actual-weight" />
            <span>Kg</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-usual-weight">Peso usual:</label>
            <input type="text" v-model="anthropometryUsualWeight" id="anthropometry-usual-weight" />
            <span>Kg</span>
          </div>
          <div class="anthropometry-div">
            (
            <input
              type="text"
              placeholder="Observação Peso usual"
              v-model="anthropometryParentheses1"
              id="anthropometry-parentheses1"
            />
            <span>)</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-pp">%PP:</label>
            <input type="text" v-model="anthropometryPP" id="anthropometry-pp" />
            <span>%</span>
          </div>
          <div class="anthropometry-div">
            (
            <input
              type="text"
              placeholder="Observação %PP"
              v-model="anthropometryParentheses2"
              id="anthropometry-parentheses2"
            />
            <span>)</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-imc">IMC:</label>
            <input type="text" v-model="anthropometryIMC" id="anthropometry-imc" />
            <span>Kg/m²</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-cabd">Cabd:</label>
            <input type="text" v-model="anthropometryCabd" id="anthropometry-cabd" />
            <span>cm</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-ambc">AMBc:</label>
            <input type="text" v-model="anthropometryAMBC" id="anthropometry-ambc" />
            <span>cm</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-dynamometry">Dinamometria:</label>
            <input type="text" v-model="anthropometryDynamometry" id="anthropometry-dynamometry" />
            <span>cm</span>
          </div>
          <div class="anthropometry-div">
            <label for="anthropometry-calf">Circunferência da panturrilha:</label>
            <input type="text" v-model="anthropometryCalf" id="anthropometry-calf" />
            <span>cm</span>
          </div>
        </div>
      </div>
    </form>
    <div class="controlQMed4">
      <buttonRectangle info="VOLTAR" @click.native="qmedico3()" id="backMed4"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico5()" id="nextMed4"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";
import { baseApiUrl } from "../global";

export default {
  name: "QMedico2",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      anthropometryHeight:
        this.$store.state.versaoPaciente.versaoPaciente_q1Tamanho / 100,
      anthropometryUsualWeight: this.$store.state.antropometria
        .antropometria_pesoUsual,
      anthropometryUsualWeightSmst: this.$store.state.versaoPaciente
        .versaoPaciente_q1PesoSemestre,
      anthropometryPP: this.$store.state.antropometria.antropometria_pp,
      anthropometryIMC: this.$store.state.antropometria.antropometria_imc,
      anthropometryAMBC: this.$store.state.antropometria.antropometria_ambc,
      anthropometryActualWeight: this.$store.state.versaoPaciente
        .versaoPaciente_q1PesoAtual,
      anthropometryParentheses1: this.$store.state.antropometria
        .antropometria_descricaoPesoAtual,
      anthropometryParentheses2: this.$store.state.antropometria
        .antropometria_des_pp,
      anthropometryCabd: this.$store.state.antropometria.antropometria_cabd,
      anthropometryDynamometry: this.$store.state.antropometria
        .antropometria_dinamometria,
      anthropometryCalf: this.$store.state.antropometria
        .antropometria_circunferenciaPanturrilha
    };
  },
  methods: {
    qmedico5() {
      this.$store.state.antropometria.antropometria_altura = this.anthropometryHeight;
      this.$store.state.antropometria.antropometria_pesoUsual = this.anthropometryUsualWeight;
      this.$store.state.antropometria.antropometria_pp = this.anthropometryPP;
      this.$store.state.antropometria.antropometria_imc = this.anthropometryIMC;
      this.$store.state.antropometria.antropometria_ambc = this.anthropometryAMBC;
      this.$store.state.antropometria.antropometria_pesoAtual = this.anthropometryActualWeight;
      this.$store.state.antropometria.antropometria_descricaoPesoAtual = this.anthropometryParentheses1;
      this.$store.state.antropometria.antropometria_descricaoPP = this.anthropometryParentheses2;
      this.$store.state.antropometria.antropometria_cabd = this.anthropometryCabd;
      this.$store.state.antropometria.antropometria_dinamometria = this.anthropometryDynamometry;
      this.$store.state.antropometria.antropometria_circunferenciaPanturrilha = this.anthropometryCalf;
      this.$store.state.antropometria.avaliacao_id = this.$store.state.avaliacao.avaliacao_id;

      axios
        .post(
          `${baseApiUrl}/antropometria`,
          this.$store.state.antropometria
        )
        .then(res => {
          const resposta = res.data;
          const respostaSplit = resposta.split(": ");
          this.$store.state.antropometria.ant_id = respostaSplit[1];
        })
        .catch(err => {
          alert(err);
        });

      this.$router.push("qmedico5");
    },
    qmedico3() {
      this.$router.push("qmedico3");
    },
    carregaImc() {
      this.anthropometryIMC = this.imc;
      //console.log(this.imc);
    },
    carregaPP() {
      this.anthropometryPP = this.pp;
      //console.log(this.pp);
    },
    carregaPesoUsual() {
      this.anthropometryUsualWeight = this.pesoUsual;
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  mounted() {
    this.carregaImc();
    this.carregaPP();
    this.carregaPesoUsual();
  },
  computed: {
    avaliacao() {
      return this.$store.state.avaliacao;
    },
    imc() {
      return (
        this.$store.state.versaoPaciente.versaoPaciente_q1PesoAtual /
        Math.pow(
          this.$store.state.versaoPaciente.versaoPaciente_q1Tamanho / 100,
          2
        )
      ).toFixed(2);
    },
    pp() {
      if (!this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes) {
        return (
          ((this.$store.state.versaoPaciente.versaoPaciente_q1PesoSemestre -
            this.$store.state.versaoPaciente.versaoPaciente_q1PesoAtual) /
            this.$store.state.versaoPaciente.versaoPaciente_q1PesoSemestre) *
          100
        ).toFixed(2);
      }
      return (
        ((this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes -
          this.$store.state.versaoPaciente.versaoPaciente_q1PesoAtual) /
          this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes) *
        100
      ).toFixed(2);
    },
    pesoUsual() {
      if (!this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes) {
        return this.$store.state.versaoPaciente.versaoPaciente_q1PesoSemestre;
      }
      return this.$store.state.versaoPaciente.versaoPaciente_q1PesoMes;
    }
  }
};
</script>

<style>
.QMedico4 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 30px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

#overlay-q4 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  z-index: 3;
}

#overlay4-gif {
  width: 50px;
  height: 50px;
}

.controlQMed4 {
  position: relative;
  top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.anthropometry-div {
  width: 40vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.anthropometry-div span {
  margin-left: 15px;
}

.form-qmedico4 p,
.form-qmedico4,
.first-column,
.second-column {
  position: relative;
}

.form-qmedico4 p {
  width: 80vw;
  height: 50px;
  left: 50px;
}

.anthropometry-inputs {
  display: flex;
  position: relative;
  width: 80vw;
  height: 520px;
  left: 60px;
  font-size: 30px;
}

.QMedico4 input[type="text"] {
  color: #777;
  width: 100px;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
}

#anthropometry-parentheses1,
#anthropometry-parentheses2 {
  width: 260px;
}

.first-column {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

#evaluation-register-med4 {
  font-size: 35px;
}

#nextMed4 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed4 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed4 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  height: 50px;
  width: 80vw;
  left: 50px;
}

@media screen and (max-width: 678px) {
  #titleMed4 {
    font-size: 35px;
    left: 20px;
  }

  .anthropometry-div {
    width: 90vw;
    flex-wrap: wrap;
  }

  #evaluation-register-med4 {
    font-size: 30px;
    width: 95vw;
    margin-bottom: 50px;
  }

  .form-qmedico4 {
    margin-top: 20px;
    align-self: flex-start;
  }

  .form-qmedico4 p {
    left: 20px;
  }

  .anthropometry-inputs {
    left: 20px;
  }

  .controlQMed4 {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  #nextMed4 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed4 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}

@media screen and (max-height: 800px) {
  .QMedico4 {
    height: 120vh;
  }

  .form-qmedico4 {
    height: 600px;
    margin-bottom: 30px;
  }

  .form-qmedico4 label {
    font-size: 25px;
  }

  #anthropometry-parentheses1,
  #anthropometry-parentheses2 {
    width: 80vw;
  }

  .QMedico4 input[type="text"] {
    width: 70px;
    font-size: 25px;
  }
}
</style>