<template>
  <div class="historico">
    <div id="titleHist">
      <p>{{ paciente.paciente_nome }}</p>
    </div>
    <div class="table-div">
      <div class="pacientes-div">
        <table>
          <thead>
            <td width="10%">Código</td>
            <td width="15%">DATA</td>
            <td width="15%">ASG-PPP</td>
            <td width="45%">AVALIAÇÃO GLOBAL</td>
            <td>RELATÓRIO</td>
          </thead>
          <tbody v-if="dadosHistoricoReady">
            <tr
              class="paciente-list"
              v-for="relatorio in relatorios"
              :key="relatorio.avaliacao_id"
            >
              <td>#{{ relatorio.avaliacao_id }}</td>
              <td>{{ relatorio.avaliacao_dataMedico }}</td>
              <td>{{ relatorio.versaomedico_pontuacaoTotal }}</td>
              <td>{{ relatorio.versaoMedico_avaliacaoGlobal }}</td>
              <td class="history">
                <i @click="getInfoFrom(relatorio)" class="fas fa-file-alt"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <buttonRectangle
      info="VOLTAR"
      @click.native="telaPaciente()"
      id="back-historico"
    ></buttonRectangle>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import { baseApiUrl } from "../global";

export default {
  name: "Historico",
  components: {
    buttonRectangle,
  },
  data() {
    return {
      dadosHistoricoReady: false,
      relatorios: [],
    };
  },
  methods: {
    async carregaAvaliacoes() {
      this.relatorios = await axios.get(
        `${baseApiUrl}/dados_avaliacao/${this.$store.state.paciente.paciente_id}`
      );

      this.relatorios = this.relatorios.data;
      this.organizaLista();
      this.dadosHistoricoReady = true;
    },
    organizaLista() {
      for (let i = 0; i < this.relatorios.length; i++) {
        this.relatorios[i].avaliacao_dataMedico = this.formatData(
          this.relatorios[i].avaliacao_dataMedico
        );
        this.relatorios[i].versaoMedico_avaliacaoGlobal = this.formatCategoria(
          this.relatorios[i].versaoMedico_avaliacaoGlobal
        );
      }
    },
    formatData(data) {
      data = data.split("T");
      data = data[0].split("-");
      data = data[2] + "/" + data[1] + "/" + data[0];

      return data;
    },
    formatCategoria(categoria) {
      switch (categoria) {
        case "0":
          categoria = "CATEGORIA A";
          break;
        case "1":
          categoria = "CATEGORIA A";
          break;
        case "2":
          categoria = "CATEGORIA B";
          break;
        case "3":
          categoria = "CATEGORIA C";
          break;
        default:
          categoria = "";
      }
      return categoria;
    },
    telaPaciente() {
      this.$router.push("tela_paciente");
    },
    async getInfoFrom(relatorio) {
      try {
        const versaoMedico = await axios.get(
          `${baseApiUrl}/versao_medico/${relatorio.versaoMedico_id}`
        );

        const versaoPacientePorAvaliacao = await axios.get(
          `${baseApiUrl}/versao_paciente_por_avaliacao/${relatorio.avaliacao_id}`
        );

        const avaliacao = await axios.get(
          `${baseApiUrl}/avaliacao/${relatorio.avaliacao_id}`
        );

        const exameFisico = await axios.get(
          `${baseApiUrl}/exame_fisico_versao_medico/${relatorio.versaoMedico_id}`
        )

        const condicoesClinicas = await axios.get(
          `${baseApiUrl}/condicao_clinica_versao_medico/${relatorio.versaoMedico_id}`
        );

        this.$store.state.condicoes = condicoesClinicas.data;
        this.$store.state.avaliacao = avaliacao.data;
        this.$store.state.exameFisico = exameFisico.data;
        this.$store.state.versaoMedico = versaoMedico.data;
        this.$store.state.versaoPaciente = versaoPacientePorAvaliacao.data;

        if(exameFisico != undefined) {
          this.$router.push("relatorio");
        }
        
      }
      catch(err) {
        alert(err)
      }
    },
  },
  computed: {
    paciente() {
      return this.$store.state.paciente;
    },
  },
  mounted() {
    this.carregaAvaliacoes();
  },
};
</script>

<style>
.historico {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.historico i {
  cursor: pointer;
}

#titleHist {
  width: 100vw;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

#back-historico {
  margin-top: 50px;
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

@media screen and (max-width: 600px) {
  .pacientes-div {
    width: 100vw;
  }
}
</style>