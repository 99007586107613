<template>
  <div class="modal-paciente">
    <h1>Formulário Paciente</h1>
    <h2>Quadro 1:</h2>
    <p>Peso Atual: {{ vpac.versaoPaciente_q1PesoAtual }}</p>
    <p>Altura: {{ vpac.versaoPaciente_q1Tamanho }}</p>
    <p>Há um mês costumava pesar: {{ vpac.versaoPaciente_q1PesoMes }}</p>
    <p>Há seis meses costumava pesar: {{ vpac.versaoPaciente_q1PesoSemestre }}</p>
    <p>Durante as duas últimas semanas o meu peso: {{ boardResponses.versaoPaciente_quadro1 }}</p>
    <h2>Quadro 2:</h2>
    <p>Comparada com a minha alimentação habitual no último mês, eu tenho comido: 
      <span v-if="vpac.versaoPaciente_q2Quantidade == '1'">Menos que o Habitual</span>
      <span  v-if="vpac.versaoPaciente_q2Quantidade == '0'">Igual/Mais que o habitual</span>
    </p>
    <p
      v-if="vpac.versaoPaciente_q2Quantidade == '1'"
    >Atualmente, eu estou comendo: {{ boardResponses.foodType }}</p>
    <h2>Quadro 3:</h2>
    <h3>Sintomas:</h3>
    <p>
      <span v-for="(sintoma, index) in sintomas" :key="index" class="sintomas-modal">
        {{ sintoma.sintomas_nome }}
        <span
          v-if="sintoma.sintomas_nome == 'Dor'"
        > de {{ vpac.versaoPaciente_q2Dor }}</span>
        <span v-if="sintoma.sintoma_nome == 'Outro'">: {{ vpac.versaoPaciente_q2Outro }}</span>
        <span v-if="index < sintomas.length - 1">,</span>
      </span>
    </p>
    <h2>Quadro 4:</h2>
    <p>No último mês, de um modo geral eu consideraria a minha atividade (função) como: {{ boardResponses.versaoPaciente_quadro4 }}</p>
    <Button
      @click.native="closeModal()"
      id="btn-modal-paciente"
      icon="fas fa-times"
      styl="color: white; font-size: 40px;"
    />
  </div>
</template>

<script>
import Button from "./Button";
import axios from "axios";
import { baseApiUrl } from "../global";

export default {
  name: "ModalPaciente",
  props: {
    msg: String
  },
  components: {
    Button
  },
  data() {
    return {
      sintomas: [],
      boardResponses: {}
    };
  },
  methods: {
    closeModal() {
      let modal = document.getElementsByClassName("modal-paciente")[0];
      modal.style.animationName = "modal-out";

      setTimeout(() => {
        this.$store.state.flagModal = false;
      }, 500);
    },
    async getSintomas() {
      this.sintomas = await axios.get(
        `${baseApiUrl}/sintoma_versao_paciente/${this.$store.state.versaoPaciente.versaoPaciente_id}`
      )
      .then(res => res.data)
      

      if (this.vpac.versaoPaciente_quadro1 == "0") {
        this.boardResponses.versaoPaciente_quadro1 = "Ficou igual/aumentou";
      } else {
        this.boardResponses.versaoPaciente_quadro1 = "Diminuiu";
      }

      if (this.vpac.versaoPaciente_q2Tipo == "1") {
        this.boardResponses.foodType =
          "A mesma comida(sólida) em menor quantidade que o habitual";
      } else if (this.vpac.versaoPaciente_q2Tipo == "2") {
        this.boardResponses.foodType =
          "A mesma comida(sólida) em pouca quantidade";
      } else if (this.vpac.versaoPaciente_q2Tipo == "3") {
        this.boardResponses.foodType = "Apenas alimentos líquidos";
      } else if (this.vpac.versaoPaciente_q2Tipo == "4") {
        this.boardResponses.foodType = "Apenas suplementos nutricionais";
      } else if (this.vpac.versaoPaciente_q2Tipo == "5") {
        this.boardResponses.foodType =
          "Muito pouca quantidade de qualquer alimento";
      } else {
        this.boardResponses.foodType =
          "Apenas alimentos por sonda ou pela veia";
      }
      console.log(this.vpac.versaoPaciente_q2Tipo)
      if (this.vpac.versaoPaciente_quadro4 == "0") {
        this.boardResponses.versaoPaciente_quadro4 = "Normal, sem nenhuma limitação";
      } else if (this.vpac.versaoPaciente_quadro4 == "1") {
        this.boardResponses.versaoPaciente_quadro4 =
          "Não totalmente normal, mas capaz de manter quase todas as atividades normais";
      } else if (this.vpac.versaoPaciente_quadro4 == "2") {
        this.boardResponses.versaoPaciente_quadro4 =
          "Sem disposição para a maioria das coisas mas ficando na cama ou na cadeira menos da metade do dia";
      } else if (this.vpac.versaoPaciente_quadro4 == "3") {
        this.boardResponses.versaoPaciente_quadro4 =
          "Capaz de fazer pouca atividade e passando maior parte do dia na cadeira ou na cama";
      } else {
        this.boardResponses.versaoPaciente_quadro4 =
          "Praticamente acamado, raramente fora da cama";
      }
    }
  },
  computed: {
    visibleModal() {
      return this.$store.state.visibleModal;
    },
    paciente() {
      return this.$store.state.paciente;
    },
    vpac() {
      return this.$store.state.versaoPaciente;
    }
  },
  mounted() {
    this.getSintomas();
  }
};
</script>

<style>
.btn-container {
  height: 100px;
  align-items: flex-end;
  justify-content: space-around;
  display: flex;
}

#dataNascimento {
  margin-top: 0;
  color: #777;
}

.dataNasc-div {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
}

.dataNasc-div label {
  margin-top: 10px;
  color: #777;
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.modal-paciente {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 20px;
  background-color: #fefefe;
  box-shadow: 3px 3px 5px 6px #ccc;
  animation-name: modal-in;
  animation-duration: 0.5s;
  width: 40vw;
  height: auto;
  left: 30vw;
  bottom: 35vh;
  padding: 20px;
  padding-bottom: 30px;
  z-index: 4;
}

/*.modal-fechar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 20px;
  background-color: #fefefe;
  box-shadow: 3px 3px 5px 6px #ccc;
  animation-name: modal-out;
  animation-duration: .5s;
  width: 40vw;
  height: auto;
  position: absolute;
  left: 30vw;
  bottom: 35vh;
  padding: 20px;
  padding-bottom: 30px;
  z-index: 4;
}*/

.modal-paciente h1 {
  text-align: center;
  width: 100%;
}

.modal-paciente h2 {
  margin-top: 10px;
  width: 100%;
}

.modal-paciente h3 {
  margin-top: 10px;
  width: 100%;
}

.modal-paciente p {
  width: 100%;
  margin-top: 5px;
  font-size: 20px;
}

#btn-modal-paciente {
  margin-top: 20px;
  width: 60px;
  height: 60px;
  background-color: #fc7753;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .modal-paciente {
    left: 20vw;
    width: 60vw;
  }

  .modal-paciente input {
    background-color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .modal-paciente {
    left: 10vw;
    bottom: 0;
    width: 80vw;
  }

  .modal-paciente input {
    width: 100%;
    background-color: #fff;
  }
}

@keyframes modal-in {
  from {
    bottom: 30vh;
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    bottom: 35vh;
    opacity: 1;
  }
}

@keyframes modal-out {
  from {
    visibility: visible;
    bottom: 35vh;
    opacity: 1;
  }
  to {
    bottom: 30vh;
    visibility: hidden;
    opacity: 0;
  }
}
</style>