<template>
  <div class="guidanceModal">
    <div class="guidance-message-container">
      <h1 class="guidance-title">{{ step.title }}</h1>
      <p id="guidance-body">{{ step.message }}</p>
      <button-rectangle
        class="guidance-btn"
        info="Ok"
        @click.native="closeModal"
      />
    </div>
  </div>
</template>

<script>
import ButtonRectangle from "./buttonRectangle.vue";
export default {
  props: ["step"],
  components: {
    ButtonRectangle,
  },
  methods: {
    closeModal() {
      this.step.close();
    },
  },
};
</script>

<style>
.guidance-message-container {
  width: 500px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 10px #777;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.guidance-title {
  margin-bottom: 20px;
  text-align: center;
}

#guidance-body {
  font-size: 20px;
  padding: 10px 20px 30px 20px;
}

.guidance-btn {
  width: 100px;
  height: 50px;
}

@media (max-width: 700px) {
  .guidance-message-container {
    width: 300px;
  }
}
</style>