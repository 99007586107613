<template>
  <div class="QMedico3">
    <div id="titleMed3">{{$store.state.paciente.paciente_nome}} - Avaliação Médica</div>
    <form action class="form-qmedico3">
      <p id="evaluation-register-med3">CADASTRO DE AVALIAÇÃO</p>
      <div class="intestinal-rhythm-div">
        <p id="intestinal-rhythm">Ritmo intestinal:</p>
        <div class="intestinal-rhythm-inputs">
          <div class="intestinal-rhythm-radios">
            <div class="slow-intestinal-rhythm-div">
              <input
                type="radio"
                v-model="intestinalRhythmRadio"
                value="Ritmo lento"
                name="intestinal-rhythm-radio"
                id="slow-intestinal-rhythm"
              />
              <label for="slow-intestinal-rhythm">Lento</label>
            </div>
            <div class="normal-intestinal-rhythm-div">
              <input
                type="radio"
                v-model="intestinalRhythmRadio"
                value="Ritmo normal"
                name="intestinal-rhythm-radio"
                id="normal-intestinal-rhythm"
              />
              <label for="normal-intestinal-rhythm">Normal</label>
            </div>
            <div class="fast-intestinal-rhythm-div">
              <input
                type="radio"
                v-model="intestinalRhythmRadio"
                value="Ritmo rápido"
                name="intestinal-rhythm-radio"
                id="fast-intestinal-rhythm"
              />
              <label for="fast-intestinal-rhythm">Acelerado</label>
            </div>
          </div>
          <div class="consistency-div">
            <label for="consistency">Consistência:</label>
            <select name v-model="consistency" id="consistency">
              <option selected disabled value="undefined">Escolha uma consistência</option>
              <option value="Sólido">Sólido</option>
              <option value="Pastoso">Pastoso</option>
              <option value="Semi-líquido">Semi-líquido</option>
              <option value="Líquido">Líquido</option>
            </select>
          </div>
        </div>
      </div>
      <div class="urinary-rhythm-div">
        <p id="urinary-rhythm">Ritmo urinário:</p>
        <div class="urinary-rhythm-inputs">
          <div class="urinary-rhythm-radios">
            <div class="slow-urinary-rhythm-div">
              <input
                type="radio"
                v-model="urinaryRhythmRadio"
                value="Ritmo lento"
                name="urinary-rhythm-radio"
                id="slow-urinary-rhythm"
              />
              <label for="slow-urinary-rhythm">Lento</label>
            </div>
            <div class="normal-urinary-rhythm-div">
              <input
                type="radio"
                v-model="urinaryRhythmRadio"
                value="Ritmo normal"
                name="urinary-rhythm-radio"
                id="normal-urinary-rhythm"
              />
              <label for="normal-urinary-rhythm">Normal</label>
            </div>
            <div class="fast-urinary-rhythm-div">
              <input
                type="radio"
                v-model="urinaryRhythmRadio"
                value="Ritmo rápido"
                name="urinary-rhythm-radio"
                id="fast-urinary-rhythm"
              />
              <label for="fast-urinary-rhythm">Acelerado</label>
            </div>
          </div>
        </div>
      </div>
      <div class="clinical-evolution-div">
        <p id="clinical-evolution">Evolução Clínica:</p>
        <textarea
          name="clinical-evolution"
          v-model="clinicalEvolutionTextarea"
          id="clinical-evolution-textarea"
          cols="50"
          rows="10"
        ></textarea>
      </div>
    </form>
    <div class="controlQMed3">
      <buttonRectangle info="VOLTAR" @click.native="qmedico2()" id="backMed3"></buttonRectangle>
      <buttonRectangle info="AVANÇAR" @click.native="qmedico4()" id="nextMed3"></buttonRectangle>
    </div>
    <Button
      @click.native="openModal()"
      icon="fas fa-paste"
      id="btn-open-modal"
      styl="color: white; font-size: 40px;"
    />
    <div v-if="this.$store.state.flagModal" class="qmedico-overlay">
      <ModalPaciente />
    </div>
  </div>
</template>

<script>
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import ModalPaciente from "../components/ModalPaciente";
import Button from "../components/Button";
import { baseApiUrl } from "../global";

export default {
  name: "QMedico2",
  components: {
    buttonRectangle,
    ModalPaciente,
    Button
  },
  data() {
    return {
      intestinalRhythmRadio: this.$store.state.avaliacao
        .avaliacao_ritmoIntestino,
      consistency: this.$store.state.avaliacao.avaliacao_consistencia,
      urinaryRhythmRadio: this.$store.state.avaliacao.avaliacao_ritmoUrinario,
      clinicalEvolutionTextarea: this.$store.state.avaliacao.avaliacao_evolucao,
      ready: false
    };
  },
  methods: {
    qmedico4() {
      this.$store.state.avaliacao.avaliacao_ritmoIntestino = this.intestinalRhythmRadio;
      this.$store.state.avaliacao.avaliacao_consistencia = this.consistency;
      this.$store.state.avaliacao.avaliacao_ritmoUrinario = this.urinaryRhythmRadio;
      this.$store.state.avaliacao.avaliacao_evolucao = this.clinicalEvolutionTextarea;

      axios
        .get(`${baseApiUrl}/versao_paciente/`)
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            if (
              this.$store.state.avaliacao.avaliacao_id ==
              res.data[i].avaliacao_id
            ) {
              this.$store.state.versaoPaciente = res.data[i];
              this.$router.push("qmedico4");
            }
          }
        })
        .catch(err => {
          alert(err);
        });
    },
    qmedico2() {
      this.$router.push("qmedico2");
    },
    openModal() {
      this.$store.state.flagModal = true;
    }
  },
  computed: {
    avaliacao() {
      return this.$store.state.avaliacao;
    }
  }
};
</script>

<style>
.QMedico3 {
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 20px;
  width: 100vw;
  height: 100vh;
}

.qmedico-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #5555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.controlQMed3 {
  position: relative;
  top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.form-qmedico3,
.form-qmedico3 p,
.intestinal-rhythm-div,
.normal-intestinal-rhythm-div,
.fast-intestinal-rhythm-div,
.intestinal-rhythm-inputs div,
.urinary-rhythm-inputs div,
.urinary-rhythm-div,
.clinical-evolution-div,
#clinical-evolution-textarea {
  position: relative;
}

#clinical-evolution-textarea {
  color: #777;
  left: 50px;
  outline: none;
  border: 2px solid #21a0a0;
  background-color: #e9f1ed;
  font-size: 25px;
  height: 80px;
  width: 550px;
}

.form-qmedico3 {
  display: flex;
  flex-wrap: wrap;
  top: 30px;
  bottom: 40px;
  width: 100vw;
  height: 70vh;
}

.form-qmedico3 p {
  width: 80vw;
  height: 50px;
  left: 50px;
}

.intestinal-rhythm-radios,
.urinary-rhythm-radios {
  left: 50px;
  font-size: 30px;
}

.intestinal-rhythm-radios div,
.urinary-rhythm-radios div {
  display: inline;
}

.intestinal-rhythm-div {
  height: 150px;
}

.urinary-rhythm-div {
  height: 100px;
}

.intestinal-rhythm-inputs {
  height: 100px;
}

.urinary-rhythm-inputs {
  height: 50px;
}

.intestinal-rhythm-inputs .normal-intestinal-rhythm-div,
.urinary-rhythm-inputs .normal-urinary-rhythm-div {
  left: 15px;
}

.intestinal-rhythm-inputs .fast-intestinal-rhythm-div,
.urinary-rhythm-inputs .fast-urinary-rhythm-div {
  left: 30px;
}

.consistency-div label,
#urinary-rhythm,
#intestinal-rhythm,
#clinical-evolution {
  font-size: 30px;
}

.intestinal-rhythm-inputs input[type="radio"],
.urinary-rhythm-inputs input[type="radio"] {
  display: none;
}

.intestinal-rhythm-radios label,
.urinary-rhythm-radios label {
  cursor: pointer;
}

.QMedico3 input[type="text"] {
  color: #777;
  border: none;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
}

.intestinal-rhythm-inputs input[type="radio"] + label:before,
.urinary-rhythm-inputs input[type="radio"] + label:before {
  border: 1px solid #21a0a0;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(8px);
}

.intestinal-rhythm-inputs input[type="radio"]:checked + label:before,
.urinary-rhythm-inputs input[type="radio"]:checked + label:before {
  color: #21a0a0;
  content: "\25CF";
  text-align: center;
}

.intestinal-rhythm-inputs input[type="radio"]:checked + label:after,
.urinary-rhythm-inputs input[type="radio"]:checked + label:after {
  font-weight: bold;
}

.consistency-div {
  left: 50px;
  top: 25px;
}

.form-qmedico3 select {
  color: #777;
  width: 20vw;
  border-color: #fff;
  border-bottom: 1px solid #292b2e;
  font-size: 30px;
  outline: none;
  position: relative;
  left: 10px;
  text-align: center;
}

#evaluation-register-med3 {
  font-size: 35px;
}

#nextMed3 {
  margin-left: 10px;
  display: flex;
  background-color: #56e049;
}

#backMed3 {
  margin-right: 10px;
  display: flex;
  background-color: #fc7753;
}

#titleMed3 {
  text-decoration: underline;
  font-size: 40px;
  position: relative;
  height: 50px;
  width: 80vw;
  left: 50px;
}

@media screen and (max-width: 678px) {
  #titleMed3 {
    font-size: 35px;
    left: 10vw;
    width: 80vw;
  }

  .form-qmedico3 p {
    width: 80vw;
    left: 10vw;
  }

  .form-qmedico3 {
    height: auto;
  }

  #evaluation-register-med3 {
    font-size: 30px;
  }

  .form-qmedico3 label,
  .consistency-div label,
  #urinary-rhythm,
  #intestinal-rhythm,
  #clinical-evolution {
    font-size: 25px;
  }

  .intestinal-rhythm-radios,
  .urinary-rhythm-radios {
    left: 10vw;
  }

  .QMedico3 input[type="text"] {
    font-size: 25px;
  }

  .consistency-div {
    left: 10vw;
  }

  .urinary-rhythm-div {
    margin-top: 20px;
  }

  .form-qmedico3 select {
    width: 40vw;
    font-size: 25px;
  }

  #clinical-evolution-textarea {
    width: 80vw;
    left: 10vw;
  }

  .controlQMed3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  #nextMed3 {
    margin-left: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }

  #backMed3 {
    margin-right: 0;
    margin-top: 20px;
    width: 250px;
    height: 50px;
  }
}
</style>