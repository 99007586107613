<template>
  <div class="telaPaciente">
    <div id="nome">{{paciente.paciente_nome}}</div>
    <div class="aval-div" v-if="!this.$store.state.user">
      <span id="avalAuto">Auto Avaliação</span>
      <Button
        id="btn-aval-auto"
        @click.native="relatorioPaciente()"
        icon="fas fa-plus"
        styl="color: white; font-size: 40px;"
      ></Button>
    </div>
    <div class="aval-div" v-if="this.$store.state.user">
      <span id="avalMed">Avaliação Médica</span>
      <Button
        id="btn-aval-med"
        @click.native="relatorioMedico()"
        icon="fas fa-plus"
        styl="color: white; font-size: 40px;"
      ></Button>
    </div>

    <div class="control-telaPaciente">
      <buttonRectangle
        id="history"
        @click.native="historico()"
        v-if="this.$store.state.user"
        info="Acessar Histórico"
      ></buttonRectangle>
      <buttonRectangle info="VOLTAR" @click.native="home()" id="backPaciente"></buttonRectangle>
    </div>
    <!--<buttonRectangle id="edit" info="Editar Paciente"></buttonRectangle>-->
  </div>
</template>

<script>
import Button from "../components/Button";
import buttonRectangle from "../components/buttonRectangle";
import axios from "axios";
import { baseApiUrl } from "../global";

export default {
  name: "telaPaciente",
  components: {
    Button,
    buttonRectangle
  },
  data() {
    return {
      avaliacao: { paciente_id: this.$store.state.paciente.paciente_id },
      avaliacaoPassagem: [],
      medicoPassagem: []
    };
  },
  methods: {
    relatorioPaciente() {
      const data = new Date();

      const stringData =
        data.getUTCFullYear() +
        "-" +
        (data.getUTCMonth() + 1) +
        "-" +
        data.getDate();

      this.avaliacao.avaliacao_dataPaciente = stringData;
      this.avaliacao.paciente_id = this.$store.state.paciente.paciente_id;

      axios
        .post(`${baseApiUrl}/avaliacao`, this.avaliacao)
        .then(res => {
          let idAval = res.data;
          this.$store.state.idAvaliacao = idAval[0];
        })
        .catch(err => {
          alert(err);
        });

      this.$router.push("quest1");
    },
    relatorioMedico() {
      this.$store.state.avaliacao = {};
      axios
        .get(`${baseApiUrl}/avaliacao`)
        .then(res => {
          this.avaliacaoPassagem = res.data;
        })
        .catch(err => {
          alert(err);
        });

      axios
        .get(`${baseApiUrl}/versao_medico`)
        .then(res => {
          this.medicoPassagem = res.data;
        })
        .catch(err => {
          alert(err);
        });

      let avaliacaoFlag = false;

      setTimeout(() => {
        for (let i = this.avaliacaoPassagem.length; i > 0; i--) {
          if (
            this.avaliacaoPassagem[i - 1].paciente_id ==
            this.$store.state.paciente.paciente_id
          ) {
            this.$store.state.avaliacao = this.avaliacaoPassagem[i - 1];
            break;
          }
        }
        for (let i = this.medicoPassagem.length - 1; i > 0; i--) {
          if (
            this.medicoPassagem[i].avaliacao_id ==
            this.$store.state.avaliacao.avaliacao_id
          ) {
            avaliacaoFlag = true;
            alert("Avaliação já possui uma avaliação médica vinculada");
            this.$router.push("/");
            break;
          }
        }
        if (!avaliacaoFlag) {
          this.$router.push("qmedico1");
        }
      }, 1000);
    },
    historico() {
      this.$router.push("historico");
    },
    home() {
      this.$store.state.paciente = {};
      this.$store.state.listaPacientes = [];
      this.$store.state.versaoPaciente = {};
      this.$store.state.exameFisico = [];
      this.$store.state.avaliacaoGlobalAtual = [];
      this.$store.state.idAvaliacao = "";
      this.$store.state.isMenuVisible = true;
      this.$store.state.sintomas = [];
      this.$store.state.condicoes = [];
      this.$store.state.valueQuest1 = "";
      this.$store.state.valueQuest2 = "";
      this.$store.state.valueQuest3 = "";
      this.$store.state.valueQuest4 = "";
      this.$store.state.valueQuest5 = "";
      this.$store.state.otherFlag = false;
      this.$store.state.acheFlag = false;
      this.$store.state.feedFlag = false;
      this.$store.state.avaliacao = {};
      this.$store.state.antropometria = {};
      this.$store.state.versaoMedico = {};

      this.$router.push("/");
    },
    disableMenu() {
      //console.log(this.paciente);
      this.$store.state.isMenuVisible = false;
    }
  },
  computed: {
    isMenuVisible() {
      return this.$store.state.isMenuVisible;
    },
    paciente() {
      return this.$store.state.paciente;
    }
  },
  mounted() {
    this.disableMenu();
  }
};
</script>

<style>
.aval-div {
  display: flex;
  width: 45vw;
  justify-content: space-between;
  height: 100px;
  margin: 50px 0 0 50px;
}

.control-telaPaciente {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  height: auto;
}

#history {
  display: flex;
  background-color: #fabc3c;
}

#backPaciente {
  margin-top: 15px;
  display: flex;
  background-color: #fc7753;
}

#btn-aval-auto,
#btn-aval-med {
  width: 75px;
  height: 75px;
}

#btn-aval-auto,
#avalAuto {
  top: 350px;
}

#btn-aval-med,
#avalMed {
  top: 520px;
}

#avalAuto,
#avalMed {
  font-size: 55px;
  left: 50px;
}

div#nome {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  position: relative;
  text-decoration: underline;
  font-size: 70px;
  margin: 50px 0 100px 0;
  left: 50px;
}

@media screen and (max-width: 600px) {
  .telaPaciente {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100vh;
  }

  .aval-div {
    width: 80vw;
    height: 40px;
    margin-top: 0;
    margin-left: 0;
  }

  #avalAuto,
  #avalMed {
    font-size: 35px;
  }

  #btn-aval-auto,
  #btn-aval-med {
    width: 50px;
    height: 50px;
  }

  div#nome {
    width: 80vw;
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 0;
    left: 0;
    height: 60px;
  }

  .control-telaPaciente {
    margin-top: 0;
  }

  #history {
    width: 250px;
    height: 50px;
  }

  #backPaciente {
    width: 250px;
    height: 50px;
  }
}
</style>