<template>
  <div class="hello">
    <div class="welcome">{{ msg }}</div>
    <img class="imagem" alt="fruta" src="../assets/fruta.png" />
    <h1 class="start" v-if="!user" @click="openModal()">
      Clique aqui para começar a sua auto-avaliação
    </h1>

    <div v-if="user">
      <div>
        <Button
          class="btn-add"
          @click.native="openModal()"
          id="btn-add"
          icon="fas fa-plus"
          styl="color: white;font-size:30px;"
        ></Button>
        <div class="tooltip" id="tooltipPaciente">Novo Paciente</div>
      </div>

      <div>
        <Button
          class="btn-file"
          @click.native="pesquisaPaciente()"
          id="btn-file"
          icon="far fa-folder-open"
          styl="color: white;font-size:30px;"
        ></Button>
        <div class="tooltip" id="tooltipExistente">Abrir Existente</div>
      </div>
      <Button
        class="btn-user"
        @click.native="toggleButton()"
        id="btn-user"
        icon="far fa-user"
        styl="color: white;font-size:46px;"
      ></Button>
    </div>

    <Modal msg="Pré-Cadastro" id="modal"></Modal>
    <div id="overlay"></div>

    <div class="guidance-overlay" v-if="!stepComplete">
      <guidance-modal :step="step"/>
    </div>
  </div>
</template>

<script>
import Button from "./Button";
import GuidanceModal from './GuidanceModal.vue';
import Modal from "./Modal";

export default {
  name: "homePage",
  props: {
    msg: String,
  },
  data: function () {
    return {
      isVisible: false,
      step: {
        title: "Bem Vindo(a) ao Nutrologic!",
        message: 'Para iniciar a sua auto avaliação clique na mensagem "Clique aqui para começar a sua auto avaliação", abaixo da figura de frutas e preencha os seus dados pessoais na janela que abrirá',
        close: () => {
          this.stepComplete = true;
        }
      },
      stepComplete: false
    };
  },
  components: {
    Button,
    Modal,
    GuidanceModal,
  },
  methods: {
    toggleButton() {
      if (this.isVisible == false) {
        document.getElementById("btn-add").style.removeProperty("animation");
        document
          .getElementById("btn-add")
          .style.setProperty("animation", "slide-in-add .5s");
        document
          .getElementById("btn-add")
          .style.setProperty("animation-fill-mode", "forwards");
        document.getElementById("btn-file").style.removeProperty("animation");
        document
          .getElementById("btn-file")
          .style.setProperty("animation", "slide-in-file .5s");
        document
          .getElementById("btn-file")
          .style.setProperty("animation-fill-mode", "forwards");
        setTimeout(function () {
          document
            .getElementById("tooltipExistente")
            .style.removeProperty("animation");
          document.getElementById("tooltipExistente").style.visibility =
            "visible";
        }, 200);
        setTimeout(function () {
          document
            .getElementById("tooltipPaciente")
            .style.removeProperty("animation");
          document.getElementById("tooltipPaciente").style.visibility =
            "visible";
        }, 350);

        this.isVisible = true;
      } else {
        document.getElementById("btn-add").style.removeProperty("animation");
        document
          .getElementById("btn-add")
          .style.setProperty("animation", "slide-out-add .5s");
        document
          .getElementById("btn-add")
          .style.setProperty("animation-fill-mode", "forwards");
        document.getElementById("btn-file").style.removeProperty("animation");
        document
          .getElementById("btn-file")
          .style.setProperty("animation", "slide-out-file .5s");
        document
          .getElementById("btn-file")
          .style.setProperty("animation-fill-mode", "forwards");
        document
          .getElementById("tooltipPaciente")
          .style.removeProperty("animation");
        document.getElementById("tooltipPaciente").style.visibility = "hidden";
        document
          .getElementById("tooltipExistente")
          .style.removeProperty("animation");
        document.getElementById("tooltipExistente").style.visibility = "hidden";

        this.isVisible = false;
      }
    },
    openModal() {
      if (this.$store.state.visibleModal == false) {
        document.getElementById("modal").style.removeProperty("animation");
        document
          .getElementById("modal")
          .style.setProperty("animation", "modal-in .5s");
        document
          .getElementById("modal")
          .style.setProperty("animation-fill-mode", "forwards");
        document.getElementById("overlay").style.visibility = "visible";

        this.$store.state.visibleModal = true;
      }
    },
    enableMenu() {
      this.$store.state.isMenuVisible = true;
    },
    pesquisaPaciente() {
      this.$router.push("pesquisa_paciente");
      //this.user ? this.$router.push("pesquisa_paciente") : alert("Você deve fazer o login para acessar os pacientes!");
    },
    resetValues() {
      this.$store.state.paciente = {};
      this.$store.state.listaPacientes = [];
      this.$store.state.versaoPaciente = {};
      this.$store.state.exameFisico = [];
      this.$store.state.avaliacaoGlobalAtual = [];
      this.$store.state.idAvaliacao = "";
      this.$store.state.isMenuVisible = true;
      this.$store.state.sintomas = [];
      this.$store.state.condicoes = [];
      this.$store.state.valueQuest1 = "";
      this.$store.state.valueQuest2 = "";
      this.$store.state.valueQuest3 = "";
      this.$store.state.valueQuest4 = "";
      this.$store.state.valueQuest5 = "";
      this.$store.state.otherFlag = false;
      this.$store.state.acheFlag = false;
      this.$store.state.feedFlag = false;
      this.$store.state.avaliacao = {};
      this.$store.state.antropometria = {};
      this.$store.state.versaoMedico = {};
    },
  },
  computed: {
    visibleModal() {
      return this.$store.state.visibleModal;
    },
    isMenuVisible() {
      return this.$store.state.isMenuVisible;
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    visibleModal() {
      if (this.$store.state.visibleModal == false) {
        document.getElementById("overlay").style.visibility = "hidden";
      }
    },
  },
  mounted() {
    this.enableMenu(), this.resetValues();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  text-align: center;
}

#overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  z-index: 3;
  visibility: hidden;
}

.guidance-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  background-color: #aaa5;
}

#tooltipPaciente {
  position: absolute;
  right: 180px;
  bottom: 270px;
  visibility: hidden;
  display: flex;
  width: 100px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  float: right;
  color: #fff;
}

#tooltipExistente {
  position: absolute;
  right: 200px;
  bottom: 190px;
  visibility: hidden;
  display: flex;
  width: 100px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  float: right;
  color: #fff;
}

.start {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s;
  
}

.start:hover {
  filter: invert(46%) saturate(1000%) brightness(96%);
}

#img-btn {
  width: 50px;
  margin-left: 5px;
  cursor:pointer;
}

.imagem {
  z-index: -1;
}

.welcome {
  font-size: 50px;
  margin-top: 6%;
}

#btn-user {
  position: absolute;
  right: 40px;
  bottom: 60px;
  z-index: 2;
}

#btn-add {
  visibility: hidden;
  background-color: #6cd4ff;
  position: absolute;
  right: 110px;
  bottom: 190px;
  width: 60px;
  height: 60px;
  z-index: 2;
}

#btn-file {
  visibility: hidden;
  background-color: #fabc3c;
  position: absolute;
  right: 130px;
  bottom: 115px;
  width: 60px;
  height: 60px;
  z-index: 2;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

@media screen and (max-width: 1366px) {
  .imagem {
    width: 400px;
  }

  .start {
    font-size: 25px;
  }

  #img-btn {
    width: 30px;
  }
}

@keyframes tooltip-in {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

@keyframes tooltip-out {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}
</style>
